import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, Output,
         ViewChild, EventEmitter, ElementRef, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';

import { Subject, Observable, Subscription } from 'rxjs';

import { MatDividerModule } from '@angular/material/divider';
// import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
// import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { GroupData } from '../../model/groupdata.model';
import { OrgData } from '../../org/model/orgdata.model';
import { OrgUserData } from '../../org/model/orguserdata.model';
import { OrgUserInfo } from '../../org/model/orguserinfo.model';
import { OrgContactData } from '../../org/model/orgcontactdata.model';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';

import { AuthService } from '../../service/auth.service';
import { DocumentService } from '../../service/document.service';
import { GroupService } from '../../service/group.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';
import { PlatformService } from '../../service/platform.service';
import { UserService } from '../../service/user.service';
// import { UserSubAccountService } from '../../service/user-subaccount.service';

// import { UserListComponent } from '../../user-list/user-list.component';

// import { OrgModule } from '../../org/org.module';
// import { GroupModule } from '../group/group.module';
import { DocumentModule } from '../../document/document.module';

//import { DocComponent } from '../../doc/doc.component';
//import { DocListComponent } from '../../doc-list/doc-list.component';

// import * as LISTS from '../../mdtools/select-lists.common';
import * as PLATFORM from '../../service/platform.service';
import * as HELP from '../../service/help.service';


@Component({
  selector: 'app-org-usage',
  templateUrl: './org-usage.component.html',
  styleUrls: ['./org-usage.component.css']
})
export class OrgUsageComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()  selectedOrg: OrgData | null = null;
  @Input()  objData: any | null = null;
  @Input()  isPolling = false;   
  @Input()  pollData = true;
  @Input()  pollInterval = 30000;

  @ViewChild('orgUsage', {static: false}) orgUsage;
  @ViewChild('orgGridView', {static: false}) orgGridView;
  @ViewChild('gridView') gridView;

  UI_DESKTOP = 'Desktop';
  UI_TABLET = 'Tablet';
  UI_MOBILE = 'Mobile';
  UI_PORTRAIT = 'Portrait';

  marginTop = 0;
  screenWidth: any;
  screenHeight: any;
  mainWidth = 0;
  headerWidth?: string;
  uiMode = '';

  numColumns = 3;
  tileWidth = 110;
  tileHeight = 200;

  helpContext: any;

  updateInterval: any;

  printImgSrc?: any;

  userData?: UserData = null;
  userInfo?: UserInfo = null;

  // selectedOrg?: OrgData | null = null;
  selectedOrgUser?: OrgUserData | OrgUserInfo | null = null;
  selectedOrgContact: OrgContactData | null = null;
  selectedUser?:  UserInfo | null = null;
  selectedGroup?: GroupData | null = null;

  //Observables
  orgUserCount$: Observable<any> = null;
  orgEmpCount$: Observable<any> = null;
  orgActiveEmpCount$: Observable<any> = null;
  orgAvailableEmpCount$: Observable<any> = null;
  orgGuestCount$: Observable<any> = null;
  orgActiveGuestCount$: Observable<any> = null;
  orgAvailableGuestCount$: Observable<any> = null;
  orgGroupCount$: Observable<any> = null;
  orgDocCount$: Observable<any> = null;
  orgStorage$: Observable<any> = null;

  constructor(
    public auth: AuthService,
    public platformsvc: PlatformService,
    // public usersvc: UserService,
    public orgsvc: OrganizationService,
    public orgusersvc: OrgUserService,
    public documentsvc: DocumentService,
    public groupsvc: GroupService
  ) {
    this.userData = this.auth.getTokenInfo();
  }

  ngOnInit() {
     this.userData = this.auth.getTokenInfo();
     if ( this.selectedOrg ) {
        this.setSelectedOrg(this.selectedOrg);
        this.orgsvc.getOrgStats(this.selectedOrg.uid);     
     }
     // Get updates on updateInterval every 5 seconds..
     this.updateInterval = setInterval(
        () => {
                /* Only update when window is actively focused */
                if (document.hasFocus() && this.selectedOrg) {
                      this.orgsvc.getOrgStats(this.selectedOrg.uid);
                }
            }, this.pollInterval);
  }

  async ngAfterViewInit(): Promise<void> {
     this.userData = this.auth.getTokenInfo();
     this.getScreenSize();
     this.setColNum();
     // console.log('MGR Init userData=', this.userData);
  }


  async ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
     // console.log('org-user changes=', changes);
      if ( changes.uid ) {
         this.objData = await this.orgsvc.getOrganizationPromise( changes.uid.currentValue );
         this.setSelectedOrg(this.objData);
      }
      if ( changes.selectedOrg ) {
         this.selectedOrg = changes.selectedOrg.currentValue;
         this.setSelectedOrg(this.selectedOrg);
      }
      if ( changes.objData ) {
         this.setSelectedOrg(changes.objData.currentValue);
      }
  }

  ngOnDestroy() {
     if ( this.updateInterval ) {
       clearInterval(this.updateInterval);
     }
  }

  /**************
  getOrgStats() {
    if ( this.selectedOrg && this.selectedOrg != null ) {
       this.orgUserCount$ = this.orgusersvc.getOrgUserCount$(this.objData.uid);
       this.orgEmpCount$ = this.orgusersvc.getOrgEmpRecordCount$(this.objData.uid);
       this.orgActiveEmpCount$ = this.orgusersvc.getOrgActiveEmpCount$(this.objData.uid);
       this.orgAvailableEmpCount$ = this.orgusersvc.getOrgAvailableEmpCount$(this.objData.uid);
       this.orgGuestCount$ = this.orgusersvc.getOrgGuestRecordCount$(this.objData.uid);
       this.orgActiveGuestCount$ = this.orgusersvc.getOrgActiveGuestCount$(this.objData.uid);
       this.orgAvailableGuestCount$ = this.orgusersvc.getOrgAvailableGuestCount$(this.objData.uid);
       this.orgGroupCount$ = this.groupsvc.getOrgGroupCount$(this.objData.uid);
       this.orgDocCount$ = this.documentsvc.getOrgDocumentCount$(this.objData.uid);
       this.orgStorage$ = this.documentsvc.getOrgDocumentStorage$(this.objData.uid);
    }
  }
  ***************/
  
  getScreenSize(): void {
      this.screenHeight = window.innerHeight;
      this.screenWidth = window.innerWidth;
      this.platformsvc.orientation = PLATFORM.VIEWPORT_LANDSCAPE;
      if ( this.platformsvc.uiMode === PLATFORM.UI_MOBILE ) {
         this.platformsvc.mainMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
         this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_MOBILE;
      } else {
         this.platformsvc.mainMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
         this.platformsvc.sidenavMarginTop = PLATFORM.MARGIN_TOP_DESKTOP;
        }
      console.log('getScreenSize mainMarginTop=', this.platformsvc.mainMarginTop );
  }

  setColNum(){
     let width = window.innerWidth;
     if ( this.gridView ) {
         width = this.gridView.nativeElement.clientWidth;
     }
     this.numColumns = Math.trunc(width/(this.tileWidth * 4));
     if (this.numColumns < 1) { this.numColumns = 1 }
     // this.numColumns = Math.floor(this.gridView.nativeElement.clientWidth / 100);
  }

  setSelectedOrg(e) {
     console.log('mdmgr selectedOrg e=', e);
     this.selectedOrg = e;
     this.objData = e;
     if (e!==null) {
        this.orgsvc.getOrgStats(e.uid);
     }
  }

  setSelectedUser(e) {
     console.log('mdmgr selectedUser e=', e);
     this.selectedUser = e;
  }

  setSelectedGroup(e) {
     console.log('mdmgr selectedGroup e=', e);
     this.selectedGroup = e;
  }

  compareListItemss(o1: any, o2: any): boolean {
   if ( o1 == null || o2 == null )
      { return false; }
   const result = o1.code === o2.code;
   // console.log('compare objs result=', result);
   return result;
  }

  /***
  setTypeFilter(s:string ) {
     this.typeFilter = s;
  }
  ***/
}
