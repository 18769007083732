<ng-template #tileLoading>
  <div><span>Loading...</span></div>
</ng-template>

<ng-template #notAuthorized>
  <div #notAuthorized class="not-authorized">
    <br><br>
    <h2 *ngIf="userData && userData.user">User {{userData.user}}  is Not Authorized!</h2><br><br>
    <ng-template #noauth>
      <h2 style="color: red">User is Not Authorized!</h2><br><br>
    </ng-template>
    <h3 class="black">Unauthorized Use of This Feature is Prohibited.</h3>
  </div>
  <!----
  <div class="noauth-btn-div">
    <button mat-stroked-button enabled class="noauth-btn" (click)="navToApp()">
      Cancel
    </button><BR>
    <button mat-stroked-button enabled class="noauth-btn" (click)="logout()">
      Logout
    </button>
  </div>
  ----->
</ng-template>


<div #orgUsage class="org-usage" *ngIf="userData; else notAuthorized">

	 <div #orgGridView *ngIf="objData" style="padding-top: 0.5rem;">
         <span class="grid-title"> {{selectedOrg.org_name}} System Usage</span>
	   
         <mat-grid-list class="grid-list" cols="{{numColumns}}" gutterSize="2rem"
			rowHeight="20vh" rowWidth="5vw" (window:resize)="setColNum()">

           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       User Accts
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgUserCount$ | async) as orgusercnt; else tileLoading" class="tile-div">
	        <span>{{orgusercnt.count}}</span>
             </div>
           </mat-grid-tile>

           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Active Emp/Mbr Limit
	     </mat-grid-tile-header>
             <div class="tile-div">
              <span>{{selectedOrg.org_active_ee_account_limit}}</span>
             </div>
           </mat-grid-tile>
	   
           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Active Emp Accts
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgActiveEmpCount$ | async) as orgactiveempcnt; else tileLoading" class="tile-div">
	        <span>{{orgactiveempcnt.count}}</span>
             </div>
           </mat-grid-tile>
	   
           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
               Avail Emp Accts
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgAvailableEmpCount$ | async) as orgavailempcnt; else tileLoading"
	   class="tile-div">
	        <span>{{orgavailempcnt.count}}</span>
             </div>
           </mat-grid-tile>

           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Active Guest Limit
	     </mat-grid-tile-header>
             <div *ngIf="objData; else tileLoading" class="tile-div">
             <span>{{objData.org_active_guest_account_limit}}</span>
             </div>
           </mat-grid-tile>
	   
           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Guest Accounts
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgGuestCount$ | async) as orgguestcnt; else tileLoading" class="tile-div">
	        <span>{{orgguestcnt.count}}</span>
             </div>
	     <!--
	     <mat-grid-tile-footer class="tile-footer">
	       Accounts
	     </mat-grid-tile-footer>
              -->
           </mat-grid-tile>

           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Active Guest Accts
	     </mat-grid-tile-header>
             <div
	   *ngIf="(orgsvc.orgActiveGuestCount$ | async) as orgactiveguestcnt; else tileLoading" class="tile-div">
	        <span>{{orgactiveguestcnt.count}}</span>
             </div>
           </mat-grid-tile>
	   
           <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
               Avail Guest Accts
	     </mat-grid-tile-header>
             <div
	   *ngIf="(orgsvc.orgAvailableGuestCount$ | async) as orgavailguestcnt; else tileLoading"
	   class="tile-div">
	        <span>{{orgavailguestcnt.count}}</span>
             </div>
           </mat-grid-tile>
	   
         <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Group Count
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgGroupCount$ | async) as orggroups; else tileLoading"
		  class="tile-div">
	        <span>{{orggroups.count}}</span>
             </div>
         </mat-grid-tile>

         <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       File Count
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgDocCount$ | async) as orgfiles; else tileLoading"
		  class="tile-div">
	        <span>{{orgfiles.count}}</span>
             </div>
         </mat-grid-tile>

         <mat-grid-tile class="tile">
	     <mat-grid-tile-header class="tile-header">
	       Storage Used
	     </mat-grid-tile-header>
             <div *ngIf="(orgsvc.orgStorage$ | async) as orgstorage; else tileLoading"
		  class="tile-div">
             <span>{{((orgstorage.sum / 1024) / 1024) | number: '1.1-2'}}MB</span>
            </div>
         </mat-grid-tile>

       </mat-grid-list>
     </div>	 
</div>
