  <mat-menu #objMenu="matMenu">
  <button mat-menu-item enabled (click)="setSelectedObj(); viewObj()">
    <mat-icon>domain</mat-icon>              
    <span style="font-size: 0.9em">View Details</span>
  </button>
  <button *ngIf="userData && ((iam && userData.iam) || isObjAdmin)"
          mat-menu-item enabled (click)="setSelectedObj(); editObj()">
          <mat-icon>edit</mat-icon>        
          <span style="font-size: 0.9em">Edit {{objLabel}}</span>
  </button>
  <button *ngIf="userData && ((iam && userData.iam) || isObjAdmin)"
          mat-menu-item enabled (click)="createObj()">
          <mat-icon>add</mat-icon>        
          <span style="font-size: 0.9em">Add New {{objLabel}}</span>
  </button>
  <button *ngIf="userData && ((iam && userData.iam) || isObjAdmin) && !isOrgOwner"
	  mat-menu-item enabled (click)="setSelectedObj(); xferContentDialog()">
          <mat-icon>drive_file_move</mat-icon>
          <span style="font-size: 0.9em">Transfer {{objLabel}} Content</span>
  </button>
  <button *ngIf="userData && ((iam && userData.iam) || isObjAdmin) && !isOrgOwner"
	  mat-menu-item enabled (click)="setSelectedObj(); deleteObj()">
          <mat-icon>delete</mat-icon>
          <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
  </mat-menu>

  
<ng-template #nodata>
   <span>No Data Found</span>
</ng-template>

<ng-template #loading>
  <div>
    <span>Loading Data...</span>
  </div>
</ng-template>

<ng-template #notax>
  <span style="margin-left: 1em">Non-Taxable</span>
</ng-template>

<!-- border: 1px solid #1A567A;  -->
<div>

<div #orgUser *ngIf="template === DisplayTemplate.FIELD_NAME">
 <div *ngIf="(uid && objData$ | async) as obj">
        <mat-form-field class="form-field" [floatLabel]="'always'" appearance="">	  
           <mat-label class="form-label">{{nameLabel}}</mat-label>
           <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
           <input matInput placeholder=""
                  type="text" name="name" class="form-control"
		  [value]="obj.org_user_email">
        </mat-form-field>
  </div>	
</div>

<div #orgUser *ngIf="template === DisplayTemplate.SIDENAV_NAME">
  <div *ngIf="objData">
    <span class="sidenav-name">{{objData.org_user_email}}</span>
  </div>
</div>

<div #orgUserList *ngIf="template === DisplayTemplate.LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 70vw !important;">
  <div #orgUserItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <span *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid; else defaultObjName" style="display: flex; flex-flow: row; align-content: center; font-size 1.5rem; font-weight: bold; background: #1A567A; color: white; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em; justify-content: space-between;">
    <div style="align-self: flex-start">	  	      
      <mat-icon matPrefix style="margin-right: 1rem; background: #1A567A; color: white;">person</mat-icon>
      {{objData.org_user_email}}
      <span *ngIf="objData.org_admin" style="font-size: 0.8rem; background: #1A567a; color: white"> &#9733;</span>      
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem"> &#9733;</span>
    </div>      
      <mat-icon *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid"    style="align-self: center;padding-left: 0em; padding-right: 0em; padding-top: 0em; padding-bottom: 0em;">
	check
      </mat-icon>
   </span>
      
    <ng-template #defaultObjName>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em;">
        <mat-icon style="margin-right: 1rem;">person</mat-icon>    		
        {{objData.org_user_email}}
	<span *ngIf="objData.org_admin" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>
        <span *ngIf="isOrgOwner" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>		
       </span>
    </ng-template>
    <button *ngIf="showDeleteButton && !isOrgOwner"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #orgUserList *ngIf="template === DisplayTemplate.XFER_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 70vw !important;">
  <div #orgUserItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; min-height: 5vw !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <span *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid; else defaultObjName" style="display: flex; flex-flow: row; align-content: center; font-size 1.5rem; font-weight: bold; background: #1A567A; color: white; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em; justify-content: space-between;">
      <div style="align-self: flex-start">
      <mat-icon matPrefix style="margin-right: 1rem; background: #1A567A; color: white;">person</mat-icon>
      {{objData.org_user_email}}
      <span *ngIf="objData.org_admin" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>		
    </div>      
      <mat-icon *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid"    style="align-self: center;padding-left: 0em; padding-right: 0em; padding-top: 0em; padding-bottom: 0em;">
	check
      </mat-icon>
   </span>
      
    <ng-template #defaultObjName>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em;">
        <mat-icon style="margin-right: 1rem;">person</mat-icon>    		
         {{objData.org_user_email}}
       </span>
    </ng-template>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.SELECT_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <!--   <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em">person</mat-icon>  
      <span>Type={{objData.org_user_type}}</span>     
    -->
    <span *ngIf="selectedObj && selectedObj.uid === objData.uid; else slNotSelected"
	  style="font-size 1.5rem; font-weight: bold; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      <mat-icon *ngIf='objData.org_user_type==="E"' style="margin-left: 0.25rem; margin-top: 1rem; margin-right: 0.5rem; background: white; color: #1A567A;">person</mat-icon>
      <mat-icon *ngIf='objData.org_user_type==="G"' style="margin-left: 0.25rem; margin-top: 1rem; margin-right: 0.5rem; background: white; color: black;">person2</mat-icon>      
      <span *ngIf="objData.org_user_type==='E'"
	    style="color: #1A567A;">
	    {{objData.org_user_email}} ({{objData.org_user_type}})</span>
      <span *ngIf="objData.org_user_type==='G'"
	    style="color: black">
	    {{objData.org_user_email}} ({{objData.org_user_type}})</span>	
      <span *ngIf="objData.org_admin" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>		
    </span>
    <ng-template #slNotSelected>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      <mat-icon *ngIf='objData.org_user_type==="E"' style="margin-left: 0.25rem; margin-top: 1rem; margin-right: 0.5rem; background: white; color: #1A567A;">person</mat-icon>
      <mat-icon *ngIf='objData.org_user_type==="G"' style="margin-left: 0.25rem; margin-top: 1rem; margin-right: 0.5rem; background: white; color: black;">person2</mat-icon>
      <span *ngIf="objData.org_user_type==='E'"
	    style="color: #1A567A;">
	    {{objData.org_user_email}} ({{objData.org_user_type}})</span>		
      <span *ngIf="objData.org_user_type==='G'"
	    style="color: black">
            {{objData.org_user_email}} ({{objData.org_user_type}})</span>
      <span *ngIf="objData.org_admin" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>      
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem; color: #1A567A"> &#9733;</span>
    </span>
    </ng-template>
    <mat-icon *ngIf="selectedObj && selectedObj.uid === objData.uid"
	      style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: white; color: #1A567A;">
	check
    </mat-icon>
    <button *ngIf="showDeleteButton && (isObjAdmin || (iam && userData.iam)) && ! isOrgOwner"
	    mat-stroked-button (click)="xferContentDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">drive_file_move</mat-icon>
    </button>
    <button *ngIf="showDeleteButton && (isObjAdmin || (iam && userData.iam)) && ! isOrgOwner"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #objExpandList *ngIf="template === DisplayTemplate.EXPAND_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;" (click)="setSelectedObj()">
  <div #orgitem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="setSelectedObj()">
    <button mat-stroked-button
            (click)="toggleDetail()"
      style="margin-right: 1.5em; color: white; background-color: #2274A5;">
      <mat-icon>expand_more</mat-icon>
    </button>
    <span *ngIf="orgData && selectedOrg && selectedOrg.uid === orgData.uid; else defaultOrgNbr"
          (click)="setSelectedObj()"
          style="font-weight: bold; color: #2274A5; margin-right: 1rem">
          {{objData.org_client_nbr}}
    </span>
    <ng-template #defaultOrgNbr>
       <span (click)="setSelectedObj()"
          style="font-size: 1rem; font-weight: normal; color: black; margin-right: 1rem">
          {{objData.org_client_nbr}}
      </span>
    </ng-template>
    <span *ngIf="orgData && selectedOrg && selectedOrg.uid === orgData.uid; else defaultOrgName"
          style="font-size 1rem; font-weight: bold; color: #2274A5; width: 100%">
          {{objData.org_user_email}}
    </span>
    <ng-template #defaultOrgName>
       <span style="font-size: bold; font-weight: normal; color: black; width: 100%" (click)="setSelectedObj()"> 
         {{objData.org_user_email}}
       </span>
    </ng-template>
  </div>

  <div #objItemButtons (click)="setSelectedObj()"
       style="display: flex; flex-flow: row; justify-content: space-around; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em;">
  <mat-icon style="color: #2274A5; align-self: flex-end;" *ngIf="selectedObj && selectedObj.uid === objData.uid">check</mat-icon>    
 </div>
</div>

<!-- border: 1px solid #1A567A;  -->
<div #objdetail *ngIf="template === DisplayTemplate.DETAIL"
     style="display: flex; flex-flow: column; justify-content: space-between; align-items: space-between; width: 90% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="toggleDetail()">
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
    <button *ngIf="this.mode === DisplayMode.LIST || this.mode === DisplayMode.EXPAND_LIST"
	  mat-stroked-button
          (click)="toggleDetail()"
          style="margin-right: 1.5em;">
    <mat-icon>expand_less</mat-icon>
    </button>
    <span style="margin-right: 1rem">{{objData.org_client_nbr}}</span>
    <span style="width: 100%;">{{objData.org_user_email}}</span>
    <mat-icon *ngIf="objData && selectedObj && (objData.uid===selectedObj.uid)"
        style="margin-left: 0.5em; margin-right: 0.4em; color: #2274A5">
	check
    </mat-icon>
  </div>
  <span style="margin-left: 1.5em; width: 100%"></span><br>
 <div style="display: flex; flex-flow: column; margin-left: 0.5em; margin-right: 1em; border: 1px solid #1A567A; width: 95%% !important;">  
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
  </div>
  <div style="display:flex; flex-flow: column; justify-content: space-between; margin-left: 0.5em">
    <span style="">Contact</span>
    <span style="">Email :{{objData.org_owner_email}}</span>
    <span style="">Name  : {{objData.org_owner_name}}</span>
    <span style="">Phone : {{objData.org_owner_phone}}</span>
    <span style="">Mobile: {{objData.org_owner_mobile_phone}}</span>
    <span style="">Fax   : {{objData.org_owner_fax}}</span><br>            
    <span style="">Addr  : {{objData.org_addr}}</span>
    <span style="">Addr2 : {{objData.org_addr_2}}</span>
    <span style="">City  : {{objData.org_city}}</span>
    <span style="">State : {{objData.org_state}}</span>
    <span style="">Zip: {{objData.org_postal_code}}</span>                    
  </div>
  <span style=""></span><BR>
  <div *ngIf="userData && userData.iam && iam">
    <span style="color: gray">ADMIN/MGR INFORMATION</span><BR><BR>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Type:</span>
       <span style="margin-left: 1em">{{objData.org_type}}</span>
    </div>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Category:</span>      
       <span style="margin-left: 1em">{{objData.org_category}},</span>
    </div>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Sales Tax:</span>           
       <span *ngIf="objData.org_taxable; else notax"
	     style="margin-left: 1em">Taxable</span>
    </div>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Sales Ref Agent:</span>           
       <span style="margin-left: 1rem">{{objData.org_sales_referral_email}}
       </span>
    </div>
    <span style="color: gray">Limits:</span><BR>
    <span style="margin-left: 1rem">Employees: {{objData.org_ee_account_limit}}
    </span>
    <span style="margin-left: 1rem">Guests: {{objData.org_guest_account_limit}}
    </span>
    <span style="margin-left: 1rem">Storage: {{objData.org_storage_limit}}GB
    </span>
    <span style=""></span><BR>
  </div>
 </div>
</div>

<div #orgform *ngIf="template===DisplayTemplate.FORM">
 <div 
     style="display: flex; flex-flow: column; justify-content: space-around; align-content; center; min-width: 90vw; margin-left: 1rem; margin-right: 1rem; overflow: hidden !important; margin-bottom: 7rem;">  

   <!--   <div *ngIf="showFormTitle"> -->
    <!-- <span>IS OBJ ADMIN: {{isObjAdmin}}</span> -->     
   <div>
   <div class="form-title" *ngIf="isAddMode">Create {{objLabel}}</div>
   <div class="form-title" *ngIf="mode === DisplayMode.EDIT">Edit {{objLabel}}</div>
   <div class="form-title" *ngIf="mode === DisplayMode.VIEW">View {{objLabel}}</div>  
  </div>

  <!-----
  <div class="form-title" *ngIf="isAddMode">Add New User</div>
  <ng-container *ngIf="availGuestOrgUsers$ | async as availGuests">
     <div class="form-title" *ngIf="isAddMode">Remaining Guest User Adds: {{availGuests.count}}</div>
  </ng-container>       
  <ng-container *ngIf="availEmpOrgUsers$ | async as availEmps">
     <div class="form-title" *ngIf="isAddMode">Remaining Emp/Member User Adds: {{availEmps.count}}</div>
  </ng-container>
  ------>
  
    <div *ngIf="checkQuota">
      <div *ngIf="isAddMode"
            class="form-title" style="margin-top: 1rem;">
	    Checking Quota for Add New User...</div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

     <div *ngIf="objForm && objForm.get('org_user_type').value==='E' && this.newEmpsAvailable && this.newEmpsAvailable['count']" style="margin-top: 1rem;">
       {{newEmpsAvailable['count']}} Employee/Member user adds remain for this subscription level<BR>
     </div>
     <div *ngIf="objForm && objForm.get('org_user_type').value==='G' && this.newGuestsAvailable && this.newGuestsAvailable['count']" style="margin-top: 1rem;">
          {{newGuestsAvailable['count']}} Guest user adds remain for this subscription level
     </div>
    
    <div *ngIf="quotaError" style="margin-top: 1rem;">
     <div *ngIf="isAddMode && quotaErrorType==='G'">
	Notice: No more GUEST users are available under your subscription
      </div>
      <div *ngIf="isAddMode && quotaErrorType!=='G'">
	Notice: No more EMPLOYEE/MEMBER users are available under your subscription
      </div>
      <p>
	<span>You can reduce the number of currently active users by editing an existing Company/Org/Team
	  User record and setting the expire by date flag and then adding an expiration date prior to
	  this date and time.</span><br>
	<span>You can also increase your active user quotas by updating your subscription or subscription
	  options by navigating to the Subscription form and using the MODIFY button/option.
        </span>
      </p>
    </div>

  <ng-container *ngIf="isAddMode && !selectedOrg && !checkQuota">
    <app-org-list #orgList class="form-field" 
		     style="margin: 0; padding: 0; width: 80vw; max-width: 80vw; height: 100%: overflow-y: auto"
	 [iam]="true"
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showMenuButton]="false"		     
         [selectedObj]="selectedOrg"
         [titleFilter]="''"
         (selectObjEvent)="setSelectedOrg($event)"		     
	 >
     </app-org-list>
  </ng-container>


  <!--
    <mat-form-field *ngIf="usersvc.User"
		    class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'" [hidden]="false">
      <mat-label class="form-label">Last Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>
      <input matInput placeholder="" 
             value="{{usersvc.User.last_name}}"
             [ngModel]="usersvc.User.last_name"
             type="text" name="user_last_name" class="form-control" formControlName="user_last_name">
    </mat-form-field>
   -->

  <form id="objForm" [formGroup]="objForm">
    <span *ngIf="selectedOrg && selectedOrg.org_owner_email===usrEmail.value">
      Note: this user is the Company/Org. owner/administrator.
    </span>

    <mat-form-field [class.cdk-visually-hidden]="!showHidden" 
      class="form-field" [appearance]="formFieldStyle" [style.display]="'none'" [floatLabel]="'always'">
      <mat-label class="form-label">UID</mat-label>      
      <input matInput placeholder="UID" [hidden]="!showHidden" 
             type="text" name="uid" class="form-control" formControlName="uid">
  </mat-form-field>
    
    <mat-form-field *ngIf="showOrgInfo"
		    [class.cdk-visually-hidden]="!showHidden"
      class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company/Org UID</mat-label>
      <input matInput placeholder="Company/Org. UID" maxlength="10" size="10"
             type="text" name="org_uid" class="form-control"
	     formControlName="org_uid">
    </mat-form-field>

    <mat-form-field *ngIf="showOrgInfo && (orgData$ | async) as uorg"
		    class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'" [hidden]="false">
      <mat-label class="form-label">Company/Org. Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
      <input matInput placeholder="" 
             value="{{uorg.org_name}}"
             [ngModel]="uorg.org_name"
             type="text"
	     name="org_name"
	     class="form-control"
	     formControlName="org_name">
    </mat-form-field>

    <mat-form-field [class.cdk-visually-hidden]="!showHidden"
		    class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'">
      <mat-label class="form-label">User UID</mat-label>
      <input matInput placeholder="User UID" maxlength="10" size="10"
             type="text"
	     name="org_user_uid"
	     class="form-control"
	     formControlName="org_user_uid">
    </mat-form-field>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">User Type</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>
      <mat-select class="form-select"
	 name="org_user_type"	
         formControlName="org_user_type"
         placeholder="User Type"
         [(value)]="userType"
	 [required]="'true'">
         <mat-option class="form-option"
		    *ngFor="let ut of orgUserTypes" [value]="ut.code">
           {{ut.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_user_type.touched || submitted) && objForm.controls.org_user_type.errors?.required">
        User Type is Required or Format Error!</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">User Email (for Login id, Required)</mat-label>
      <mat-icon matPrefix>person</mat-icon> 
      <input #usrEmail
	     matInput
	     (keyup)="checkUserEmail($event)"
	     placeholder="User Email" maxlength="64" size="64"
             type="email" name="org_user_email" class="form-control"
	     formControlName="org_user_email">
      <ng-template #noAcct>
         <mat-hint align="start">User account will be created if it doesnt already exist.</mat-hint>
      </ng-template>
      <ng-container *ngIf="userInfo$ | async as usr">
	<ng-container *ngIf="usr && usr.email; else noAcct">
         <mat-hint align="start">
             User account exists for {{usr.email}} ({{usr.name}} {{usr.last_name}}) 
         </mat-hint>
      </ng-container>
     </ng-container>
    </mat-form-field><br>
    <mat-error class="form-error" *ngIf="(objForm.controls.org_user_email.touched || submitted) && objForm.get('org_user_email').hasError('required')">
      <span class="form-error">        
         User Email is Required!</span>	
    </mat-error>
    <mat-error class="form-error" *ngIf="objForm.get('org_user_email').hasError('email')">
      <span class="form-error">        
         User Email Error: Bad email format!</span>	
    </mat-error>

    <mat-slide-toggle [class.cdk-visually-hidden]="true"
		      id="org_user_active"
		      name="org_user_active"
                      class="form-control-toggle"
 		      style="margin-left: 1rem"
		      formControlName="org_user_active">
                      Active User?
    </mat-slide-toggle><br>

    <!--  [(ngModel)]="objData.org_admin" 
          *ngIf="userType && userType !=='G'"
          [class.cdk-visually-hidden]="userType && userType ==='G'"
          [style.display]="(userType==='G') ? 'none' : 'block'"
      -->
    <mat-slide-toggle *ngIf="objForm.get('org_user_type')?.value !== 'G'"
		      id="org_admin" name="org_admin"
		      (change)="org_admin_change($event)"
                      class="form-field-short"
 		      style="margin-left: 1rem"
		      formControlName="org_admin">
                      Company/Org. Administrator
    </mat-slide-toggle><br><br>

    <!--      [(ngModel)]="objData.org_billing_admin" 
              *ngIf="userType && userType !=='G'"
              [style.display]="(userType==='G') ? 'none' : 'block'"
    -->    
    <mat-slide-toggle *ngIf="objForm.get('org_user_type')?.value !== 'G'"
		      id="org_billing_admin" name="org_billing_admin"
		      (change)="org_billing_admin_change($event)"		      
                      class="form-field-short"
		      formControlName="org_billing_admin"
 		      style="margin-left: 1rem">          
                      Company/Org. Billing Administrator
    </mat-slide-toggle><br><br>

    <!--- *ngIf="userType && userType !=='G'" 
          [style.display]="(userType==='G') ? 'none' : 'block'"
    --->
    <mat-slide-toggle *ngIf="objForm.get('org_user_type')?.value !== 'G'"
		      id="org_guest_user_admin" name="org_guest_user_admin"
                      class="form-field-short"
		      formControlName="org_guest_user_admin"
 		      style="margin-left: 1rem">          
                      Allow Add/Create Guest Users
    </mat-slide-toggle><br><br>
    
    <!--      [(ngModel)]="objData.org_billing_admin" -->    
    <mat-slide-toggle id="org_user_group_admin" name="org_user_group_admin"
                      class="form-field-short"
		      formControlName="org_user_group_admin"
 		      style="margin-left: 1rem">          
                      Allow Group Admin (Add/Create Groups)
    </mat-slide-toggle><br><br><br>

    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">User/Employee ID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>
      <input matInput placeholder="User/Employee ID"
             type="text" name="org_user_emp_id" class="form-control" formControlName="org_user_emp_id">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_user_emp_id.touched || submitted) && 
                objForm.controls.org_user_emp_id.errors?.required">
	 Bad Employee ID Format</span>	
    </mat-error>
    
    <div *ngIf="userType==='G'" style="border: 1px solid;">
    <span>User Specific Expiration Date</span>
    <div style="border: 1px solid;">      
      <br>

    <mat-slide-toggle 
		id="org_user_expire_by_date" name="org_user_expire_by_date"
                (change)="toggleExpirationByDate()"
                class="form-field-short"
                formControlName="org_user_expire_by_date"
                style="margin-left: 1rem">          
                Expire by Date?
    </mat-slide-toggle><br><br>

    <mat-form-field class="form-field-short" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">User Access Expires On:</mat-label>
      <input #expByDate matInput placeholder="User Expires" 
             type="datetime-local" name="org_user_expires" class="form-control"
	     formControlName="org_user_expires">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"      
            *ngIf="(objForm.controls.org_user_expires.touched || submitted) && 
                   (objForm.controls.org_user_expires.errors?.required)">
	    User Expiration Date is Required when Toggled On.</span>
      <span class="form-error"      
            *ngIf="(objForm.controls.org_user_expires.touched || submitted) && 
                   (objForm.controls.org_user_expires.errors?.dateTime)">
	Invalid Expiration Date (yyyy-mm-ddTHH:MM:SS)!</span>
      <span class="form-error"      
            *ngIf="(objForm.controls.org_user_expires.touched || submitted) && 
                   (objForm.controls.org_user_expires.errors?.min ||
                    objForm.controls.org_user_expires.errors?.max)">
	Invalid Expiration Date Length must be (yyyy-mm-ddTHH:MM:SS)!
      </span>      
      <span class="form-error"      
            *ngIf="(objForm.controls.org_user_expires.touched || submitted) && 
                   (objForm.controls.org_user_expires.errors?.min)">
	Invalid Expiration Date Length must be (yyyy-mm-ddTHH:MM:SS)!
      </span>      
    </mat-error>
    </div><br><br>


    <mat-slide-toggle [class.cdk-visually-hidden]="!showHidden"
		id="org_user_expire_by_last_group" name="org_user_expire_by_last_group"
                [checked]="objData?.org_user_expire_by_last_group === true ? true : false"
                (change)="toggleExpirationByGroup()"
                class="form-field-short"
                formControlName="org_user_expire_by_date"
                style="margin-left: 1rem">          
                Expire by Last Group?
    </mat-slide-toggle><br><br>
    </div>

    <mat-form-field [class.cdk-visually-hidden]="!showHidden"
		    class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company/Org. Role</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>      
      <input matInput placeholder="Role"
             type="text" name="org_user_role" class="form-control" formControlName="org_user_role">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_user_role.touched || submitted) && objForm.controls.org_user_role.errors?.required">
	Please enter the user role for the company/org.</span>	
    </mat-error>


    <mat-form-field [class.cdk-visually-hidden]="!showHidden"
		    class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Org. User Subscription ID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>      
      <input matInput placeholder="User Subscription ID"
             type="text" name="org_user_subscription_id" class="form-control" formControlName="org_user_subscription_id">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_user_subscription_id.touched || submitted) && objForm.controls.org_user_subscription_id.errors?.required">
	Org user subscription required!</span>	
    </mat-error>

  <div *ngIf="showActions" class="form-bottom-div">
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more tabs and try again
    </span>
<!-- *ngIf="showActions" class="form-btn-div"> -->
    <div>
       <!--
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       -->
       <button mat-stroked-button class="submit-btn" (click)="navBack()">
               Back</button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode === DisplayMode.EDIT || (mode === DisplayMode.ADD && (objForm.get('org_user_type')?.value=='E' && !empQuotaError) || (objForm.get('org_user_type')?.value=='G' && !guestQuotaError) )" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
       <!---
       <span>userType={{userType}}</span>       
       <span>empQuotaError={{empQuotaError}}</span>
       <span>guestQuotaError={{guestQuotaError}}</span>      
       ----->
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>
  
  </form>
 </div>
</div>


<div #orgform *ngIf="template===DisplayTemplate.QUOTA">
 <div 
     style="display: flex; flex-flow: column; justify-content: space-around; align-content; center; min-width: 90vw; margin-left: 1rem; margin-right: 1rem; overflow: hidden !important; margin-bottom: 7rem;">  

    <div *ngIf="checkQuota">
      <div class="form-title" *ngIf="isAddMode">Checking Quota for Add New User...</div>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>

    <div *ngIf="!checkQuota">
      <div class="form-title" *ngIf="isAddMode">Quota values go here...</div>
    </div>
    
    <div *ngIf="quotaError">
      <div class="form-title" *ngIf="isAddMode && quotaErrorType==='G'">
	Notice: No more GUEST users are available under your subscription
      </div>
      <div class="form-title" *ngIf="isAddMode && quotaErrorType!=='G'">
	Notice: No more EMPLOYEE/MEMBER users are available under your subscription
      </div>
      <p>
	<span>You can reduce the number of currently active users by editing an existing Company/Org/Team
	  User record and setting the expire by date flag and then adding an expiration date prior to
	  this date and time.</span><br>
	<span>You can also increase your active user quotas by updating your subscription or subscription
	  options by navigating to the Subscription form and using the MODIFY button/option.
        </span>
      </p>
    </div>
    
  </div>
</div>


 <!-- Delete Template -->
 <div #orgDelete *ngIf="template === DisplayTemplate.DELETE">
        <div *ngIf="selectedObj; else noObjSelected"
	     class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Delete Company/Org. User {{selectedObj.org_user_email}}
	  </span>
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
	<ng-template #noObjSelected>
	  <div class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Error: No Company/Org. selected!
	  </span>
          </div>
	</ng-template>
        <div class="form-btn-div">
             <button mat-stroked-button id="cancel-delete-obj" class="normal-btn" type="button" value="Cancel" (click)="onCancel()">Cancel</button>	  
             <button mat-stroked-button *ngIf="selectedObj" id="delete-obj" class="delete-btn" type="submit" value="Yes" (click)="deleteObjData(selectedObj.uid)">Yes</button>
        </div>
 </div>

<!-- org component -->    
