<!------------------- Addtl COMPONENTS ------------------------->
<app-new-user-signup #userSignup class="user-signup"></app-new-user-signup>
<app-onboarding #onboarding *ngIf="userInfo && userInfo.new_user" [userInfo]="userInfo" class="onboarding"></app-onboarding>
<app-tos-accept #tosAccept *ngIf="userInfo && userInfo.new_user" [userInfo]="userInfo" class="tos-accept"></app-tos-accept>
<app-password-reset #passwordReset></app-password-reset>

<!-------------------  MENU SECTION --------------------------->

<mat-menu #appMgrMenu="matMenu">
  <button mat-menu-item enabled (click)="sidenavOrgSelect()">
    <span style="font-size: 0.9em">Company/Org. Select</span>
  </button>
  <button mat-menu-item enabled (click)="sidenavOrgCombo()">
    <span style="font-size: 0.9em">Company/Org. Combobox</span>
  </button>
  <button mat-menu-item enabled (click)="sidenavOrgList()">
    <span style="font-size: 0.9em">Company/Org. List</span>
  </button>
  <button mat-menu-item enabled (click)="sidenavOrgTable()">
    <span style="font-size: 0.9em">Company/Org. Table</span>
  </button>
  <button mat-menu-item enabled (click)="sidenavOrgExpandSelect()">
    <span style="font-size: 0.9em">Company/Org. Expand Select</span>
  </button>
</mat-menu>

<mat-menu #topMenu="matMenu">
  <button mat-menu-item enabled (click)="showPageTool = !showPageTool">
    <mat-icon>description</mat-icon>
    <span style="font-size: 0.9em">Page Tool</span>
  </button>
  <button mat-menu-item enabled (click)="showFindTool = !showFindTool">
    <mat-icon>description</mat-icon>
    <span style="font-size: 0.9em">Find Text</span>
  </button>
  <button mat-menu-item enabled (click)="showZoomTool = !showZoomTool">
    <mat-icon>description</mat-icon>
    <span style="font-size: 0.9em">Zoom</span>
  </button>
  <button mat-menu-item onclick="location.href='https://markadoc.com/help/'"
	  matToolTip="Help/FAQ">
    <mat-icon>help</mat-icon>
    <span style="font-size: 0.9em">Help/FAQ</span>
  </button>
  <button mat-menu-item enabled (click)="openAboutDialog()">
    <mat-icon>info</mat-icon>
    <span style="font-size: 0.9em">About</span>
  </button>
  <button mat-menu-item enabled (click)="toggleUIMode()">
    <mat-icon>computer</mat-icon>
    <span style="font-size: 0.9em">Desktop UI Mode</span>
  </button>
  <button mat-menu-item disabled>
    <mat-icon>thumb_up_alt</mat-icon>
    <span style="font-size: 0.9em">Rate This App</span>
  </button>
  <button mat-menu-item (click)="goToLink(paymentURL)">
       <mat-icon>payments</mat-icon>	     
       <span style="font-size: 0.9em">My Subscription</span>
  </button>
  <ng-container *ngIf="userData && userData.iam">
    <button mat-menu-item [matMenuTriggerFor]="appMgrMenu">
      <mat-icon style="color: red">admin_panel_settings</mat-icon>	     
      <span style="font-size: 0.9rem; color: red">App. Management Menu</span>
`    </button>
  </ng-container>
  <ng-container *ngIf="userData && userData.iam">  
    <button mat-menu-item (click)="navAppMgr()">
      <mat-icon style="color: red">admin_panel_settings</mat-icon>	     
      <span style="font-size: 0.9rem; color: red">MD App. Manager</span>
    </button>
  </ng-container>
  <button mat-menu-item enabled (click)="logout()"
	  matToolTip="Logout of the Markadoc Appliction">
    <mat-icon>logout</mat-icon>
    <span style="font-size: 0.9em">Logout</span>
  </button>
</mat-menu>

<mat-menu #orgMenu="matMenu" xPosition="after">
    <button mat-menu-item *ngFor="let org of orgsvc.orgList$ | async"
      (click)="setSelectedOrg(org); openOrgTabDialog();">
      {{org.org_name}}
      <mat-icon *ngIf="selectedOrg && selectedOrg.uid===org.uid">done</mat-icon>
    </button>
</mat-menu>

<mat-menu #orgUserMenu="matMenu" xPosition="after">
    <button mat-menu-item *ngFor="let org of orgsvc.orgList$ | async"
      (click)="setSelectedOrg(org); openOrgUserDialog();">
      {{org.org_name}} Users
      <mat-icon *ngIf="selectedOrg && selectedOrg.uid===org.uid">done</mat-icon>
    </button>
</mat-menu>

<mat-menu #orgContactMenu="matMenu" xPosition="after">
    <button mat-menu-item *ngFor="let org of orgsvc.orgList$ | async"
      (click)="setSelectedOrg(org); openOrgContactDialog();">
      {{org.org_name}} Contacts
      <mat-icon *ngIf="selectedOrg && selectedOrg.uid===org.uid">done</mat-icon>
    </button>
</mat-menu>

<mat-menu #mgrmenu="matMenu" xPosition="after">
    <button
        *ngIf="this.userOrgList && this.userOrgList.length > 0"
      mat-menu-item [matMenuTriggerFor]="orgMenu" >
        <mat-icon>domain</mat-icon>
        <span style="font-size: 0.9rem">Company/Organization</span>
    </button>
    <button
        *ngIf="this.userOrgList && this.userOrgList.length > 0"
      mat-menu-item [matMenuTriggerFor]="orgUserMenu" >
        <mat-icon>domain</mat-icon>
        <span style="font-size: 0.9rem">Company/Org. Users</span>
    </button>
    <button
        *ngIf="this.userOrgList && this.userOrgList.length > 0"
      mat-menu-item [matMenuTriggerFor]="orgContactMenu" >
        <mat-icon>contact_mail</mat-icon>
        <span style="font-size: 0.9rem">Company/Org. Contacts</span>
    </button>
    <button mat-menu-item (click)="openGroupManagerDialog()">
        <mat-icon>groups</mat-icon>
        <span style="font-size: 0.9rem">Groups / Members</span>
    </button>
    <ng-container *ngIf="userInfo && userInfo.accounts>0">
      <button mat-menu-item 
        (click)="openUserSubAccountsDialog()">
        <mat-icon>supervised_user_circle</mat-icon>
        <span style="font-size: 0.9rem">My Guest Sub-Accounts</span>
      </button>
    </ng-container>
    <button mat-menu-item (click)="openUserSettingsDialog()">
        <mat-icon>manage_accounts</mat-icon>	     
        <span style="font-size: 0.9rem">My User Settings</span>
        <span *ngIf="userInfo && userInfo.email"
	      style="font-size: 0.7rem; margin-left: 1rem">({{userInfo.email}})</span>	
    </button>
    <button mat-menu-item (click)="goToLink(paymentURL)">
        <mat-icon>payments</mat-icon>	     
        <span style="font-size: 0.9rem">My Subscription</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="appMgrMenu">
        <mat-icon style="color: red">admin_panel_settings</mat-icon>	     
        <span style="font-size: 0.9rem; color: red">App. Management</span>
    </button>
</mat-menu>

<mat-menu #actionBarMenu="matMenu">
  <button mat-menu-item enabled (click)="actionMode=ACTION_MODE_TOOLS">
    <mat-icon>location_on</mat-icon>
    <span>TOOLS</span>
  </button>
  <button mat-menu-item enabled (click)="actionMode=ACTION_MODE_MARKUP">
    <mat-icon>list</mat-icon>
    <span>MARKUP</span>
  </button>
  <button mat-menu-item enabled (click)="actionMode=ACTION_MODE_FILE">
    <mat-icon>find_in_page</mat-icon>
    <span>FILE</span>
  </button>
  <button mat-menu-item (click)="actionMode=this.ACTION_MODE_VIEW">
    <mat-icon>move</mat-icon>
    <span>VIEW</span>
  </button>
  <button mat-menu-item enabled (click)="sidenavMain()">
    <span class="material-symbols-outlined">left_panel_open</span>
    <span>MAIN MENU</span>
  </button>
  <button mat-menu-item enabled (click)="showActionBar=!showActionBar">
    <mat-icon>close</mat-icon>
    <span>CLOSE</span>
  </button>
</mat-menu>


<!-------------------  GENERAL TEMPLATE SECTION ----------------------->

<!--
<ng-template #groupSelector>
	 <mat-select 
          class="group-select"
          [(ngModel)]="selectedGroup"
          [ngModelOptions]="{standalone: true}"
          ngDefaultControl selected="selectedGroup"
          style="z-index: 900; color: #2274A5; font-size: 0.8rem; width: 60%; border-bottom: 2px solid #2274A5;; margin-left: 2rem; margin-right: 1rem;"
          (selectionChange)="setSelectedGroup(selectedGroup)">
          <mat-option (click)="openCreateGroupDialog()" id="new_group" style="color: blue; font-size: 0.8rem"> + create new group...</mat-option>
          <mat-option *ngFor="let g of this.groups" [value]="g.uid" style="color: #2274A5;: font-size: 0.8rem">{{g.name}}<span *ngIf="userInfo && userInfo.uid===g.owner" style="font-size: 0.8rem"> &#9733;</span></mat-option>
	 </mat-select>
</ng-template>
-->

<ng-template #groupSelector>
       <app-group-list #grpList class="left-content-scroll"
         style="margin: 0; padding: 0; width: 80vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"		     
         [mode]="'C'"
         [userInfo]="userInfo"
         [userData]="userData"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showAddButton]="false"
         [showMenuButton]="false"		       
         [showListViewButton]="false"
         [selectedObj]="selectedGroupObj"
         (selectObjEvent)="setSelectedGroupObj($event)"		     
         >
	 </app-group-list>
</ng-template>

<ng-template #groupBtns>
        <div class="group-btns">
          <button mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openCreateGroupDialog()"
                  matTooltip="Add Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">group_add</mat-icon>
                  <div class="label-text">Add</div>		  
          </button>
          <button *ngIf="selectedGroupObj && selectedGroupObj.owner === userData.uid"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openRenameGroupDialog(selectedGroup)"
                  matTooltip="Rename Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">edit</mat-icon>
                  <div class="label-text">Rename</div>		  
          </button>
          <button mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="sidenavGroupManager()"
                  matTooltip="Edit Groups & Members">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">manage_accounts</mat-icon>
                  <div class="label-text">Manage</div>		  
          </button>
          <button *ngIf="selectedGroupObj"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="sidenavGroupUpload()"
                  matTooltip="Add Files/Content">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">note_add_outlined</mat-icon>
                  <div class="label-text">+Files</div>
          </button>
          <button *ngIf="selectedGroupObj && selectedGroupObj.owner === userData.uid"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openDeleteGroupDialog(selectedGroupObj.uid, selectedGroupObj.name)"
                  matTooltip="Delete Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0; color: red;">delete_forever</mat-icon>
                  <div class="label-text">Delete</div>		  
          </button>
        </div>
</ng-template>

<!--------------------- TOP TOOLBAR TEMPLATES ------------------->

<ng-template #actionModeTools>
<!--     </div> <!-- action-bar -->
</ng-template>

<ng-template #actionModeMarkup>
  <div id="actionbar-markup" class="top-action-bar-actions" style="margin-top: 2px;">
     <div id="file-info" class="action-icon-btn" (click)="openFileInfoDialog(documentsvc.activeDocument)">
         <mat-icon class="action-icon" aria-label="File Info">info_outlined</mat-icon>
         <span class ="action-label">File Info</span>
     </div>
     <button mat-icon-button id="file-info1"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon aria-label="File Info">info_outlined</mat-icon>
     </button>
     <button mat-icon-button
	    (click)="showActionBar = !showActionBar">
            <mat-icon>close</mat-icon>
     </button>
     <button mat-icon-button
	    (click)="showActionBar = !showActionBar">
            <mat-icon>close</mat-icon>
     </button>
   </div>
  <div id="actionbar-markup" class="top-action-bar-actions">
     <div id="file-info" class="action-icon-btn" (click)="openFileInfoDialog(documentsvc.activeDocument)">
         <mat-icon class="action-icon" aria-label="File Info">info_outlined</mat-icon>
         <span class ="action-label">File Info</span>
     </div>
     <button mat-icon-button
	    (click)="showActionBar = !showActionBar">
            <mat-icon>close</mat-icon>
     </button>
   </div>
</ng-template>

<ng-template #actionBarContainer>
  <mat-toolbar *ngIf="uiMode===UI_MOBILE&&showActionBar&&platformsvc.orientation==='Portrait'" #toptoolbar class="mat-toolbar" role="toolbar">
    
     <div class="top-action-bar-items">
         <div class="top-action-bar-title"
	    [matMenuTriggerFor]="actionBarMenu">
            <mat-icon>menu</mat-icon>	 
            <span style="margin: 3px;">{{actionMode}}</span>
         </div>
     <!-- TOOLS -->
     <div *ngIf="actionMode===ACTION_MODE_TOOLS">
       <div cdkDropList [cdkDropListAutoScrollDisabled]="false" #tools="cdkDropList" [cdkDropListConnectedTo]="[overlaycontent]" id="tools" class="top-action-bar-actions">
            <div id="tools-question" class="sidenav-section-content sidenav-icon" matTooltip="Question" matTooltipPosition="left" aria-label="Question" cdkDrag [cdkDragData]="noteData.question" [cdkDragDisabled]="!documentsvc.activeDocument" (cdkDragEnded)="dragEnd($event)" style="marginTop: 0;">
              <img class="action-bar-icon" src="/assets/question_icon.png"/><br/>
            </div>
            <div id="tools-note" class="sidenav-section-content sidenav-icon" matTooltip="Comment" matTooltipPosition="left" aria-label="Comment" cdkDrag [cdkDragData]="noteData.note" [cdkDragDisabled]="!documentsvc.activeDocument" style="marginTop: 0;">
              <img class="action-bar-icon" src="/assets/note_icon.png"/><br/>
            </div>
            <div id="tools-issue" class="sidenav-section-content sidenav-icon" matTooltip="Issue" matTooltipPosition="left" aria-label="Issue" cdkDrag [cdkDragData]="noteData.issue" [cdkDragDisabled]="!documentsvc.activeDocument" style="marginTop: 0;">
              <img class="action-bar-icon" src="/assets/issue_icon.png"/><br/>
            </div>
            <div id="tools-note" class="sidenav-section-content sidenav-icon" matTooltip="Approval" matTooltipPosition="left" aria-label="Approval" cdkDrag [cdkDragData]="noteData.note" [cdkDragDisabled]="!documentsvc.activeDocument" style="marginTop: 0;">
              <img class="action-bar-icon" src="/assets/approval_icon.png"/><br/>
            </div>
     </div>
     <button mat-icon-button
	    (click)="showActionBar = !showActionBar">
            <mat-icon>close</mat-icon>
     </button>
    </div>
    <!-- MARKUP -->
    <div id="actionbar-markup" class="top-action-bar-actions" *ngIf="actionMode===ACTION_MODE_MARKUP">
     <button mat-icon-button id="file-info1" class="action-icon-btn"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon class="action-icon" aria-label="File Info">
	     info_outlined</mat-icon>
             <div class="action-label">File Info</div>
     </button>
     <button mat-icon-button id="file-info1" class="action-icon-btn"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon class="action-icon" aria-label="File Info">
	     info_outlined</mat-icon>
             <div class="action-label">File Info</div>
     </button>
     <button mat-icon-button id="file-info1" class="action-icon-btn"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon class="action-icon" aria-label="File Info">
	     info_outlined</mat-icon>
             <div class="action-label">File Info</div>
     </button>
     <button mat-icon-button
	    (click)="showActionBar = !showActionBar">
            <mat-icon>close</mat-icon>
     </button>
     </div>
    <!-- FILE -->
    <div id="actionbar-markup" class="top-action-bar-actions" *ngIf="actionMode===ACTION_MODE_FILE">
     <button mat-icon-button id="file-info1" class="action-icon-btn"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon class="action-icon" aria-label="File Info">
	     info_outlined</mat-icon>
             <div class="action-label">File Info</div>
     </button>
     <button mat-icon-button id="file-info1" class="action-icon-btn"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon class="action-icon" aria-label="File Info">
	     info_outlined</mat-icon>
             <div class="action-label">File Info</div>
     </button>
     <button mat-icon-button id="file-info1" class="action-icon-btn"
	     (click)="openFileInfoDialog(documentsvc.activeDocument)">
             <mat-icon class="action-icon" aria-label="File Info">
	     info_outlined</mat-icon>
             <div class="action-label">File Info</div>
     </button>
     <button mat-icon-button
	    (click)="showActionBar = !showActionBar">
            <mat-icon>close</mat-icon>
     </button>
     </div>
     </div>
  </mat-toolbar>
</ng-template>

<!--------------------- LEFT TOOLBAR TEMPLATES ------------------>
<ng-template #quickBtnTemplate>
        <button #quickbtn mat-raised-button class="icon-action-button"
		(click)="openQuickStartDialog();" style="width: 100%;"
		>
       	  <mat-icon style="margin-right: auto;">bolt</mat-icon>
	  <span class="action-button-text">QUICK START</span>
        </button>
</ng-template>
<ng-template #uploadBtnTemplate>
        <button  #uploadbtn mat-raised-button
		class="icon-action-button" (click)="openUploadFilesDialog();"
		style="width: 99%;"
		>
       	  <mat-icon style="margin-right: auto;">note_add_outlined</mat-icon>
	  <span class="action-button-text">ADD FILES</span>
        </button>
</ng-template>


<!--------------------- RIGHT TOOLBAR SUB TEMPLATES -------------->
<ng-template #searchOn>
   <mat-icon aria-label="File Info">search</mat-icon>
</ng-template>

<ng-template #matShare>
   <mat-icon aria-label="File Info">share</mat-icon>
</ng-template>

<ng-template #showMarkers>
   <span class ="sidenav-label-mobile">Show Markers</span>	   
</ng-template>

<ng-template #sendTokenMobile>
   <div id="send-token" class="sidenav-icon"
        matTooltip="Send 24 Hour Access Token"	     
        aria-label="Send 24 Hour Access Token"
        (click)="sidenavInviteToken()">	     
        <a><mat-icon class="material-icons-outlined">schedule_send</mat-icon></a><br/>
        <span class ="sidenav-label-mobile">Send Invitation</span>
   </div>
</ng-template>

<ng-template #sendTokenDesktop>
   <div id="send-token" class="sidenav-icon"
        matTooltip="Send 24 Hour Access Token"
        matTooltipPosition="left"	
        matTooltipPosition="left"
        (click)="openSendTokenDialog()">
        <a><mat-icon class="material-icons-outlined">schedule_send</mat-icon></a><br/>
        <span class ="sidenav-label-mobile">Send Invitation</span>
   </div>
</ng-template>

<!--------------------- RIGHT TOOLBAR TEMPLATE(s)  -------------->

<ng-template #rightToolbarTemplate>
    <div id="markup" class="sidenav-section-header" (click)="collapseComments('marker-section');toggleMarkup = !toggleMarkup" matTooltip="Toggle Markers" matTooltipPosition="right" aria-label="Toggle Markup">MARKUP<mat-icon id="fiber_manual_record">{{ toggleMarkup ? 'fiber_manual_record' : 'remove' }}</mat-icon>
    </div>
    
      <div id="marker-section" class="marker-section">
	    <app-marker-list #markerList 
	      [template]=3
	      [userInfo]=userInfo
	      [noteData]=this.noteData
	      [documentUID]=selectedDocUID
	      (scrollToNoteEvent)=scrollToNote($event)
              (displayUserInfoEvent)=openUserInfoDialog($event)
              (displayMarkerButtonsEvent)=displayMarkerButtons($event)
	      > 
	    </app-marker-list>
         <div #markerToolButtons *ngIf="documentsvc.activeDocument" class="marker-tool-buttons">
      <div class="sidenav-section-bottom" style="margin-bottom: 0.5em">		
       <div id="marker-report" class="sidenav-icon" (click)="openMarkerReportDialog();">
         <a><mat-icon class="sidenav-icon"
	      aria-label="Marker Report">summarize</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Marker Report</span>
       </div>
      </div>
	    
      <div class="sidenav-section-bottom" style="margin-bottom: 0.5em">		
       <div id="show-comments" class="sidenav-icon" (click)="hide_tools();eyeIcon=!eyeIcon;">
         <a><mat-icon class="sidenav-icon"
		      matTooltip="Toggle Markers"
		      matTooltipPosition="left"
		      aria-label="Toggle Markers">{{eyeIcon ? 'visibility_off': 'visibility'}}</mat-icon></a><br/>
         <span *ngIf="ishiding === false || ishiding === null; else showMarkers" class ="sidenav-label-mobile">Hide Markers</span>
       </div>
      </div>

      <div class="sidenav-section-bottom" style="margin-bottom: 0.5em">		
       <div id="show-comments" class="sidenav-icon" *ngIf="!ishiding"(click)="show_comments();commentIcon=!commentIcon;">
         <a><mat-icon class="sidenav-icon"
              matTooltip="Show Comments" matTooltipPosition="left"	      
	      aria-label="File Info">{{commentIcon ? 'chat_bubble': 'chat_bubble_outline'}}</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Marker Details</span>
       </div>
      </div>

	 </div> <!-- marker tool buttons -->
    </div> <!-- marker section -->

    <div id="file-button" class="sidenav-section-header" (click)="collapseComments('file-tools-content');toggleFileTools=!toggleFileTools" matTooltip="Toggle File Tools" matTooltipPosition="right" aria-label="Toggle File Tools">FILE<mat-icon id="fiber_manual_record" style="padding-top: 0%; padding-bottom: 0%">{{ toggleFileTools ? 'fiber_manual_record' : 'remove' }}</mat-icon>
        </div>
    <div id="file-tools-content" class="sidenav-section-bottom">
      <div *ngIf="documentsvc.activeDocument"
	   class="sidenav-section-bottom" style="margin-bottom: 0.5em">		
       <div id="file-info" class="sidenav-icon" (click)="openFileInfoDialog(documentsvc.activeDocument)">
         <a><mat-icon aria-label="File Info">info_outlined</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">File Info</span>
       </div>
      </div>

      <div *ngIf="documentsvc.activeDocument"
	   class="sidenav-section-bottom" style="margin-bottom: 0.5em">	
       <div id="reload" class="sidenav-icon" (click)="loadDocument(documentsvc.activeDocument, documentsvc.pdfPageNbr)">
         <a><mat-icon class="material-icons-outlined">restore_page</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Reload File</span>
       </div>
      </div>

      <div *ngIf="documentsvc.activeDocument"
	   class="sidenav-section-bottom" style="margin-bottom: 1.25em">
       <div id="close-file" class="sidenav-icon" (click)="openCloseFileDialog()">
         <a><mat-icon class="material-icons-outlined">close</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Close File</span>
       </div>
      </div>
      
      <div *ngIf="documentsvc.activeDocument"
	   class="sidenav-section-bottom" style="margin-bottom: 1em">   
         <button mat-stroked-button
		 style="font-size: 0.8rem"
                 aria-label="Page Tool"
                 id="page"
                 (click)="showPageTool = !showPageTool"
	         >Page: {{documentsvc.pdfPageNbr}}
	 </button>
       </div>       
      
       <div *ngIf="documentsvc.activeDocument"
	    class="sidenav-section-bottom" style="margin-bottom: 1em">
       <div id="find" class="sidenav-icon" (click)="showFindTool = ! showFindTool">
         <a><mat-icon *ngIf="showFindTool; else searchOn"
		      aria-label="File Info">search_off</mat-icon>
	 </a><br/>
         <span class ="sidenav-label-mobile">Find</span>
       </div>
      </div>

      <div *ngIf="documentsvc.activeDocument && ! platformsvc.isAndroid"
	    class="sidenav-section-bottom" style="margin-bottom: 0.5em">
         <div id="print" class="sidenav-icon"
              (click)="openPrintSheet()">
         <a><mat-icon class="material-icons-outlined">print</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Print</span>
	</div>
       </div>

       <div *ngIf="documentsvc.activeDocument"
	    class="sidenav-section-bottom" style="margin-bottom: 0.5em">
         <div id="download" class="sidenav-icon"
              (click)="openDownloadSheet()">
         <a><mat-icon class="material-icons-outlined">download</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Download</span>
	</div>
       </div>
       
       <div *ngIf="documentsvc.activeDocument && appSharePlugin"
	    class="sidenav-section-bottom" style="margin-bottom: 0.5em">
       <div id="share" class="sidenav-icon" (click)="shareDocument(documentsvc.activeDocument)">
         <a><mat-icon *ngIf="platformOS === 'ios'; else matShare"
		      aria-label="File Info">ios_share</mat-icon>
	 </a><br/>
         <span class ="sidenav-label-mobile">Share</span>
       </div>
      </div>

       
       <div *ngIf="documentsvc.activeDocument"
	    class="sidenav-section-bottom" style="margin-bottom: 0.5em">
	<div id="copy-link" class="sidenav-icon"
	      [cdkCopyToClipboard]="this.activeDocumentLink"
              [cdkCopyToClipboardAttempts]="5"
              aria-label="Copy Link to Clipboard"	     
	      (click)="notifyDocumentLink()">
         <a><mat-icon class="material-icons-outlined">link</mat-icon></a><br/>
         <span class ="sidenav-label-mobile">Copy Link</span>
       </div>
      </div>

      <div *ngIf="documentsvc.activeDocument&&uiMode===UI_DESKTOP"
	   class="sidenav-section-bottom" style="margin-bottom: 0.5em">
           <ng-container *ngTemplateOutlet="sendTokenDesktop">
	   </ng-container>
      </div>
      <div *ngIf="documentsvc.activeDocument&&uiMode===UI_MOBILE"
	   class="sidenav-section-bottom" style="margin-bottom: 0.5em">
           <ng-container *ngTemplateOutlet="sendTokenMobile">
	   </ng-container>
      </div>

      </div> <!-- file tools -->

	<div id="view-button" class="sidenav-section-header" (click)="collapseComments('view-tools-content');toggleViewTools=!toggleViewTools" matTooltip="Toggle View Tools" matTooltipPosition="right" aria-label="Toggle View Tools">VIEW<mat-icon id="fiber_manual_record" style="padding-top: 0%; padding-bottom: 0%">{{ toggleViewTools ? 'fiber_manual_record' : 'remove' }}</mat-icon>
        </div>
	<div id="view-tools-content">

      <div *ngIf="documentsvc.activeDocument"
	   class="sidenav-section-bottom" style="margin-bottom: 1em">   
         <button mat-stroked-button
		 style="font-size: 0.8rem"
                 aria-label="Zoom Tool"
                 id="zoom-tool"
		 class="zoom-tool"
                 (click)="showZoomTool = !showZoomTool"
	         >Zoom: {{documentsvc.pdfZoom * 100}}%
	 </button>
       </div>       

          <div *ngIf="documentsvc.activeDocument"
	       class="sidenav-section-bottom" style="margin-bottom: 0.5em">
            <div (click)="pdf_zoom_in(0.25)">
                <a><mat-icon class="sidenav-icon" matTooltip="Zoom In" matTooltipPosition="left" aria-label="Zoom in" >zoom_in</mat-icon></a><br/>
                <span class ="sidenav-label-mobile">Zoom In</span>
            </div>
	  </div>
          <div *ngIf="documentsvc.activeDocument"
	       class="sidenav-section-bottom" style="margin-bottom: 0.5em">
            <div (click)="pdf_zoom_out(0.25)">
                <a><mat-icon class="sidenav-icon" matTooltip="Zoom Out" matTooltipPosition="left" aria-label="Zoom Out">zoom_out</mat-icon></a><br/>
                <span class ="sidenav-label-mobile">Zoom Out</span>
            </div>
	  </div>
          <div *ngIf="documentsvc.activeDocument"
	       class="sidenav-section-bottom" style="margin-bottom: 0.5em">
            <div (click)="pdf_rotate(90)">
              <a><mat-icon [matBadge]="documentsvc.pdfRotation"
			   matBadgeOverlap=false
			   class="sidenav-icon"
			   matTooltip="Rotate"
			   matTooltipPosition="left"
			   aria-label="Rotate pdf">text_rotation_angledown</mat-icon>
	      </a><br/>
              <span class ="sidenav-label-mobile">Rotate</span>
            </div>
	  </div>
          <div *ngIf="documentsvc.activeDocument"
	       class="sidenav-section-bottom" style="margin-bottom: 0.5em">
            <div (click)="hide_panels()">
                <a><mat-icon class="sidenav-icon" matTooltip="Full Screen" matTooltipPosition="left" aria-label="Full Screen">fullscreen</mat-icon></a><br/>
                <span class ="sidenav-label-mobile">Full Screen</span>
            </div>
          </div>
          <div *ngIf="documentsvc.activeDocument"
	       class="sidenav-section-bottom" style="margin-bottom: 4em">
            <div (click)="refreshbrowse()">
              <a><mat-icon class="sidenav-icon"
			   matTooltip="Reload App"
			   matTooltipPosition="left"
			   aria-label="Reload App"
			   id="cached">cached</mat-icon></a><br>
                <span class ="sidenav-label-mobile">Reload App</span>  
            </div>
	  </div>
        </div> <!-- view tools -->
</ng-template>  <!-- rightToolbarTemplate -->

<!--------------- END RIGHT TOOLBAR TEMPLATE ---------------------->

<!-------------------  MAIN COMPONENT ------------------------->

 <div #uiContainer class="ui-container">

   <mat-toolbar *ngIf="uiMode===UI_MOBILE&&!showActionBar;else actionBarContainer" #toptoolbar class="mat-toolbar" role="toolbar">
     
     <div class="toolbar-left">
       <mat-icon *ngIf="sidenavOpen===false;else leftMenuOpen"
                 class="left-toolbar-menu-icon" #leftMenu
		 style="font-size: 30px;"
	         (click)="sidenavleft.toggle();">menu</mat-icon>
       <ng-template #leftMenuOpen>
         <mat-icon class="left-toolbar-menu-icon" #leftMenu
 		   style="font-size: 30px;"		   
                  (click)="sidenavleft.toggle();">menu_open</mat-icon>
       </ng-template>
       <img class="logo-img" src="../assets/Markadoc-logo-200TR.png"
	    style="font-size: 30px;"
            alt="Markadoc" [routerLink]="'/'"/>
    </div>
    <div class="toolbar-right" style="margin-bottom: 0.2em">
       <mat-progress-spinner #progress
          class="progress"
	  style="margin-top: -0.3rem; padding-top: 0; margin-right: 2rem"
	  diameter="24"
	  mode="determinate"
          [value]="documentsvc.pdfProgress">
	 </mat-progress-spinner>
       <mat-icon class="toolbar-menu-icon" #rightMenu
		 [matMenuTriggerFor]="topMenu"
		 style="margin-right: 5rem; margin-bottom: 0.15em; font-size: 24px;"
		 aria-label="Actions Menu">more_vert</mat-icon>
       <div *ngIf="platformsvc.orientation!=='Portrait';else actionBarBtn">
       <div #toolbarClosed
            style="margin-right: 1.5rem; font-size: 24px"
            *ngIf="sidenavright.opened; else toolbarOpened"	    
	    class="toolbar-icon"
            (click)="sidenavright.toggle()">	    
         <mat-icon class="toolbar-marker-icon" #markers
                 aria-label="Tools">view_sidebar</mat-icon>
         <mat-icon class="toolbar-marker-icon" #chevron
		   style="margin: 0; padding: 0"
                   aria-label="Tools">chevron_right</mat-icon>
       </div>	 
       <ng-template #toolbarOpened>
         <div class="toolbar-icon"
                 style="margin-right: 1.5rem; font-size: 24px;"	      
                 (click)="sidenavright.toggle()">
            <mat-icon class="toolbar-marker-icon" #markers
                      aria-label="Tools">chevron_left</mat-icon>
            <mat-icon class="toolbar-marker-icon" #chevron
                      aria-label="Tools">view_sidebar</mat-icon>
            </div>
       </ng-template>
       </div>
       <ng-template #actionBarBtn>
       <mat-icon class="toolbar-menu-icon" #actionBarIcon
		 style="margin-right: 4rem; margin-bottom: 0.15em"
		 aria-label="Action Bar"
		 (click)="showActionBar=!showActionBar">build</mat-icon>
       </ng-template>
    </div> <!-- toolbar-right -->
   </mat-toolbar>
 
   <!-- <mat-sidenav-container class="controls-container"> -->

  <mat-sidenav-container class="mobile-sidenav-container"
			 autosize autoresize="true" autosize="true">

    <!-- [style.marginBottom.px]="platformsvc.sidenavMarginBottom"> -->

<!--  <ng-container *ngIf="platformsvc.uiMode===UI_DESKTOP"> -->

<!--	       fixedTopGap="500px"	   -->
  <mat-sidenav #sidenavleft opened
	       mode="side"
	       class="sidenav sidenav-left"
	       id="left-sidenav-id"
	       fixedInViewport="true"
               (opened)="sidenavOpen=true"
	       (closed)="sidenavOpen=false"
               *ngIf="platformsvc.uiMode===UI_DESKTOP">	       
      
        <div> <a href="https://www.markadoc.com"><img src="/assets/Markadoc-logo-200TR.png" id="img-logo" alt="Mark a doc logo"></a> </div>
	<div class="action-button-group" style="display: flex; flex-flow: column; justify-content: flex-start; align-items: stretch; flex-grow: 1; margin-left: 5%; margin-right: 5%;">
        <button *ngIf="groups!==null" #quickbtn mat-raised-button class="icon-action-button"
		(click)="openQuickStartDialog();" style="width: 100%;"
		>
       	  <mat-icon style="margin-right: auto;">bolt</mat-icon>
	  <span class="action-button-text">QUICK START</span>
        </button>
        <button *ngIf="groups!==null" #uploadbtn mat-raised-button
		class="icon-action-button" (click)="openUploadFilesDialog();"
		style="width: 99%;"
		>
       	  <mat-icon style="margin-right: auto;">note_add_outlined</mat-icon>
	  <span class="action-button-text">ADD FILES</span>
        </button>
        <button #newgroupbtn mat-raised-button class="icon-menu-button"
		class="icon-action-button"
                [matMenuTriggerFor]="mgrmenu"
                style="width: 99%;">		  
          <mat-icon style="">settings</mat-icon>
	  <span class="action-button-text">SETUP/MANAGE</span>
          <mat-icon style="padding-top: 1%;">chevron_right</mat-icon>
        </button>
     </div>
     <div class="sidenav-section-content">
        </div>
        <div class="sidenav-section-header" (click)="hide_recents(); toggleFiber2=!toggleFiber2" matTooltip="Toggle Recents" matTooltipPosition="right" aria-label="Toggle Recents">RECENTS <mat-icon id="fiber_manual_record">{{ toggleFiber2 ? 'fiber_manual_record' : 'remove' }}</mat-icon></div>

        <li *ngFor="let d of get_most_recent()" [ngClass]="{'active': d.uid === documentsvc.activeDocument}" class="recent-items-list">
            <button *ngIf="userInfo && (d.owner === userInfo.uid || d.users_uid === userInfo.uid)" mat-raised-button  (click)="openDeleteFileDialog(d)" id="delete-button"><mat-icon id="delete-icon">delete</mat-icon></button>
            <span (click)="openDocument(d)">{{d.name}}</span>
        </li>
        <div class="sidenav-section-header" (click)="collapseComments('group-content'); toggleFiber1=!toggleFiber1" matTooltip="Toggle Groups" matTooltipPosition="right" aria-label="Toggle Groups">
          GROUPS <mat-icon id="fiber_manual_record">{{ toggleFiber1 ? 'fiber_manual_record' : 'remove' }}</mat-icon></div>
        <div class="sidenav-section-content" id="group-content">
          <mat-accordion class="accordian-margin" style="width:100%">
            <button class="folder-icon" matRipple
		    (click)="openCreateGroupDialog()"
		    matTooltip="Create Group">
	            <mat-icon>group_add</mat-icon></button>
            <button class="folder-icon" matRipple
		    (click)="openGroupManagerDialog()"
		    matTooltip="Edit Groups & Members">
	      <mat-icon>manage_accounts</mat-icon></button>
            <button class="folder-icon" matRipple
		    (click)="openUploadFilesDialog()"
		    matTooltip="Add Files/Content">
	            <mat-icon>note_add_outlined</mat-icon></button>
            <mat-expansion-panel *ngFor="let g of groups; let i = index" (opened)="isMembersVisible = false; panelOpenState = true" (closed)="panelOpenState = false" (click)="panelClick(g)">
              <mat-expansion-panel-header [collapsedHeight]="'40px'"
                style="margin-top: 0em; margin-botton: 0rem">
                <mat-panel-title id="user-group-title"> {{g.name}} <mat-icon *ngIf="userInfo && (g.owner === userInfo.uid)" id="admin-star">star</mat-icon> </mat-panel-title>
                </mat-expansion-panel-header>
                    <button class="folder-icon" matRipple
                          (click)="selectedGroup=g.uid; openUploadFilesDialog()"
		          matTooltip="Add Files/Content"
			  style="margin-top: 0rem; margin-bottom: 0.8rem"
			  >
		      <mat-icon style="font-size: 1.7em">note_add</mat-icon>
		    </button>		
                <div *ngIf="userInfo && (g.owner === userInfo.uid)" class="folder-management-div">
                    <button class="folder-icon" matRipple (click)="groupMgrTabIndex=2; selectedGroup=g.uid;openGroupManagerDialog()" matTooltip="Add/Edit Group Members">
		    <mat-icon style="font-size: 1.7em;">manage_accounts</mat-icon></button>
                    <button class="folder-icon" matRipple (click)="openRenameGroupDialog(g.uid)" matTooltip="Rename Group">
		    <mat-icon style="font-size: 1.7em;">edit</mat-icon></button>
                    <button class="folder-icon" matRipple (click)="openDeleteGroupDialog(g.uid, g.name)" matTooltip="Delete Group">
		    <mat-icon style="font-size: 1.7em;">delete_forever</mat-icon></button>
                </div>
                <ul class="sidenav-list set-list">
                    <ng-container *ngFor="let d of documents">
                        <li *ngIf="d.groups_uid === g.uid" [ngClass]="{'active': d.uid === documentsvc.activeDocument}" class="items-list">
                            <button *ngIf="userInfo && (g.owner === userInfo.uid || d.users_uid === userInfo.uid)" mat-raised-button  color="warn" (click)="openDeleteFileDialog(d)" id="delete-button"><mat-icon id="delete-icon">delete</mat-icon></button>
                            <span (click)="openDocument(d);leftNavMode=NAV_MAIN_MENU">{{d.name}}</span>
                        </li>
                    </ng-container>
                </ul>

	<!-- show buttons for members below ui -->
        <button mat-button-legacy class="members-button" (click)="show_members(g.uid);">Members</button>
       <app-group-member-list #memberView *ngIf="isMembersVisible"
          style="text-align: center; align-items: center"
          id="member-div2-{{i}}"
          (displayUserInfoEvent)="this.openUserInfoDialog($event)"
          (removeMemberEvent)="this.handleRemoveMember($event)"
          [template]=4
          [userInfo]="userInfo"
          [group]="g"
          [groupUID]="g.uid"
          [(groupList)]="this.groups"
       ></app-group-member-list>
       <div>
          Number of Documents: {{countDocs(g.uid)}}
       </div>
    
       </mat-expansion-panel>
       </mat-accordion>
       </div>  
       <div id="document-data">{{getNumberOfDocuments()}} documents, {{getTotalFileSizeStr()}}</div>

       <div class="logout-section-header">
	 <div class="buttons-and-logout">
            <div class="spacingFeedFaq">
              <span class="feedbackAndFaq" style="position: relative; border-right: 4px solid #c3c3c3">
		<div (click)="openFeedbackDialog()"> <mat-icon class="feedback-icon" matTooltip="Feedback Form"
    aria-label="Feedback form" >feedback</mat-icon></div>
              </span>
              <span class="feedbackAndFaq" style="position: relative; border-right: 6px solid #c3c3c3" onclick="location.href='https://markadoc.com/help/'">
                  <mat-icon class="feedback-icon" matTooltip="FAQ" aria-label="FAQ">help</mat-icon>
              </span>
              <span class="feedbackAndFaq" style="border-right: 4px solid #c3c3c3">
		<div (click)='toggleUIMode()'> <mat-icon matTooltip="Mobile UI Mode"
    aria-label="Feedback form" >phone_iphone</mat-icon></div>
              </span>
            </div>
            <div>	    
              <!-- <a href='#'> -->
	      <button id="logout-button" mat-button  (click)="logout()">Logout</button> <!-- </a> -->
            </div>
	  </div>
        </div>

  </mat-sidenav>

  <mat-sidenav #sidenavleft opened
	       mode="side"
	       class="sidenav mobile-sidenav-left"
	       id="left-sidenav-id"
	       fixedInViewport="true"
	       [style.top.px]="platformsvc.sidenavMarginTop"
               (opened)="sidenavOpen=true"
	       (closed)="sidenavOpen=false"
               *ngIf="platformsvc.uiMode===UI_MOBILE">	       


    <mat-divider></mat-divider>
    <mat-toolbar class="left-sidenav-toolbar">
      <div class="left-sidenav-toolbar-div"
       style="display: flex; flex-flow: row; justify-content: space-between; align-items: center; width: 100%;">
	<div class="left-sidenav-toolbar-left">
          <button mat-icon-button>
            <mat-icon
                style="font-size: 1.8em; margin-left: 0em; margin-top: 0em;"
		(click)="sidenavBack()">
                arrow_back_ios</mat-icon>
	  </button>
          <span (click)="sidenavBack()"
               style="font-size: 0.8em; margin-left: 0.1em; margin-top: .1em; margin-right: 0.3em;"
  	       >{{leftNavMode}}</span>
	</div>
	
	<div *ngIf="leftNavMode===NAV_GROUP || leftNavMode===NAV_ADD_GROUP_MEMBER || leftNavMode===NAV_ADD_GROUP_MEMBERS || ( (leftNavMode===NAV_GROUP_MANAGER || leftNavMode===NAV_SETUP_GROUP_MGR) && groupTabIndex !== 0)"
	   style="flex-grow: 4; margin-top: 0.5rem; margin-left: 1rem;">
           <ng-container #toolbarGroupSelect
			 *ngTemplateOutlet="groupSelector"
                         style="width: 95%;">		 
           </ng-container>
       </div>

       <div *ngIf="leftNavMode===NAV_GROUP_MANAGER"
	    style="margin-right: 1rem; min-width: 70%;">
         <mat-icon (click)="openCreateGroupDialog()"
		   style="margin-top: 0.5rem; margin-right: 1rem;"
		   >add</mat-icon>
       </div>
       
       <div *ngIf="leftNavMode===NAV_GROUP_MANAGER && groupTabIndex===0"
	    style="margin-right: 10rem">
         <span class="showall"
         style="font-size: 0.9rem; align-self: flex-start; margin-right: 3rem; padding-right: 10rem;"
           (click)="showAllGroups = !showAllGroups;handleShowGroups()">All
           <mat-checkbox
	     class="showall" style="margin-right: 10rem;"
             [(ngModel)]="showAllGroups"
	 (ngModelChange)="handleShowGroups()"
	     (click)="showAllGroups = !showAllGroups;handleShowGroups()">
          </mat-checkbox>
         </span>
       </div>
       
       <div *ngIf="leftNavMode===NAV_GROUP"
	    style="margin-right: 1rem">
         <mat-icon (click)="sidenavGroupList()"
		   style="margin-top: 0.5rem; margin-left: 1rem; margin-right: 1rem;"
		   >search</mat-icon>
       </div>

       <div *ngIf="leftNavMode===NAV_USER_LIST" >
         <mat-form-field fxFlex="25%" style="font-size: 0.75rem">
               <input matInput type="text" (keyup)="userFilter($event.target.value)" placeholder="Enter User Search filter"/>
            </mat-form-field>   
       </div>

       <div *ngIf="leftNavMode===NAV_ORG_LIST" >
       </div>
       
       <div *ngIf="leftNavMode===NAV_ORG_TABLE" >
         <mat-form-field fxFlex="25%" style="font-size: 0.75rem">
               <input matInput type="text" (keyup)="orgFilter($event.target.value)" placeholder="Enter Org Search filter"/>
            </mat-form-field>   
       </div>

       <!-- fxFlex="20%" -->
       <div [hidden]="! showTitleFilter" >
         <mat-form-field style="font-size: 0.75rem; margin-left: 0.3rem; margin-right: 0.4rem">
           <input matInput #titleFilter type="text"
		  style="width: 22%"
		  placeholder="Enter Search filter"/>
         </mat-form-field>   
       </div>
       
       <mat-icon (click)="openHelpDialog(helpContext);"
                 *ngIf="showSidenavHelp===true"
	         style="margin-right: 0.5rem; font-size: 22px"
                 >help_outline</mat-icon>
       </div>
      
      <mat-divider></mat-divider>
 </mat-toolbar>

    <div class="left-menu-content"
	 *ngIf="leftNavMode===NAV_MAIN_MENU">
      <mat-nav-list>
       <mat-divider></mat-divider>  	             	
       <mat-list-item (click)='sidenavRecents()'>
        <mat-icon class="material-icons-outlined">file_open</mat-icon>
        <span style="padding-left: 0.2em; font-size: 16px">Recent Files</span>
       </mat-list-item>
       <mat-divider></mat-divider>
       <mat-list-item (click)='sidenavUploadFiles()'>	 	 
        <mat-icon class="material-icons-outlined">note_add_outlined</mat-icon>
        <span style="padding-left: 0.2em; font-size: 16px;">Upload File(s)</span>
       </mat-list-item>
       <mat-divider></mat-divider>
       <mat-list-item (click)='sidenavFindFiles()'>
       <mat-icon class="material-icons-outlined">plagiarism</mat-icon>
        <span style="padding-left: 0.2em; font-size: 16px">Find File(s)</span>
       </mat-list-item>
       <mat-divider></mat-divider>
       <mat-list-item (click)='sidenavQuickStart1()'>	 
         <mat-icon class="material-icons-outlined">flash_on</mat-icon>
	 <span style="padding-left: 0.2em; font-size: 16px;">Quick Start</span>	 
       </mat-list-item>
       <mat-divider></mat-divider>  	             
       <mat-list-item (click)='sidenavGroup()'>
         <mat-icon class="material-icons-outlined">group</mat-icon>
	 <span style="padding-left: 0.2em; font-size: 16px;">Groups</span>	 	 
       </mat-list-item>
        <mat-divider></mat-divider>  	                    
       <mat-list-item (click)='sidenavSetup()'>
         <mat-icon class="material-icons-outlined">settings</mat-icon>
	 <span style="padding-left: 0.2em; font-size: 16px;">{{this.NAV_SETUP}}</span>	 
       </mat-list-item>
       <mat-divider></mat-divider>
       <mat-list-item (click)="logout()">
         <mat-icon class="material-icons-outlined">logout</mat-icon>
	 <span style="padding-left: 0.2em; font-size: 16px;">Logout</span>
       </mat-list-item>
       <mat-divider></mat-divider>       
      </mat-nav-list>
    </div>    

    
    <div class="left-content" *ngIf="leftNavMode===NAV_RECENTS">
    <mat-nav-list style="margin-bottom: 50px;">
      <mat-divider></mat-divider>
      <div id="document-data"
		     style="margin: 3px; padding 0px;"
		     class="recent-file">
	{{getNumberOfDocuments()}} documents, {{getTotalFileSizeStr()}}
      </div>
      <mat-divider></mat-divider>      
       <mat-list-item *ngFor="let d of get_recent_docs(9)"
		      [ngClass]="{'active': d.uid===documentsvc.activeDocument}"
                      class="recent-file">
         <mat-divider></mat-divider>
         <button *ngIf="userInfo && (d.owner === userInfo.uid || d.users_uid === userInfo.uid)" mat-raised-button  (click)="openDeleteFileDialog(d)" id="delete-button"><mat-icon id="mbl-delete-icon">delete</mat-icon></button>
         <span (click)="openDocument(d);leftNavMode=NAV_MAIN_MENU"
               style="width=90%; font-size: 16px;">
               {{d.name}}</span>
      </mat-list-item>
    </mat-nav-list>       
    <div class="left-sidenav-toolbar"
      style="display: flex; flex-flow: row; justify-content: flex-start">
      <span style="font-size: 0.75rem;margin-left: 1rem;">
      GROUP FILES ({{countDocs(selectedGroup)}})</span>
      <ng-container #recentGroupSelect *ngTemplateOutlet="groupSelector">
      </ng-container>
      <mat-icon (click)="sidenavGroup()"
		style="margin-right: 0.7rem">group</mat-icon>
    </div>
    <!--- LEGACY GROUP FILES LIST --->
    <mat-nav-list>
      <ng-container *ngFor="let d of documents" >
      <mat-list-item *ngIf="(selectedGroupObj && d.groups_uid===selectedGroupObj.uid)"
                     [ngClass]="{'active': d.uid===documentsvc.activeDocument}"	
                     class="file-name">
      <mat-divider></mat-divider>
      <button *ngIf="userInfo && (selectedGroupObj.owner === userInfo.uid || d.users_uid === userInfo.uid)" mat-raised-button  color="warn" (click)="openDeleteFileDialog(d)" id="delete-button"><mat-icon id="mbl-delete-icon">delete</mat-icon>
      </button>
      <span style="width: 100%; margin-top: 0.3em; font-size: 16px;"
           (click)="openDocument(d)">{{d.name}}</span>
      </mat-list-item>
       </ng-container>
     </mat-nav-list>	  
    </div>
 
    <div class="left-content" style="margin-left: 1rem; margin-right: 1rem; width: 50vw;"
	 *ngIf="leftNavMode===NAV_SUBACCOUNTS">
            <app-subaccount-edit
               #subEdit
	       (displayUserInfoEvent)="this.openUserInfoDialog($event)"
               style="margin-left: 1em; margin-right: 1rem;overflow-y: none;">
            </app-subaccount-edit>
    </div>
 
    <div class="left-content" *ngIf="leftNavMode===NAV_GROUP_LIST"
	 style="margin-top: 0.5rem">
      <mat-nav-list>
	<mat-list-item>
	 Select a group below or enter a search filter above to only display matchng entries.
        </mat-list-item>
      </mat-nav-list>
      <mat-divider></mat-divider>
      <!---
      <mat-list>
	<mat-list-item *ngFor="let g of groups | NameFilter:titleFilter.value"
          (click)="selectedGroup=g.uid;selectedGroupObj=g;leftNavText=g.name;sidenavGroup()">
         <mat-divider></mat-divider>	  
         <mat-icon *ngIf="userInfo && (g.owner === userInfo.uid)" class="mbl-admin-star">star
         </mat-icon>	
         <span >{{g.name}}</span>
         <mat-icon>chevron_right</mat-icon>
        </mat-list-item>
      </mat-list>
      --->
       <app-group-list #grpList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 80vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"
         [mode]="'SL'"
         [showSearchButton]="false"
         [showSelectButton]="false"		     
         [userInfo]="userInfo"
         [userData]="userData"
         [selectedObj]="selectedGroupObj"
         [titleFilter]="titleFilter.value"
         (selectObjEvent)="setSelectedGroupObj($event)"
         (doneEvent)="sidenavBack()"
		       >
       </app-group-list>
    </div>

    <div class="left-content" *ngIf="leftNavMode===NAV_ORG_TAB"
	 style="margin-top: 0.5rem">
         <app-org-tab #orgTab class="left-content-scroll"
	     style="margin: 0; margin-botton: 75px; padding: 0; width: 80vw; width: 99vw;max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"		     
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showActions]="true"
         [showSearchButton]="false"
	 [showSelectButton]="false"
         [useMenuActionDialog]="false"
         [selectedObj]="selectedOrgUser"		      		      
         [selectedOrg]="selectedOrg"
         [selectedOrgUser]="selectedOrgUser"
         [titleFilter]="titleFilter.value"
         (refreshEvent)="refreshData()"
         (selectObjEvent)="setSelectedOrgUser($event)"		    
         (selectOrgEvent)="setSelectedOrg($event)"
         (selectOrgUserEvent)="setSelectedOrgUser($event)"
	 (doneEvent)="sidenavBack()"
	 >
       </app-org-tab>
      <mat-divider></mat-divider>       
    </div>

    <div class="left-content" *ngIf="leftNavMode===NAV_FILE_LIST"
	 style="margin-top: 0.5rem">
      <mat-nav-list>
	<mat-list-item>
	 Select a file name below or enter a search filter above to only display matchng entries.
        </mat-list-item>
      </mat-nav-list>
      <mat-divider></mat-divider>
       <app-document-list #grpList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 80vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"
         [mode]="'SL'"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showAddButton]="true"
         [showComboItem]="false"
         [showSelectItem]="false"			  
         [userInfo]="userInfo"
         [userData]="userData"
         [selectedObj]="selectedDocObj"
         [titleFilter]="titleFilter.value"
         (selectObjEvent)="selectDocumentObj($event)"
         (openObjEvent)="loadDocumentObj($event)"
         (createObjEvent)="sidenavUploadFiles()"			  
         (doneEvent)="sidenavBack()"
		       >
       </app-document-list>
    </div>

    <div class="left-content" *ngIf="leftNavMode===NAV_ORG_LIST"
	 style="margin-top: 0.5rem">
       <app-org-list #orgList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 70vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"
         [mode]="'L'"
         [userInfo]="userInfo"
         [userData]="userData"
         [showActions]="true"
         [titleFilter]="titleFilter.value"
         (refreshListEvent)="refreshData()"		     		     
	 >
       </app-org-list>
        <!--
         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)"  
       <app-org-tab #orgTab class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 80vw; width: 99vw;max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"		     
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showSearchButton]="false"
         [showSelectButton]="false"		     
         [selectedOrg]="selectedOrg"
         [selectedOrgUser]="selectedOrgUser"
         [titleFilter]="titleFilter.value"
         (selectOrgEvent)="setSelectedOrg($event)"
         (selectOrgUserEvent)="setSelectedOrgUser($event)"
	 (doneEvent)="sidenavBack()"
	 >
       </app-org-tab>
       -->
      <mat-divider></mat-divider>       
    </div>

    <div class="left-content" *ngIf="leftNavMode===NAV_ORG_TABLE"
	 style="margin-top: 0.5rem">
       <app-org-list #orgList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 70vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"		     
         [mode]="'T'"
         [userInfo]="userInfo"
         [userData]="userData"
         [titleFilter]="titleFilter.value"
	 >
<!--         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)" -->		     
       </app-org-list>
      <mat-divider></mat-divider>       
    </div>

    <div class="left-content" *ngIf="leftNavMode===NAV_ORG_SELECT"
	 style="margin-top: 0.5rem">
       <app-org-list #orgList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 70vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"		     
         [mode]="'S'"
         [userInfo]="userInfo"
         [userData]="userData"
         [titleFilter]="titleFilter.value"
         (refreshListEvent)="refreshData()"		     		     		     
	 >
<!--         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)" -->		     
       </app-org-list>
      <mat-divider></mat-divider>       
    </div>

    <div class="left-content" *ngIf="leftNavMode===NAV_ORG_COMBO"
	 style="margin-top: 0.5rem">
       <app-org-list #orgList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 70vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"		     
         [mode]="'C'"
         [userInfo]="userInfo"
         [userData]="userData"
         [titleFilter]="titleFilter.value"
	 >
<!--         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)" -->		     
       </app-org-list>
      <mat-divider></mat-divider>       
    </div>
    
    <div class="left-content" *ngIf="leftNavMode===NAV_USR_ORG_SELECT"
	 style="margin-top: 0.5rem">
       <app-org-list #usrOrgList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 70vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"
         [mode]="'SL'"
         [userInfo]="userInfo"
         [userData]="userData"
         [selectedObj]="selectedOrg"		     
         [titleFilter]="titleFilter.value"
         (selectObjEvent)="setSelectedOrg($event)"
         (doneEvent)="sidenavBack()"
         (refreshListEvent)="refreshData()"		     
	 >
<!--         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)" -->		     
       </app-org-list>
      <mat-divider></mat-divider>       
    </div>
    
    <div class="left-content" *ngIf="leftNavMode===NAV_ORG_EXPAND_LIST"
	 style="margin-top: 0.5rem">
       <app-org-list #orgList class="left-content-scroll"
		     style="margin: 0; padding: 0; width: 70vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="false"
         [mode]="'EL'"
         [userInfo]="userInfo"
         [userData]="userData"
         [titleFilter]="titleFilter.value"
         (refreshListEvent)="refreshData()"
	 >
<!--         (displayOrgEvent)="displayOrganization($event)"
         (editOrgEvent)="editOrganization($event)"
         (addOrgEvent)="addOrganization($event)" -->		     
       </app-org-list>
      <mat-divider></mat-divider>       
    </div>
    
    <div class="left-content"
	 *ngIf="leftNavMode===NAV_QUICK_START_1">
     <mat-nav-list>
       <mat-list-item>
	 Select or Create a Group
       </mat-list-item>
       <div style="display: flex; flex-flow: row; justify-content: center; align-content: center">
           <mat-list-item>
	     <ng-container *ngTemplateOutlet="groupSelector">
	     </ng-container>
           </mat-list-item>		      
       <button mat-icon-button matRipple
               (click)="openCreateGroupDialog()"
	       style="margin-left: 0.8rem;"
               matTooltip="Create Group">		  
         <mat-icon class='mat-icons-outlined'
		   style="margin-right: 4em"
		   >add</mat-icon>
       </button>
       </div>
       <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1.5rem; margin-left: 1rem"
	       (click)="sidenavQuickStart2()">Next</button>
     </mat-nav-list>       
   </div>

    <div class="left-content"
	 *ngIf="leftNavMode===NAV_QUICK_START_2">
     <app-add-group-member
         style="margin-top: 0; margin-left: 0.5rem;"
	 [template]=2
	 [userInfo]="userInfo"
	 [group]="selectedGroupObj">
     </app-add-group-member>
     <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1rem; margin-left: 1rem"
	       (click)="sidenavQuickStart1()">Back</button>
       <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1rem; margin-left: 1rem"
	       (click)="sidenavQuickStart3()">Next</button>
   </div>

    <div class="left-content"
	 *ngIf="leftNavMode===NAV_QUICK_START_3"
	 style="padding-left: 1rem; padding-right: 1rem">
      <div style="padding-left: 1rem; padding-right: 1rem">
	<app-upload-files #uploadFiles
           [groups]="this.groups"
           [userData]="this.userData"
           [userInfo]="this.userInfo"
           [selectedGroup]="this.selectedGroupObj"
           [selectedOrganization]="this.selectedOrg"
           [selectedOrgUnit]="this.selectedOrgUnit"
           [selectedImageSize]="this.selectedImageSize"
           [selectedImageRotation]="this.selectedImageRotation"
           [selectedAddMargins]="this.selectedAddMargins"
           (updateDocListEvent)="updateDocumentList();"
           (saveEvent)="sidenavMain();handleFileUploaded($event);"
           (closeEvent)="sidenavMain();"			  
           (backEvent)="sidenavBack();"
           (Creategroupevent)="openCreateGroupDialog();"
           (selectGroupEvent)="setSelectedGroupObj($event);"
           >
      </app-upload-files>
      </div> 
       <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1rem; margin-left: 1rem"
	       (click)="sidenavQuickStart2()">Back</button>
       <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1rem; margin-left: 1rem"
	       (click)="sidenavQuickStart4()">Next</button>
   </div>

    <div class="left-content"
	 *ngIf="leftNavMode===NAV_QUICK_START_4"
	 style="max-width: 50vw">
     <mat-nav-list>
       <div class="steps">
	 1. Your file should open automatically after uploading it. Afterward you can use the left navigation menu to find and open the document again under Recent Files or under a group folder.
       </div>
    <mat-divider></mat-divider>
       <div class="steps">      
	 2. To close the left menu click on the menu title with the back < arrow or use the menu button on the top toolbar left of the logo to hide or re-openthe left navigation menu.
       </div>
       <mat-divider></mat-divider>       
       <div class="steps">      	 
	 3. The right file/tools menu will open when a document has been opened (or use the button on the far right of the top toolbar).
       </div>
       <mat-divider></mat-divider>       
       <div class="steps">      	 	 
	 4. Use this menu to drag and drop markers from the TOOLS section or scroll to view existing markup in the MARKERS section, print or share the document in the FILE section or change the zoom or rotation in the VIEW section of the right side menu.
       </div>	 
       <mat-divider></mat-divider>
       <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1rem; margin-left: 1rem"
	       (click)="sidenavQuickStart3()">Back</button>
       <button mat-raised-button
	       style="background: #2274A5; color: white; margin-top: 1.5rem; margin-left: 1rem"
	       (click)="sidenavMain()">Done</button>
     </mat-nav-list>       
   </div>
    
    <div class="left-content"
	 *ngIf="leftNavMode===NAV_GROUP_MANAGER || leftNavMode===NAV_SETUP_GROUP_MGR"
	 style="padding-left: 0rem; padding-right: 0rem">
      <div style="padding-left: 1.5rem; padding-right: 1.5rem; margin-bottom: 50px;">
	<app-group-manager #groupMgr style="margin-bottom: 50px;"
           [groupList]=groups
	   [userInfo]=userInfo
	   [selectedGroup]=selectedGroup
           [selectedOrganization]=selectedOrg
	   [selectedOrgUnit]=selectedOrgUnit
	   [groupTabIndex]=0
	   [groupMembers]=groupMembers[selectedGroup]
	   (helpEvent)=openHelpDialog($event)
	   (groupTabEvent)=handleGroupTabEvent($event)
	   (getGroupsEvent)=updateDocumentList()
           (displayUserInfoEvent)=openUserInfoDialog($event)
           (createGroupEvent)=openCreateGroupDialog()
	   (renameGroupEvent)=openRenameGroupDialog($event)
           (deleteGroupEvent)=handleDeleteGroupEvent($event)
	   (removeMemberEvent)=handleRemoveMemberEvent($event)>
	</app-group-manager>	   
      </div> 
    </div>

    <div *ngIf="leftNavMode===NAV_ADD_GROUP_MEMBER"
	 class="left-content" 
	 style="min-width: 80vw; max-width: 100vw;">
      <app-add-group-member #addMemberView
       style="width: 100%; height: 100%; margin-top: 1rem"
       [template]=1
       [userInfo]="userInfo"
       [group]="selectedGroupObj">
      </app-add-group-member>
    </div>    

    <div *ngIf="leftNavMode===NAV_ADD_GROUP_MEMBERS"
	 class="left-content" 
	 style="min-width: 80vw; max-width: 100vw;">
      <app-add-group-member #addMemberListView
       style="width: 100%; height: 100%; margin-top: 1rem"
       [template]=2
       [userInfo]="userInfo"
       [group]="selectedGroupObj">
    </app-add-group-member>
    </div>    


    <div class="left-content" *ngIf="leftNavMode===NAV_GROUP"
	 style="min-width: 85vw; max-width: 100vw;">
      <mat-nav-list>
        <mat-divider></mat-divider>
           <ng-container *ngTemplateOutlet="groupBtns">
           </ng-container>
	</mat-nav-list>
        <mat-divider></mat-divider>
        <div>
	  <app-group
            [mode]="'DP'"	    
	    [userInfo]="userInfo"
	    [userData]="userData"
            [objData]="selectedGroupObj"
	    [selectedObj]="selectedGroupObj"
	    (addFileEvent)="setSelectedGroupObj($event);sidenavGroupUpload()"
	    (addMemberEvent)="setSelectedGroupObj($event);sidenavAddGroupMember()"
	    (openObjEvent)="selectDocumentObj($event);openDocument($event)"
	    ></app-group>
	  <!--             
            (selectObjEvent)="setSelectedGroupObj($event)"
            (selectGroupEvent)="setSelectedGroupObj($event)"		  
          -->
	</div>
   </div>

   <div class="left-content" *ngIf="leftNavMode===NAV_GROUP2"
	 style="min-width: 85vw; max-width: 100vw;">
      <mat-nav-list>
        <mat-divider></mat-divider>
           <ng-container *ngTemplateOutlet="groupBtns">
           </ng-container>
	</mat-nav-list>
        <mat-divider></mat-divider>
        <div>
          <mat-accordion style="margin: 0; padding: 0; width: 100%">
	<mat-expansion-panel #grpFilePanelMobile style="margin-left: 0; margin-right: 1em; padding: 0.5em; width: 100%">	  
	  <mat-expansion-panel-header style="margin: 0; padding: 0; width: 90%">
            <mat-panel-title>
	      GROUP FILES
            </mat-panel-title>
             <mat-panel-description>
	       <div style="display: flex; flex-flow: row;">
		<div *ngIf="groupFileCnt$ | async as dcnt">
		  ({{dcnt.count}})
		</div>
		<ng-template #gdczero>
		  (0)
		</ng-template>
	      <mat-icon *ngIf="grpFilePanelMobile.expanded; else grpFilePanelMobileMore">expand_less</mat-icon>
	      <ng-template #grpFilePanelMobileMore>
                 <mat-icon>expand_more</mat-icon>		
	      </ng-template>
	      </div>
             </mat-panel-description>
              <button mat-button style="padding: 0; margin: 0; margin-right: 2rem;"
   		  class="group-btn"
              (click)="refreshGroupFilesEvent($event); grpFilePanelMobile.open();"
                  matTooltip="Refresh File List">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">refresh</mat-icon>
              </button>
              <button mat-button style="padding: 0; margin: 0; margin-right: 2.5em;"
   		  class="group-btn"
                  (click)="sidenavGroupUpload()"
                  matTooltip="Add Files/Content">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">note_add_outlined</mat-icon>
                  <!-- <div class="label-text">Add Files</div> -->
              </button>
	  </mat-expansion-panel-header>
	  <!-- NEW GROUP FILE LIST -->
         <mat-form-field class="mat-header-row"
			    style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
	  <!-- [(ngModel)]="titleFilter" -->
          <input matInput #titleFilterInput
		 type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
         </mat-form-field>
         <app-group-file-list #grpFileList 
           class="left-content-scroll"
	   style="margin: 0; padding: 0; width: 75vw; max-width: 99vw; overflow-y: auto"
           [iam]="false"
           [mode]="'SL'"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showMenuButton]="false"
           [showAddButton]="false"			      
           [showListViewButton]="false"
           [showTitleFilter]="false"			      
           [userInfo]="userInfo"
           [userData]="userData"
           [selectedGroup]="selectedGroupObj"
           [titleFilter]="titleFilterInput.value"
           (selectObjEvent)="setSelectedDocObj($event)" 
           (doneEvent)="sidenavBack()"
           (refreshListEvent)="refreshData()"				 >
         </app-group-file-list>
        <!--(selectObjEvent)="openDocument($event)"    (selectObjEvent)="setSelectedDocObj($event)" -->
	 <!-- END NEW GROUP FILE LIST -->	  
	</mat-expansion-panel>
        <mat-divider></mat-divider>

	<mat-expansion-panel #grpMemberPanelMobile style="margin-left: 0; margin-right: 1em; padding: 0.5em; width: 100%">
	  <mat-expansion-panel-header style="margin: 0; padding: 0; width: 90%">
            <mat-panel-title>
	      GROUP MEMBERS
            </mat-panel-title>
            <mat-panel-description>
		<div *ngIf="groupMemberCnt$ | async as mcnt">
		  ({{mcnt.count}})
		</div>
		<ng-template #gmczero>
		  (0)
		</ng-template>
	      <mat-icon *ngIf="grpMemberPanelMobile.expanded; else grpMemberPanelMobileMore">expand_less</mat-icon>
	      <ng-template #grpMemberPanelMobileMore>
                 <mat-icon>expand_more</mat-icon>		
	      </ng-template>
            </mat-panel-description>
              <button mat-button style="padding: 0; margin: 0; margin-right: 3em"
   		  class="group-btn"
          (click)="refreshGroupMembersEvent($event); grpMemberPanelMobile.open();"
                  matTooltip="Refresh Member List">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">refresh</mat-icon>
              </button>
              <button *ngIf="selectedGroupObj && selectedGroupObj.owner === userData.uid"
	          mat-button style="padding: 0; margin: 0; margin-right: 4.5em;"
   		  class="group-btn"
                  (click)="sidenavAddGroupMember()"
                  matTooltip="Add Group Member">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">person_add</mat-icon>
                   <!-- <div class="label-text">Add Member</div> -->
                </button>
	  </mat-expansion-panel-header>
	  <mat-nav-list>
          <app-group-member-list #grpMemberList
           style="text-align: center; align-items: center; margin-bottom: 50px;"
             id="mbl-member-div2"
             (displayUserInfoEvent)="this.openUserInfoDialog($event)"
             (removeMemberEvent)="this.handleRemoveMember($event)"
             (countMembersEvent)="this.handleGroupMemberCount($event)"
             [template]=5
             [userInfo]="this.userInfo"
             [group]="this.selectedGroupObj"
             [groupUID]="this.selectedGroup"
             [(groupList)]="this.groups"
          ></app-group-member-list>
          </mat-nav-list>	  
	</mat-expansion-panel>
        </mat-accordion>
     </div>
    </div>

   
    <div class="left-content-scroll" *ngIf="leftNavMode===NAV_SETUP">
      <mat-nav-list>
        <mat-divider></mat-divider>
        <mat-list-item (click)="sidenavOrgTab()">
          <mat-icon style="margin-right: .9rem">domain</mat-icon>
	  <ng-template #noSelectedOrg>
            <span style="font-size: 0.9rem">Company/Org.</span>
	  </ng-template>	    
          <div *ngIf="selectedOrg; else noSelectedOrg"
	       style="display: flex; flex-flow: column">
            <span style="font-size: 0.9rem">Company/Org.</span>
            <span style="font-size: 0.9rem">({{selectedOrg.org_name}})</span>
	    <div *ngIf="selectedOrgUser">
              <span style="font-size: 0.9rem">({{selectedOrgUser.org_user_email}})</span>
	    </div>
	  </div>
        </mat-list-item>
        <mat-list-item (click)="sidenavSetupGroupMgr()">
          <mat-icon style="margin-right: .9rem">groups</mat-icon>
	  <ng-template #noSelectedGroupObj>
            <span style="font-size: 0.9rem">Company/Org.</span>
	  </ng-template>
          <div *ngIf="selectedGroupObj; else noSelectedGroupObj"
	       style="display: flex; flex-flow: column">
            <span style="font-size: 0.9rem">Groups & Members</span>
            <span style="font-size: 0.9rem">({{selectedGroupObj.name}})</span>	    	    
	  </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item (click)="sidenavUserSettings()"> 	  
             <mat-icon style="margin-right: .9rem">manage_accounts</mat-icon>
             <span style="font-size: .9rem">My User Settings</span>
             <span *ngIf="userInfo && userInfo.email"
	      style="font-size: 0.7rem; margin-left: 1rem">({{userInfo.email}})</span>	
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item 
               (click)="goToLink(paymentURL)">
               <mat-icon style="margin-right: .9rem">payments
               </mat-icon>
               <span style="font-size: .9rem">My Subscription</span>
        </mat-list-item>	       
	
        <mat-divider></mat-divider>
	<ng-container *ngIf="userInfo && userInfo.accounts > 0">
          <mat-list-item 
               (click)="sidenavSubaccounts()"> 		       		       
               <mat-icon style="margin-right: .9rem">supervised_user_circle
               </mat-icon>
               <span style="font-size: .9rem">Guest Sub-Accounts</span>
          </mat-list-item>
	</ng-container>
        <mat-divider></mat-divider>		
        <mat-list-item
	       *ngIf="userData && userData.iam"
               (click)="sidenavUserList()"> 		       		       
               <mat-icon style="margin-right: .9rem">view_list
               </mat-icon>
               <span style="font-size: .9rem">User Directory</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item (click)="sidenavFeedback()">
             <mat-icon style="margin-right: .9rem">feedback</mat-icon>	     
             <span style="font-size: .9rem">Send Feedback</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item *ngIf="userData && userData.iam" (click)="sidenavInviteFree()">
             <mat-icon style="margin-right: .9rem">send</mat-icon>
             <span style="font-size: .9rem">{{NAV_INVITE_FREE}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>	

        <mat-list-item (click)="sidenavInviteToken()">
             <mat-icon style="margin-right: .9rem">schedule_send</mat-icon>
             <span style="font-size: .9rem">{{NAV_INVITE_TOKEN}}</span>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item *ngIf="userData && userData.uid==='1234'"
		       [matMenuTriggerFor]="appMgrMenu">
             <mat-icon style="color: red">admin_panel_settings</mat-icon>
             <span style="font-size: 0.9rem; color: red">App. Management</span>
        </mat-list-item>      
        <mat-divider></mat-divider>	
        <mat-list-item *ngIf="userData && userData.iam" (click)="navAppMgr()">
          <mat-icon style="color: red">admin_panel_settings</mat-icon>
             <span style="font-size: 0.9rem; color: red">App. Manager</span>
        </mat-list-item>      
        <mat-divider></mat-divider>	
     </mat-nav-list>
    </div>

    
    <div *ngIf="leftNavMode===NAV_FEEDBACK"
         class="left-content-scroll"
	 style="margin-left: 0.6rem; margin-right: 0.5rem; width: 90%; overflow: auto; overscroll-behavior-x: contain; overscroll-behavior-y: contain;">
      <app-feedback-data #feedback
           style="margin-left: 1rem; width: 80vw; max-width: 100vw; height: 100%: overflow-y: auto; overscroll-behavior-x: contain; overscroll-behavior-y: contain;">
      </app-feedback-data>
    </div> 

    <div *ngIf="leftNavMode===NAV_UPLOAD_FILES || leftNavMode===NAV_GROUP_UPLOAD"
         class="left-content-scroll"
	 style="margin-left: 1rem; margin-right: 1rem; margin-top: 0.5rem">
      Select a Group, then Select Files to Upload
      <app-upload-files #uploadFiles
           style="margin-left: 1rem; min-width: 50vw; max-width: 100vw; height: 100%:"			 			
           [groups]="this.groups"
           [userData]="this.userData"			
           [userInfo]="this.userInfo"
           [selectedGroup]="this.selectedGroupObj"
           [selectedOrganization]="this.selectedOrg"
           [selectedOrgUnit]="this.selectedOrgUnit"
           [selectedImageSize]="this.selectedImageSize"
           [selectedImageRotation]="this.selectedImageRotation"
           [selectedAddMargins]="this.selectedAddMargins"
           (saveEvent)="sidenavMain();handleFileUploaded($event);"
           (closeEvent)="sidenavMain();"			  
           (backEvent)="sidenavBack();"
           (createGroupEvent)="openCreateGroupDialog();"
           (selectGroupEvent)="setSelectedGroupObj($event);"
           >
      </app-upload-files>
    </div> 
    
    <div *ngIf="leftNavMode===NAV_INVITE_FREE"
         class="left-content-scroll"
	 style="width: 100vw; overflow: auto">
      <app-new-user-email class="left-content-scroll"
			   #userSettings>
      </app-new-user-email>
    </div> 

    <div *ngIf="leftNavMode===NAV_INVITE_TOKEN"
         class="left-content-scroll"
	 style="min-width: 35vw; max-width: 100vw; overflow: auto">
       <app-send-data
        *ngIf="documentsvc.activeDocument && documentsvc.activeDocument !== null; else noDocError"
              class="left-content-scroll"
              style="margin-top: 0;"
              #sendToken
              [overlayData]="noteData"
              [activeDocument]="documentsvc.activeDocument"
              [activeUser]="userInfo.uid">
       </app-send-data>
       <ng-template #noDocError style="margin-top: 2rem">
	<div style="margin: 1rem; margin-top: 2rem; color: red;">
	  Error: please select a document first. You can then use this option to share the document and group it is contained in with the users you send the invitation to.
	</div>
       </ng-template>
    </div> 

   <div class="left-content" *ngIf="leftNavMode===NAV_USER_LIST">
     <app-user-list #userList class="left-content-scroll"
         style="margin: 0; padding: 0; width: 50vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [template]=1
         [userInfo]="userInfo"
         (displayUserInfoEvent)="openUserInfoDialog($event)">
     </app-user-list>
   </div>

   <div class="left-content"
	*ngIf="leftNavMode===NAV_USER_SETTINGS"
	style="padding-top: 0; margin-top: 0; min-width: 60vw;">
     <div>
      <app-settings-dialog class="left-content-scroll"
         style="margin: 0; min-width: 55vw; max-width: 99vw; height: 100%: overflow-y: auto">
      </app-settings-dialog>
     </div>
   </div>

   <div class="left-content" *ngIf="leftNavMode===NAV_CREATE_LINK">
     <app-create-link
       [selectedGroupObj]="selectedGroupObj"
       [origin]="linkOrigin"
       [userInfo]="userInfo"
       [linkSrcData]="linkSrcData"
       [documentUID]="documentsvc.activeDocument"
       (closeEvent)="sidenavBack();sidenavClose()"
       >
     </app-create-link>
   </div>

   <!--
   <div class="left-content" *ngIf="leftNavMode===NAV_CREATE_LINK">
     <mat-nav-list>
       <mat-list-item (click)="sidenavLinkExternal()">
	 External Web Link
       </mat-list-item>
       <mat-divider></mat-divider>
       <mat-list-item (click)="sidenavLinkFile()">	 
         Link to a Document (page or marker)
       </mat-list-item>
     </mat-nav-list>	  
   </div>
   -->
      
   <div class="left-content" *ngIf="leftNavMode===NAV_LINK_EXTERNAL">
     <mat-nav-list>
       <span class="input-link-title">Link URL</span><br>
       <mat-list-item>
	 <input id="link-url" class="input-link" type="textbox" [(ngModel)]="linkURL" required (paste)="pasteMarkerUrl($event)">
       </mat-list-item>
       <span class="input-link-title">Link Name</span><br>       
       <mat-list-item>       
	 <input id="link-name" class="input-link" type="textbox" [(ngModel)]="linkTitle" required (paste)="pasteMarkerTitle($event)">
       </mat-list-item>	 
     </mat-nav-list>
        <div style="display: flex; flex-flow: row; justify-content: center;">
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px; border: 1px solid black;"
		  class="group-btn"
                  (click)="sidenavCreateLink()"
                  matTooltip="Done">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button 
		    mat-button style="padding: 0; margin-left: 4px; margin-right: 4px; border: 1px solid black;"
		    class="group-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="linksvc.createExternalLink(linkTitle, linkURL); sidenavClose();"
                    matTooltip="Done">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
       </div>
   </div>

   
   <div class="left-content" *ngIf="leftNavMode===NAV_LINK_FILE">
     <mat-nav-list>
        <div style="display: flex; flex-flow: row; justify-content: center;">       
            <button mat-button 
		  class="link-btn"
                  (click)="sidenavCreateLink()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"		    
                    (click)="sidenavLinkPage()">
                    <mat-icon class="label-icon"
                       style="margin: 0; padding: 0;">file_open</mat-icon>
                    <div class="label-text">Add Page#</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"
                    (click)="sidenavLinkMarker()">
                    <mat-icon class="label-icon"
                       style="margin: 0; padding: 0;">location_on</mat-icon>
                    <div class="label-text">Add Marker</div>
            </button>
            <button mat-button 
		    class="link-btn"
 		    [disabled]="linkDocumentData===null"
                    (click)="sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
	</div>
	<mat-divider></mat-divider>
          <mat-list-item>
          <span style="text-color: #1A567A;">{{selectedGroupObj.name}}</span>
          <span *ngIf="linkDocumentData" style="text-color: #1A567A;">
	    /{{linkDocumentData.name}}</span>
	  </mat-list-item>
        <mat-divider></mat-divider>
	<ng-container *ngFor="let d of documents">
          <mat-list-item *ngIf="d.groups_uid===selectedGroupObj.uid"
			 [ngClass]="{'active': d.uid===documentsvc.activeDocument}"	
                         class="file-name">
            <mat-divider></mat-divider>
            <span style="width: 100%; margin-top: 0.3em"
		  (click)="saveLinkDocument(d);">{{d.name}}
            </span>
          </mat-list-item>
      </ng-container>
	  </mat-nav-list>	  
   </div>

   <div class="left-content" *ngIf="leftNavMode===NAV_LINK_MARKER">
            <button mat-button 
		  class="icon-btn"
                  (click)="sidenavLinkFile()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="group-btn"
 		    [disabled]="linkDocumentData === null || linkMarkerData === null"
                    (click)="sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
          <mat-divider></mat-divider>
          <mat-list-item>
          <span style="text-color: #1A567A;">{{selectedGroupObj.name}}</span>
          <span *ngIf="linkDocumentData" style="text-color: #1A567A;">
	    /{{linkDocumentData.name}}</span>
	  </mat-list-item>
          <mat-divider></mat-divider>	  
	    <app-marker-list #markerList 
	      [template]=4
	      [userInfo]=userInfo
	      [documentUID]=linkDocumentData.uid
	      (selectMarkerEvent)=saveLinkMarker($event)
              (displayUserInfoEvent)=openUserInfoDialog($event)
	      > 
	    </app-marker-list>
   </div> <!-- NAV_LINK_MARKER -->
   
   <div class="left-content" *ngIf="leftNavMode===NAV_LINK_MARKER">
            <button mat-button 
		  class="icon-btn"
                  (click)="sidenavLinkFile()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="group-btn"
 		    [disabled]="linkDocumentData === null || linkMarkerData === null"
                    (click)="sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
          <mat-divider></mat-divider>
          <mat-list-item>
          <span style="text-color: #1A567A;">{{selectedGroupObj.name}}</span>
          <span *ngIf="linkDocumentData" style="text-color: #1A567A;">
	    /{{linkDocumentData.name}}</span>
	  </mat-list-item>
          <mat-divider></mat-divider>	  
	    <app-marker-list #markerList 
	      [template]=4
	      [userInfo]=userInfo
	      [documentUID]=selectedDocUID
	      (selectMarkerEvent)=saveLinkMarker($event)
              (displayUserInfoEvent)=openUserInfoDialog($event)
	      > 
	    </app-marker-list>
   </div> 

   <div class="left-content" *ngIf="leftNavMode===NAV_LINK_PAGE">
            <button mat-button 
		  class="icon-btn"
                  (click)="sidenavLinkFile()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button mat-button 
		    class="group-btn"
 		    [disabled]="linkDocumentData === null"
                    (click)="sidenavLinkInternal();">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">arrow_forward</mat-icon>
                    <div class="label-text">NEXT</div>
            </button>
          <mat-divider></mat-divider>
          <mat-list-item>
          <span style="text-color: #1A567A;">{{selectedGroupObj.name}}</span>
          <span *ngIf="linkDocumentData" style="text-color: #1A567A;">
	    /{{linkDocumentData.name}}</span>
	  </mat-list-item>
          <div class="left-right-space pad8">
           <input id="link-page-nbr" type="number" maxlength="5" min="1" max="9999" [(ngModel)]="linkPageNbr" required>	    
	  </div>
          <form #f >
    Page: <input required min="1" max="9999" type="number" [(ngModel)]="linkPageNbr"  name="page"><br><br>
	  </form>
	  
   </div> 

   <div class="left-content" *ngIf="leftNavMode===NAV_LINK_INTERNAL">
     <mat-nav-list>
       <span class="input-link-title">Link URL</span><br>
       <mat-list-item>
	 <input id="link-url" class="input-link" type="textbox" [(ngModel)]="linkURL" required (paste)="pasteMarkerUrl($event)">
       </mat-list-item>
       <span class="input-link-title">Link Name</span><br>       
       <mat-list-item>       
	 <input id="link-name" class="input-link" type="textbox" [(ngModel)]="linkTitle" required (paste)="pasteMarkerTitle($event)">
       </mat-list-item>	 
     </mat-nav-list>
        <div style="display: flex; flex-flow: row; justify-content: center;">
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px; border: 1px solid black;"
		  class="link-btn"
                  (click)="sidenavCreateLink()">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">arrow_back</mat-icon>
                  <div class="label-text">BACK</div>
            </button>
            <button *ngIf="linkMarkerData!==null; else savePageLink"
	            mat-button style="padding: 0; margin-left: 4px; margin-right: 4px; border: 1px solid black;"
		    class="link-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="linksvc.createInternalLink(linkOrigin, linkTitle, linkDocumentData.uid, linkMarkerData.uuid, linkPageNbr); sidenavClose()">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
	    <ng-template #savePageLink>
            <button mat-button style="padding: 0; margin-left: 4px; margin-right: 4px; border: 1px solid black;"
		    class="link-btn"
 		    [disabled]="linkURL==='' || linkTitle === ''"
                    (click)="linksvc.createInternalLink(linkOrigin, linkTitle, linkDocumentData.uid, null, linkPageNbr); sidenavClose()">
                    <mat-icon class="label-icon"
                     style="margin: 0; padding: 0;">add_link</mat-icon>
                    <div class="label-text">SAVE</div>
            </button>
	    </ng-template>
       </div>
   </div>

   <!--           [orgUID]="this.orgUID" -->
   <div class="left-content" *ngIf="leftNavMode===NAV_APPROVAL_REQUEST">
	<app-group-member-list #selectMembers
          [template]=6
          [userInfo]="this.userInfo"
	  [itemData]="this.approvalMarkerData"
          [groupUID]="this.selectedGroup"
          [group]="this.selectedGroupObj"
          [groupList]="this.groups"
	  (selectMembersEvent)="sidenavBack()"
	  >
	</app-group-member-list>
   </div>
   
  </mat-sidenav>
    
  <mat-sidenav #sidenavright
	       position="end"
               mode="side"
	       class="sidenav sidenav-right"
               id="right-sidenav-id"
	       fixedInViewport="true"
	       [style.top.px]="platformsvc.sidenavMarginTop"	       
	       (opened)="sidenavRightOpen=true"
	       (closed)="sidenavRightOpen=false">
    
    <mat-divider></mat-divider>
    <div class="sidenav-section-header" id="tools-button"
	 (click)="collapseComments('tools');toggleTools=!toggleTools" matTooltip="Toggle File Tools" matTooltipPosition="right" aria-label="Toggle File Tools">
      TOOLS
      <mat-icon id="fiber_manual_record" style="padding-top: 0%; padding-bottom: 0%">{{ toggleTools ? 'fiber_manual_record' : 'remove' }}</mat-icon>
   </div> <!-- header -->
    <div cdkDropList [cdkDropListAutoScrollDisabled]="false" #tools="cdkDropList" [cdkDropListConnectedTo]="[overlaycontent]" id="tools" class="sidenav-section-content">
            <div id="tools-question" class="sidenav-section-content sidenav-icon" matTooltip="Question" matTooltipPosition="left" aria-label="Question" cdkDrag [cdkDragData]="noteData.question" [cdkDragDisabled]="!documentsvc.activeDocument" (cdkDragEnded)="dragEnd($event)" style="marginTop: 0;">
              <img class="right-side-icon" src="/assets/question_icon.png"/><br/>
            </div>
            <div id="tools-note" class="sidenav-section-content sidenav-icon" matTooltip="Comment" matTooltipPosition="left" aria-label="Comment" cdkDrag [cdkDragData]="noteData.note" [cdkDragDisabled]="!documentsvc.activeDocument" style="marginTop: 0;">
              <img class="right-side-icon" src="/assets/note_icon.png"/><br/>
            </div>
            <div id="tools-issue" class="sidenav-section-content sidenav-icon" matTooltip="Issue" matTooltipPosition="left" aria-label="Issue" cdkDrag [cdkDragData]="noteData.issue" [cdkDragDisabled]="!documentsvc.activeDocument" style="marginTop: 0;">
                <img class="right-side-icon" src="/assets/issue_icon.png"/><br/>
            </div>
            <div id="tools-approval" class="sidenav-section-content sidenav-icon" matTooltip="Approval" matTooltipPosition="left" aria-label="Approval" cdkDrag [cdkDragData]="noteData.approval" [cdkDragDisabled]="!documentsvc.activeDocument" style="marginTop: 0;">
              <img class="right-side-icon" src="/assets/approval_icon.png"/><br/>
            </div>
    </div> <!-- tools droplist -->
    <ng-content *ngTemplateOutlet="rightToolbarTemplate">
    </ng-content>
  </mat-sidenav>

    
 <mat-sidenav-content id="main-sidenav-content" class="main-sidenav-content" (scroll)="onScrollContent($event)">

   <div *ngIf="uiMode===UI_DESKTOP" [ngStyle]="{'top.px': headTop, 'width': headerWidth}" class="header-container">
      <button mat-icon-button class="nav-toggle-left" (click)="toggle_left_nav();">
            <mat-icon class="panel-toggle" mattooltip="toggle left panel" aria-label="toggle left panel">{{ sidenavleft.opened ? 'arrow_back_ios' : 'arrow_forward_ios' }}</mat-icon>	    
      </button>
        
      <div style="margin: auto;">&nbsp;</div>
      <button mat-icon-button class="nav-toggle-right" (click)="toggle_right_nav();" mattooltip="toggle right panel" style="margin-right: 0.5rem">
	  <!--        <mat-icon class="material-icons-rounded">{{ toggleRightNav ? 'arrow_back_ios' : 'arrow_forward_ios' }}</mat-icon>  -->
        <mat-icon class="material-icons-rounded">{{ sidenavright.opened ? 'arrow_forward_ios' : 'arrow_back_ios' }}</mat-icon>	  
      </button>
   </div>

   
    <section id="main-section">
        <div #maincontainer (resized)="adjust_header_width()"  class="main-container" [style.top.px]="platformsvc.sidenavMarginTop" style="padding-top: 0px">
            <div #maincontent id="main-content" (pinchin)="onPinchIn($event)" (pinchout)="onPinchOut($event)" [style.width.px]="overlayWidth">
              <div #contentlayer id="content-layer"
                 [style.width.px]="overlayWidth"		   
		 class="content-layer">
		
		<div #contentoverlay
                   id="content-overlay"		     
		   class="content-overlay"		     
		   [style.width.px]="overlayWidth"
                   cdkDropList
                   #overlaycontent="cdkDropList"
                   [cdkDropListData]="tools"
                   (cdkDropListDropped)="drop($event)">
                </div>
<!-- [cdkDropListEnterPredicate]="canDrop"
     [style.width]="getMaxPageWidth() + 'px'" 
	[textLayer]="true"-->
                <ngx-extended-pdf-viewer #pdf
                        [style.z-index]="pdfViewerZIndex"
 			[style.width.px]="maxPageWidth"
                        [src]="documentsvc.pdfURL"
                        [textLayer]="true"
                        [pageViewMode]="'infinite-scroll'"
                        [backgroundColor]="'#ababab'"
			[httpHeaders]="documentsvc.httpHeaders"
			[handTool]="showHandTool"
                        [showBorders]="false"
                        [showToolbar]="false"
                        [sidebarVisible]="false"
                        [showSidebarButton]="false"
                        [showFindButton]="false"
                        [showPagingButtons]="false"
                        [showZoomButtons]="false"
                        [showPresentationModeButton]="false"
                        [showOpenFileButton]="false"
                        [showPrintButton]="false"
                        [showDownloadButton]="true"
                        [showSecondaryToolbarButton]="false"
                        [showRotateButton]="false"
                        [showHandToolButton]="false"
                        [showScrollingButton]="false"
                        [showSpreadButton]="false"
                        [showPropertiesButton]="true"   
                        [(rotation)]="documentsvc.pdfRotation"
                        [(zoom)]="documentsvc.pdfZoomPct"
                        [(page)]="documentsvc.pdfPageNbr"
                        (pageChange)="documentsvc.pdfPageChange($event)"
                        (updateFindMatchesCount)="updateFindMatchesCount($event)"
                        (updateFindState)="updateFindState($event)"
                        (pageRendered)="PDFPageRendered($event)"
                        (pagesLoaded)="PDFPagesLoaded($event)"
                        (pdfLoadingStarts)="PDFLoadingStarts($event)"
                        (pdfLoadingFailed)="PDFLoadingFailed($event)"
                        (pdfLoaded)="PDFLoadComplete($event)"
                        (progress)="PDFLoadProgress($event)"
                        (handToolChange)="onHandToolChange($event)"
			useBrowserLocale="false">
		</ngx-extended-pdf-viewer>
<!--		
                <pdf-viewer #pdf [src]="pdfSrc"
                        [original-size]="true"
                        [show-borders]="false"
                        [zoom]="pdfZoom"
                        [rotation]="pdfRotation"
                        [autoresize]="false"
                        [stick-to-page]="true"
                        [fit-to-page]="false"
			[(page)]="pdfPageNbr"
			(on-progress)="PDFLoadProgress($event)"	    
                        (after-load-complete)="PDFLoadComplete($event)"
                        (page-rendered)="PDFPageRendered($event)"
                        style="display: inline-block;">
                    </pdf-viewer>
-->
                </div>
              <app-marker-list #markerRpt
              style="margin: 0; padding: 0; width: 100%;"
              [hidden]="true"
              [template]=1
              [userInfo]="userInfo"
              [noteData]="noteData"
              [documentUID]="documentsvc.activeDocument"
              [documentName]="activeDocumentName">
              </app-marker-list>
	      <div #printDiv id="print-div">
		<img [src]="printImgSrc" #printImg id="printImg" />
	      </div>
            </div>
        </div>
	
    </section>
   </mat-sidenav-content>
 </mat-sidenav-container>

      <!--            [ngModelOptions]="{standalone: true}" -->
   <!-- *ngIf="activeDocument && showPageTool"> -->
   <mat-toolbar class="pageTool" *ngIf="documentsvc.activeDocument && showPageTool">
     <button mat-icon-button (click)="pdfGoToPage(1)">
       <mat-icon>first_page</mat-icon>
     </button>
     <button mat-icon-button (click)="pdfPageUp()">
       <mat-icon>chevron_left</mat-icon>       
     </button>
        <mat-form-field style="width: 5.5rem; height=1.6rem">
	 <input matInput class="input-tool"
           style="width: 5.5rem; height: 1.4rem; margin-bottom: 0px;"
	   type="number"
           [(ngModel)]="documentsvc.pdfPageNbr"
	   (ngModelChange)="pdfSetPage($event)"/>
	</mat-form-field>
      <small>
        <span style="margin-left: 1rem; width: 10vw;"> of {{documentsvc.pdfPageCount}}</span>
      </small>
    <button mat-icon-button (click)="pdfPageDown()">    
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button mat-icon-button
	    (click)="pdfGoToLastPage()">
      <mat-icon>last_page</mat-icon>
    </button>
    <button mat-icon-button
	    (click)="showPageTool = !showPageTool">
      <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>

   <mat-toolbar class="findTool" *ngIf="documentsvc.activeDocument && showFindTool">
     <!-- [class.narrow]="multipleSearchTerms" -->
    <small>Find:</small>
    <mat-form-field style="width: 12rem; height: 1.4rem; margin-bottom: 2.6rem; margin-left: 0.3rem;">     
      <input matInput
             class="input-tool"
            style="width 12rem; height: 1.4rem; margin-bottom: 0rem; padding: 0; margin-top: 0;"	     
	     type="text"
            [ngModelOptions]="{standalone: true}"
	    [(ngModel)]="searchText" />     
    </mat-form-field>
     <!--    <small *ngIf="findState !== undefined"> -->
    <small >
     <!-- <span style="margin-left: 1rem">{{ findStateText }}</span> -->
      <span style="margin-left: 0.5rem"
	    *ngIf="totalMatches" >
            {{ currentMatchNumber }} of {{ totalMatches }}
      </span>
    </small>
    <button mat-icon-button
	    (click)="findPrevious()">
      <mat-icon>chevron_left</mat-icon>
    </button>
    <button mat-icon-button (click)="findNext()">
      <mat-icon>chevron_right</mat-icon>
    </button>
    <button mat-icon-button
	    (click)="toggleFindTool()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>

   <mat-toolbar class="zoomTool" *ngIf="documentsvc.activeDocument && showZoomTool">
     <button mat-icon-button (click)="pdf_zoom_out(0.25)">
       <mat-icon>remove</mat-icon>
     </button>
        <mat-form-field style="width: 5.5rem; height=1.6rem;">
	 <input matInput class="input-tool"
           style="width: 5.5rem; height: 1.4rem; margin-bottom: 0px;"
	   type="number"
           [(ngModel)]="documentsvc.pdfZoomPct"
	   (ngModelChange)="documentsvc.pdfZoomPctChange($event)"/>%
	</mat-form-field>
    <button mat-icon-button (click)="pdf_zoom_in(0.25)">    
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button
	    (click)="showZoomTool = !showZoomTool">
      <mat-icon>close</mat-icon>
    </button>
   </mat-toolbar>
   
   <div> <!-- ui container div -->
     
<!-- END MAIN COMPONENT DESKTOP UI -->
