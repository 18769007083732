import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges,
         OnDestroy,  Input, Output, ViewChild, EventEmitter, ElementRef,
         ComponentRef } from '@angular/core';

import { ChangeDetectorRef } from '@angular/core';
import { NgFor, AsyncPipe} from '@angular/common';
// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith, tap } from "rxjs/operators";
import { SelectionModel } from '@angular/cdk/collections';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

//import { OrgModule } from '../../org/org.module';
//import { OrgComponent } from '../../org/org.module';

import { ToasterService } from '../../service/toaster.service';

import { AuthService } from '../../service/auth.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';
import { GroupService } from '../../service/group.service';
import { UserService } from '../../service/user.service';

import { FormDialogComponent } from '../../dialog/form/form.dialog';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../model/groupdata.model';
import { GroupComponent } from '../group/group.component';
import { OrgData } from '../../org/model/orgdata.model';
import { OrgUserData } from '../../org/model/orguserdata.model';
import { OrgUserInfo } from '../../org/model/orguserinfo.model';


import { SortKey } from '../../pipe/sortkey.pipe';
import { FilterKeys } from '../../pipe/filterkeys.pipe';
import { NameFilter } from '../../pipe/name-filter.pipe';

import * as GRPLIST from './group-list.component'
import * as GRP from '../group/group.component'
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

@Component({
   selector: 'app-group-list',
   templateUrl: './group-list.component.html',
   styleUrls: ['./group-list.component.css'],
})
export class GroupListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

   DisplayModel: typeof STATIC.Model = STATIC.Model;   
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   ObjDisplayMode: typeof STATIC.Mode = STATIC.Mode;

   @Input()  mode = this.DisplayMode.SELECT;
   @Input()  iam = false;
   @Input()  showTitleFilter = true;
   @Input()  showSearchButton = true;
   @Input()  showSelectButton = true;
   @Input()  useMenuActionDialog = true;   
   @Input()  showAddButton = true;
   @Input()  showInfoButton = false;
   @Input()  showEditButton = false;   
   @Input()  showDeleteButton = false;
   @Input()  showMenuButton = true;
   @Input()  showRefreshButton = true;   
   @Input()  showListViewButton = true;   
   @Input()  template = this.DisplayTemplate.SELECT;
   @Input()  objUID: string;
   @Input()  userInfo: UserInfo;
   @Input()  userData: UserData;
   @Input()  selectedObj: any | null = null;
   @Input()  objData: any[];
   @Input()  selectedOrg: OrgData | null = null;
   @Input()  title: string;
   @Input()  titleFilter: string;
   @Input()  isPolling = false;   
   @Input()  pollData = true;
   @Input()  pollInterval = 4500;

   // @Output() getMembersEvent = new EventEmitter<any>();
   // @Output() addMemberEvent = new EventEmitter<any>();
   // @Output() removeMemberEvent = new EventEmitter<any>();

   @Output() refreshListEvent = new EventEmitter<any>();
   @Output() viewObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() openObjEvent = new EventEmitter<any>();      
   @Output() doneEvent = new EventEmitter<any>();

   @ViewChild(MatTable, {static: false})table: MatTable<any>;
   @ViewChild(MatSort, { static: false }) sort: MatSort;
   @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
   // @ViewChild(titleFilterInput, { static: false }) titleFilterInput: MatInput;
   @ViewChild('orgTable', {static: false}) orgTable: ElementRef;
   @ViewChild('orglist', {static: false}) orglist: ElementRef;
   @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('grpListItem', {static: false}) grpListItem: GroupComponent;
   @ViewChild('grpFormItem', {static: false}) grpFormItem: GroupComponent;
   //@ViewChild('orgListItem', {static: false}) orgListItem: OrgComponent;
   //@ViewChild('orgFormItem', {static: false}) orgFormItem: OrgComponent;
   @ViewChild('autoCombo') public autoComplete: MatAutocomplete;
   @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

   // Constants
   /***
   ORG_SELECT = 1;
   ORG_LIST = 2;
   ORG_TABLE = 3;
   ***/

   // Form
   objLabel = 'Group';
   comboForm: FormGroup;
   objNameInput = new FormControl('');   
   orgSelectForm: FormGroup;
   formFieldStyle = 'fill';  // or outline
   viewOnly = false;
   showObjSelect = true;

   updateInterval: any;
   groupListUpdateSubscription: any;   
   
   // DialogRef vars
   actionPromptDialogRef: any = null;
   formDialogRef: any = null;   
   helpDialogRef: any = null;

   // Mode
   prevMode: string = null;

   // Combo Filter
   filteredOptions: Observable<any[] | null>;
   filteredOrgs: Observable<OrgData[]>;

   // Org and User Info
   objMode = this.ObjDisplayMode.LIST;
   selectedUser;
   selectedUserList: any[] = [];

   // Obj/Org Admin
   isObjAdmin = false;
   isOrgAdmin = false;   

   // Form Data
   selectForm: FormGroup;
   tableForm: FormGroup;
   // orgControl = new FormControl(this.selectedOrg);

   // Table Data
   displayedColumns: string[];
   adminDisplayedColumns: string[];
   dataSource: MatTableDataSource<any>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;
   searchText: string;

   // View vars
   toggleFiberOrgs = false;
   isOrgsVisible = false;
   filterValue: string;

   // Instance vars
   orgCount: number;
   orgList: OrgData[] = [];

   loading: boolean;

   // subscriptions
   orgListSubscription: any;
   groupListSubscription: any;
   // orgUserSubscription: any;

   constructor(
      public toast: ToasterService,   
      private fb: FormBuilder,
      private auth: AuthService,
      public usersvc: UserService,
      public groupsvc: GroupService,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public changeDetectorRef: ChangeDetectorRef,
      // public actionPromptDialog: MatDialog,
      public formDialog: MatDialog,
      // public helpDialog: MatDialog,      
      ) {
      } // constructor

   ngOnInit(): void {
      // console.log('group list called.');
      this.userData = this.auth.getTokenInfo();

      if (! this.userData) {
        console.error('OrgListComponent WARNING userData not defined.');
      }

      if (! this.userInfo) {
        console.error('OrgListComponent WARNING userInfo not defined.');
      }

      this.refreshList();

      if ( !this.template ) {
         this.template = this.DisplayTemplate.SELECT;
      }

      this.comboForm = new FormGroup( {
         objNameInput: new FormControl()
      });

      if (this.selectedObj) {
         // console.log('group-list afterViewInit selectedObj=', this.selectedObj);
         this.setSelectedObj(this.selectedObj);
      } 
   }

   async ngAfterViewInit(): Promise<void> {
      // this.refreshOrgList();
      this.userData = this.auth.getTokenInfo();

      /*****
      this.filteredOptions = this.objNameInput.valueChanges
      .pipe(
        startWith<string | GroupData>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) :
                           this.groupsvc.userGroupList.slice())
      );
      ****/

      this.objNameInput = new FormControl();
      // console.log(this.objNameInput.value);

      if (this.selectedObj) {
        // console.log('group-list afterViewInit selectedObj=', this.selectedObj);
        this.setSelectedObj(this.selectedObj);
      } else {
            if ( this.objNameInput ) {
               this.objNameInput.setValue('');
            }
        }
       
      //console.log('group-list _auto=',
      //             this._auto.autocomplete.options.toArray());

      // Get updates on updateInterval every pollInterval seconds..
      if (this.updateInterval) {
        clearInterval(this.updateInterval);      
      }
      this.updateInterval = setInterval(
        () => {
                if (this.pollData && !this.isPolling) {
                  this.isPolling=true;
                  this.refreshList();
                  this.isPolling=false;
                }
        }, this.pollInterval);
        
       this.groupListUpdateSubscription = this.groupsvc.userGroupListUpdates$.subscribe(
         gl => {
           if (!gl) {
             this.clearComboInput();
           }
           if (gl && this.selectedObj) {
             const result = (gl.find(x => x.uid === this.selectedObj.uid) === undefined) ? false : true;         
             if (!result) {
               this.setSelectedObj(gl[0]);
             }
           }
       });
   }

   ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      // console.log('group-list changes=', changes);
      if ( changes.selectedObj?.currentValue ) {
         this.setSelectedObj(changes.selectedObj.currentValue);
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
         this.setMode(this.mode);
      }
      if ( changes.titleFilter ) {
         this.applyTableFilter( changes.titleFilter.currentValue );      
      }
   }

   applyTableFilter(val) {
     if ( this.dataSource && val ) {
        this.dataSource.filter = val.trim().toLowerCase();
     }
   }
   
   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     if( this.orgListSubscription ) {
        this.orgListSubscription.unsubscribe();
     }
     if( this.groupListSubscription ) {
        this.groupListSubscription.unsubscribe();
     }
     // console.log('group-list ondestroy');
     if (this.updateInterval) {
       clearInterval(this.updateInterval);
     }
     if (this.groupListUpdateSubscription) {
        this.groupListUpdateSubscription.unsubscribe();
     }
   }

   private _filter(value: string): GroupData[] {
     return this.groupsvc.userGroupList.filter(obj =>
       obj.name.toLowerCase().includes(value.toLowerCase())
     );
   }

   private _filterOrgNames(value: string): OrgData[] {
     // if ( value == '' ) { return this.orgsvc.OrgList }
     // console.log('filter org names filterValue =', filterValue);
     // const newlist = this.orgsvc.OrgList;
     // const olist = newlist.filter (o => {
     //   if (o.org_name.toLowerCase().includes(filterValue)) {
     //    return o;
     //   }
     // });
     // console.log('filter olist = ', olist );
     // return olist;
     // if( value && value !== null) {
     //   if (value == '')
     //     { return new Array<OrgData>(); }
        const filterValue = (''+value).toLowerCase();
        if ( this.orgsvc.OrgList ) {
           return this.orgsvc.OrgList.filter(o => o.org_name.toLowerCase().includes(filterValue));
        } else {
             return null;
          }
     //}
   }

   clearComboInput() {
      this.objNameInput.patchValue('');
   }

   setComboOption(uid: string) {
      const opts = this._auto.autocomplete.options.toArray();
      if ( opts ) {
        const item = opts.find( o => o.value.uid === uid );
        if (item) {
          this.objNameInput.setValue(item.value);
        }
      }
   }

   async refreshList() {
     this.setMode(this.mode);

     // console.log('group-list refreshList');

     if ( this.userData ) {
       if ( this.mode === this.DisplayMode.TABLE ) {
          this.refreshObjTable();
     } else {
  // console.log('group-list getusergroupdata userData.uid=, this.userData.uid);
           // console.log('group-list refreshList');
           this.groupsvc.getUserGroupData$( this.userData.uid );
       }
     }
   }

   createForm() {
      this.orgSelectForm = this.fb.group({
               org_select_field: this.viewOnly ?
                                 [{value: '', disabled: true}] :
                                 [''] });
   }

   setForm() {
   }

   async refreshObjTable() {
      /***
      if ( this.userData && this.userData.iam && this.iam) {
          this.grpList = await this.groupsvc.getAllGroupsPromise();
      } else {
            this.grpList = await this.groupsvc.getUserGroups( this.userData.uid );
        }
      ***/
      
      await this.groupsvc.getUserGroups( this.userData.uid );

      this.dataSource = new MatTableDataSource<GroupData>(this.groupsvc.userGroupList);

      if ( this.paginator ) {
         this.dataSource.paginator = this.paginator;
      }
      if ( this.sort ) {
         this.dataSource.sort = this.sort;
      }
   }

   /****
   getObjFieldRefs(obj) {
      if ( obj && obj.owner ) {
       this.ownerData$ = this.usersvc.getUserInfo$(obj.owner);
      }
      if ( obj && obj.org_uid ) {
       this.orgData$ = this.orgsvc.getOrganization$(obj.org_uid);
      }
   }
   ***/

   setTemplate(t) {
      this.template = t;
      console.log('setTemplate t=', t);
   }

   setPrevMode() {
      if ( this.prevMode !== null ) {
          this.setMode( this.prevMode );
      }
   }

   toggleShowObjSelect() {
      this.showObjSelect = !this.showObjSelect;
   }

   setMode(m) {
      this.prevMode = this.mode;
      this.mode = m;
      // console.log('setMode m=', this.mode);

      /***
      if ( this.userData && this.userData.iam) {
         this.displayedColumns = ['org_client_nbr', 'org_name', 'org_type', 'org_contact_email', 'org_actions', 'org_selector'];
         // this.displayedColumns = ['org_client_nbr', 'org_name', 'org_type', 'org_contact_email', 'org_status'];
      } else {
         this.displayedColumns = ['group_name', 'org_name'];
        }
      ***/

      // this.displayedColumns = ['name', 'owner', 'owner_email', 'org_uid', 'obj_actions', 'obj_selector' ];
      this.displayedColumns = ['name', 'obj_actions', 'obj_selector' ];

      switch (this.mode) {
         case this.DisplayMode.SELECT: {
            this.template = this.DisplayTemplate.SELECT;
            this.objMode = this.ObjDisplayMode.SELECT;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            //this.createForm();
            //this.setForm();
            break;
         }
         case this.DisplayMode.COMBO: {
            // this.selectedObj = null;
            this.template = this.DisplayTemplate.COMBO;
            this.objMode = this.ObjDisplayMode.COMBO;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            //this.createForm();
            //this.setForm();
            break;
         }
         case this.DisplayMode.EXPAND_LIST: {
            this.template = this.DisplayTemplate.EXPAND_LIST;
            this.objMode = this.ObjDisplayMode.EXPAND_LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         case this.DisplayMode.SELECT_LIST: {
            this.template = this.DisplayTemplate.SELECT_LIST;
            this.objMode = this.ObjDisplayMode.SELECT_LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.LIST: {
            this.template = this.DisplayTemplate.LIST;
            this.objMode = this.ObjDisplayMode.LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.VIEW: {
            this.showObjSelect = false;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.VIEW;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.EDIT: {
            this.showObjSelect = false;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.EDIT;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.ADD: {
            this.showObjSelect = false;
            this.selectedObj = null;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.ADD;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            //console.log('GRPLIST ADD mode=', this.mode);
            //console.log('GRPLIST ADD template=', this.template);
            //console.log('GRPLIST ADD orgMode=', this.objMode);
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.TABLE || 'T': {
            this.template = this.DisplayTemplate.TABLE;
            this.objMode = this.ObjDisplayMode.TABLE;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            /***
            this.titleFilterInput.valueChanges.subscribe(
               name => {
                  this.dataSource.filter({name: this.titleFilter});
               }
            );
            ***/
            break;
         }
         case this.DisplayMode.DELETE || 'DEL': {
            this.template = this.DisplayTemplate.DELETE;
            this.objMode = this.ObjDisplayMode.DELETE;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         default: {
            console.error('Error org-list default mode=', this.mode);
            this.template = this.DisplayTemplate.SELECT;
            this.objMode = this.ObjDisplayMode.SELECT;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = true;
            this.showMenuButton = true;
            this.showSelectButton = false;
            break;
         }
      }
      // console.log('this.mode=', this.mode);
      // console.log('this.template=', this.template);
      // console.log('this.objMode=', this.objMode);
  }

  /****
  onOrgNameChange(evt) {
      // evt.target.value
      let org = null;
      org = this.orgsvc.OrgList.find(o => {
          let obj = null;
          if (o.org_name.toLowerCase() === evt.target.value.toLowerCase()) {
              obj = o;
          }
          return obj;
      });
      console.log('orgNameChange orgSelectionChange evt=', evt.target.value);
      console.log('orgNameChange orgSelectionChange org=', org);
      if (org) {
          // this.objNameInput.patchValue(org);
      } else {
          this.setSelectedObj(null);
      }
  }
  ****/

  toggleChecked(o) {
     // console.log('group-list toggle checked e=', o);
     if ( this.selectedObj && this.selectedObj.uid === o.uid ) {
         this.setSelectedObj(null);
     } else {
          this.setSelectedObj(o);
       }
  }

   async getIsObjAdmin(obj: any, uData: UserData) {
      let obj_admin = false;
      // Check if user is the owner
      if (obj && obj.owner && uData && uData.uid) {
         if ( obj.owner === uData.uid ) {
             obj_admin = true;
         } 
      }
      // Check if user is in group admins list
      if (obj && obj.admins && uData && uData.uid) {
         for (const uid of obj.admins) {
            if (uid === uData.uid) {
               obj_admin = true;
            }
         }
      }
      // Check if app manager
      /***
      if ( uData.iam && this.iam ) {
         return true;
      }
      ***/
      return obj_admin;
   }
   
   async getIsOrgAdmin(org: OrgData, uData: UserData) {
      let org_admin = false;
      if (org && 'uid' in org && uData && 'uid' in uData) {
         const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(org.uid, uData.uid);
         if ( orgusr && 'org_admin' in orgusr && orgusr.org_admin ) {
             org_admin = true;
         } else {
             org_admin = false;
           }
      }
      return org_admin;
   }

  onSelectionChange(evt) {
      // console.log('group-list grpSelectionChange evt=', evt);
      this.setSelectedObj(evt.value);
  }

  setSelectedObjEvent(event) {
      // console.log('onSelectionChange called', event);
      // this.setSelectedOrg(event.option.value);
      this.selectObjEvent.emit(event.option.value);
  }

  async setSelectedObj(e) {
      // console.log('group-list setSelectedObj e=', e);

      if ( e === "NONE" ) {
         this.selectedObj = null;
      } else {
           this.selectedObj = e;
           this.setForm();
        }
      this.selectObjEvent.emit(e);

      if ( this.objNameInput && this.selectedObj ) {
        this.objNameInput.patchValue(this.selectedObj.name);
        // this.comboForm.patchValue({objNameInput: this.selectedObj.name});    
      } else {
           this.objNameInput.patchValue('');
        }

      if ( this.mode == this.DisplayMode.SELECT ) {
         this.template = this.DisplayTemplate.SELECT;
      }
      if ( this.grpFormItem ) {
         this.grpFormItem.setObjData(this.selectedObj);
      }
      this.isObjAdmin = await this.getIsObjAdmin(this.selectedObj, this.userData);      
  }

  nextObj() {
    let idx = 0;

    // console.log('next: value = ', this.orgsvc.getOrgListData());

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid == this.selectedObj.uid) {
                                   return true;
                                }
       })
       // console.log('nextObj current idx=', idx);
       // console.log('nextObj selected=', this.selectedObj);
    }
    if ( idx >= 0 && (idx+1) < this.groupsvc.getUserGroupList().length ) {
       this.selectedObj = this.groupsvc.getUserGroupList()[idx+1];
       // console.log('new selectedObj =', this.selectedObj);
    }
  }

  prevObj() {
    let idx = 0;

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid === this.selectedObj.uid) {
                                   return true;
                                }
       })
       // console.log('prevOrg current idx=', idx);
    }
    if ( idx >= 0 ) {
       if ( idx === 0 ) {
           this.selectedObj = null;
       } else {
           this.selectedObj = this.groupsvc.getUserGroupList()[idx-1];
         }
    }
  }

  viewObj() {
     console.log('view obj.');
     this.viewObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.VIEW);
  }

  createObj() {
     console.log('create obj.');
     this.createObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.ADD);
  }

  editObj() {
     console.log('edit obj.');
     this.editObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.EDIT);
  }

  deleteObj() {
     console.log('delete obj.');
     this.deleteObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.DELETE);     
  }

  onSubmit(obj: any) {
    // console.log('group-list onSubmit obj=', obj);
    this.showObjSelect = true;
    this.selectedObj = obj;
    this.setPrevMode();
    this.refreshList();
    this.objNameInput.patchValue( obj.name );
    this.doneEvent.emit(this.selectedObj);
    this.selectObjEvent.emit(this.selectedObj);
    // console.log('group-list onSubmit selectedObj=', this.selectedObj);
  }

  onCancel() {
     // console.log('group-list onCancel event.');
     this.showObjSelect = true;
     this.setPrevMode();
  }

  onDone() {
     console.log('group-list onDone event.');
     this.doneEvent.emit(this.selectedObj);
     this.showObjSelect = true;
     this.setPrevMode();
  }

  getOptionName(o) {
     // console.log('group-list getOptionName o=', o);
     const name = o && o.name ? o.name : o;
     let owns = '';
     const star = '&#9733';
     if (this.userData && this.userData.uid && o && o.owner &&
         this.userData.uid===o.owner) {
         owns = star;
     }
     return name + owns;
  }

  /***
  displayGroupName(grp){
     if(grp) {
        return grp.name;
     }
  }
  ***/

  /***
  updateLocalItem(newItem){
    //let idx = this.objList.findIndex(obj => obj.uid === newItem.uid);
    //this.objList[idx] = newItem;
    //this.objList = Object.assign([], this.objList);
  }
  ***/

  updateItem(newItem) {
     this.groupsvc.updateItem(newItem);
     this.setSelectedObj(newItem);
  }

  compareObjs(o1: any, o2: any): boolean {
     if ( o1 == null || o2 == null )
        { return false; }
     const result = o1.uid === o2.uid;
     // console.log('compare objs result=', result);
     return result;
  }

  // Table Pagination Change
  onTableChangePage(pe:PageEvent) {
    console.log(pe.pageIndex);
    console.log(pe.pageSize);
  }

  // Table manipulation functions
  //
  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.filterValue = filterValue;
    this.dataSource.filter = filterValue;
    // console.log('ObjListComponent filterValue=', filterValue);
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

   // Scroll functions
   // member list scroll
   scroll_down(): void {
      // console.log('OLC orglist = ', this.orglist);
      const md = this.orglist.nativeElement;
      const su = this.scrollUp.nativeElement;
      const sd = this.scrollDown.nativeElement;
      const scrollPosition = md.scrollTop;
      const scrollHeight = md.scrollHeight;
      // console.log('md = ', md);
      // console.log('su = ', su);
      // console.log('sd = ', sd);

      if ( scrollPosition === (scrollHeight - 135)){
          sd.style.opacity = '.4';
      } else {
          sd.style.opacity = '1';
      }
      su.style.opacity = '1';
      md.scrollTop += 20;
    }

    scroll_up(): void {
       const md = this.orglist.nativeElement;
       const su = this.scrollUp.nativeElement;
       const sd = this.scrollDown.nativeElement;
       const scrollPositionTop = md.scrollTop;
       // const scrollHeight = md.scrollHeight;
       // console.log('md = ', md);
       // console.log('su = ', su);
       // console.log('sd = ', sd);

       if (scrollPositionTop === 0 ) {
            su.style.opacity = '.4';
        } else {
            su.style.opacity = '1';
        }
       sd.style.opacity = '1';
       md.scrollTop -= 20;
    }

   async viewObjDialog() {
      if ( this.selectedObj ) {
         const updatedObj = await this.groupsvc.getGroup(this.selectedObj.uid);
         if ( updatedObj ) {
            this.selectedObj = updatedObj;
         }
         this.openFormDialog( this.DisplayModel.GROUP, this.DisplayMode.VIEW,
                              this.selectedObj);
      }
   }

   openFormDialog(model, mode, grpData) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let title = 'Group';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View Group';
              break;
        case this.DisplayMode.ADD:
              title = 'Create Group';
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit Group';
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete Group';
              break;
      }

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.GROUP,
         objMode: mode,
         objData: grpData,
         dialogTitle: title,
         /****
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               // console.log('formDialog return=', data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
               this.refreshListEvent.emit();
            }, 1100);
          } // data
      );
    } // openGroupFormDialog

} // group-list
