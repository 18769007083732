import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { ToasterService } from '../service/toaster.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';

import { UserData } from '../model/userdata.model';

import { environment } from '../../environments/environment';
import { GroupData } from '../group/model/groupdata.model';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';


@Injectable()
export class GroupService {

   GroupSystemCnt: CountData = null;
   GroupStorage: SumData = null;

   OrgGroupSystemCnt: CountData = null;
   OrgGroupStorage: SumData = null;

   // userGroupList for this instance for components using this service
   public userGroupList: GroupData[] = [];

   // new userGroupList for this instance for components using this servicen
   private newUserGroupList: GroupData[];

   // Subject for subscriptions returns the global groupList
   public userGroupListUpdates$: BehaviorSubject<GroupData[]> = new BehaviorSubject<GroupData[]>(this.userGroupList);
   userGroupList$: Observable<GroupData[]> = this.userGroupListUpdates$.asObservable();

   // global groupList for this instance for components using this service
   public groupList: GroupData[] = [];
   private newGroupList: GroupData[] = [];  

   // Subject for subscriptions returns the global groupList
   public groupListUpdates$: BehaviorSubject<GroupData[]> = new BehaviorSubject<GroupData[]>(this.groupList);
   groupList$: Observable<GroupData[]> = this.groupListUpdates$.asObservable();

   public selectedGroupSubject: Subject<GroupData> = new Subject<GroupData>();

   private groupSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.GroupSystemCnt);

   groupSystemCount$: Observable<CountData> = this.groupSystemCountSubj$.asObservable();

   private groupStorageSubj$: BehaviorSubject<SumData> = new BehaviorSubject<SumData>(this.GroupStorage);

   groupStorage$: Observable<SumData> = this.groupStorageSubj$.asObservable();

   private orgGroupCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.OrgGroupSystemCnt);

   orgGroupCount$: Observable<CountData> = this.orgGroupCountSubj$.asObservable();

   // User UID for user group queries
   private uuid: string;

   // presently selectedGroupUID
   private selectedGroupUID: string;

   // presently selectedGroup
   private selectedGroup: GroupData;

   isValid: any;
   userData: any;

   constructor(
     private http: HttpClient,
     private toast: ToasterService,
   ) {
     this.userGroupList = [];
   }

   // CJ - Add and Delete operations will sendGroupMemberUpdate(gid) to
   // send the group id for the updated member list as an observable.
   // Components using the service can subscribe using the
   // getGroupListUpdates() method to refresh a local group list or
   // use groupList() to reference the global groupList

   async sendUserGroupListUpdate(grplist: GroupData[]) {
      this.userGroupList = await this.getUserGroups(this.uuid);
      this.userGroupListUpdates$.next(grplist);
   }
   clearUserGroupListUpdates() {
      this.userGroupListUpdates$.next(null);
   }
   getUserGroupListUpdates(): Observable<any> {
      return this.userGroupListUpdates$.asObservable();
   }

//   getGroupList(): GroupData[] {
//        return this.userGroupsList;
//   }

   getUserGroupList(): GroupData[] {
      return this.userGroupList;
   }

   setSelectedGroupUID(guid: string) {
      this.selectedGroupUID = guid;
      const grp = this.userGroupList.find(g => g.uid === guid);
      this.setSelectedGroup( grp );
   }

   getSelectedGroupUID(): string {
      this.selectedGroupUID = this.selectedGroup.uid;
      return this.selectedGroupUID;
   }

   setSelectedGroup(grp: GroupData) {
      this.selectedGroup = grp;
      this.selectedGroupSubject.next( grp );
   }

   getSelectedGroup(): GroupData {
      return this.selectedGroup;
   }

   // Get group by uid returning observable for reactive
   // programming and async pipes
   //
   public getGroup$(guid): Observable<GroupData> {
     return this.http.get<GroupData>(environment.apiUrl + '/group/' + guid);
   }

   /***
   public getGroupData$(gid: string){
       this.http.get<GroupData> (this.baseUrl + '/group/' + gid)
       .subscribe( res =>
                      { const item = this.orgItem$.next(res);
                        console.log('grp = ', res);
                        return item;
                      });
   }
   ***/

   // Get group as a promise for flat code where necessary
   // and observable not appropriate (requires async/await from caller)
   //
   async getGroupPromise(guid): Promise<GroupData> {
     return this.getGroup(guid);
   }

   async getGroup(guid): Promise<GroupData> {
     return this.http.get(environment.apiUrl + '/group/' + guid)
                .toPromise()
                .then(
                    (x) => x as GroupData,
                    (e) => { console.log(e);
                             throw e;
                           }
                );
   }

   getUserGroups$(uuid: string): Observable<GroupData[]> {
      // console.log('groupsvc: getGroups$()...');
      return this.http.get<GroupData[]>(environment.apiUrl + '/usergroup/uid/' + uuid );
   }

   getUserGroupData$(uuid: string) {
      // console.log('groupsvc: getUserGroupsData() uuid=', uuid);
      this.uuid = uuid;
      this.http.get<GroupData[]>(environment.apiUrl + '/usergroup/uid/' + uuid )
          .subscribe((res) => {
                    // let item = this.orgListItems$;
                    this.userGroupList = res;
                    this.userGroupListUpdates$.next(res);
                    // console.log('group list =', res);
                    return res as GroupData[];
           },
            (e) => { console.log(e);
                     throw e;
                   }
           );
   }

   OLDgetUserGroupData$(uuid: string): Observable<GroupData[]> {
      console.log('groupsvc: getUserGroupsData() uuid=', uuid);
      this.uuid = uuid;
      return this.http.get<GroupData[]>
             (environment.apiUrl + '/usergroup/uid/' + uuid )
             .pipe( map((x) => {
               this.userGroupList = x as GroupData[];
               console.log('groupsvc: getGroups userGroupList=', this.userGroupList);
               this.userGroupListUpdates$.next(x);
               return x as GroupData[];
               },
               (e) => { console.log(e);
                        throw e;
                      }
             ));
   }

   // Use toPromise so we can use async and await
   // try{
   //   grplist = await this.groupsvc.getGroupsPromise(uuid);
   // } catch (e) { }
   // -----
   // Note in rxjs 8 will need to convert to using plain getGroups Observable
   // and use the following on the component:
   // async getUserGroupsPromise() {
   //   const groups$ = this.getUserGroupsObservable(uuid);
   //   lastGroup = await lastValueFrom(groups$);
   //   this._groupList = group$ as GroupData[];
   //   return this._groupList;
   // }
   getUserGroupsPromise(uuid: string): Promise<any> {
      this.uuid = uuid;
      return this.http.get<GroupData[]>
             (environment.apiUrl + '/usergroup/uid/' + uuid ).toPromise()
             .then(
                (x) => {
                    // console.log('getUserGroupsPromise x=', x);
                    this.newUserGroupList = x as GroupData[];
                    return this.newUserGroupList;
                },
                (e) => {
                    console.log('groupsvc failed to get groups, error: ', e);
                    throw e;
                }
            );
   }

   removeUserGroupListItem(o) {
      const index = this.userGroupList.findIndex(x => x.uid === o.uid);
      this.userGroupList.splice(index, 1);
   }

   updateUserGroupListItem(obj, nobj) {
      const index = this.userGroupList.findIndex(x => x.uid === obj.uid);
      for(const key in nobj) {
        if (key != 'uid') {
           obj[key] = nobj[key];      
        }
      }
   }

   async getUserGroups(uuid: string) {
     const glist: GroupData[] = [];
     const nglist: GroupData[] = [];
     // console.log('groupsvc getUserGroups uuid=', uuid);
     this.newUserGroupList = await this.getUserGroupsPromise(uuid);

     // If userGroupList is empty populate it
     // console.log('groupsvc ugl length=', this.userGroupList.length);
     // console.log('groupsvc nugl=', this.newUserGroupList);

     if (!this.userGroupList) {
       this.userGroupList = glist;
     }
     if (!this.newUserGroupList) {
       this.userGroupList = nglist;
     }
     if ( this.userGroupList.length === 0 && this.newUserGroupList ) {
        this.userGroupList = Array.from( this.newUserGroupList );
        // console.log('getUserGroups: created array from query', this.userGroupList);
     } else {
          // Need to remove any items in ugl not in new grp list..
          // console.log('else groupsvc ugl length=', this.userGroupList.length);
          // console.log('else groupsvc nugl=', this.newUserGroupList);
          if ( this.userGroupList &&
               this.userGroupList.length > 0 &&
               this.newUserGroupList ) {
             for (const o of this.userGroupList) {
               // console.log ('getUserGroups o=', o);
               const nobj = this.newUserGroupList.find(x => x.uid === o.uid);
               // console.log('groupsvc nobj=', nobj);
               if (!nobj) {
                 this.removeUserGroupListItem(o);
               }
             }
             // compare and update obj if necessary
             for (const obj of this.userGroupList) {
               // console.log ('getUserGroups obj=', obj);
               const nobj = this.newUserGroupList.find(x => x.uid === obj.uid);
               if (JSON.stringify(obj) !== JSON.stringify(nobj)) {
                  this.updateUserGroupListItem(obj, nobj);
               }
             }
             // Add any items from newlist not in olist
             const newItems = this.newUserGroupList.filter(obj => this.userGroupList.every(g => g.uid !== obj.uid));
             // console.log('getUserGroups: newItems=', newItems);
             this.userGroupList.push(...newItems);
             // this.userGroupList = Object.assign([], this.userGroupList);
             // this.userGroupListUpdates.next(this.userGroupListUpdates);
        }
     }
     return this.userGroupList;
   }

   removeAllGroupListItem(o) {
      const index = this.groupList.findIndex(x => x.uid === o.uid);
      this.groupList.splice(index, 1);
   }

   updateAllGroupListItem(obj, nobj) {
      for(const key in nobj) {
        if (key != 'uid') {
           obj[key] = nobj[key];      
        }
      }
   }
   
   async getAllGroups(userdata: UserData) {
     if (!userdata) {
       console.error('groupsvc invalid userdata, error!');     
     }
     if (userdata && !userdata.iam) {
       console.error('groupsvc get all - invalid permissions, error!');     
     }
     
     this.newGroupList = await this.getAllGroupsPromise(userdata);
     
     if (!this.groupList) {
       this.groupList = [];
     }
     if (!this.newGroupList) {
       this.newGroupList = [];
     }
     if ( this.userGroupList.length === 0 && this.newUserGroupList ) {
        this.groupList = Array.from( this.newGroupList );
     } else {
          // Need to remove any items in ugl not in new grp list..
          // console.log('else groupsvc ugl length=', this.userGroupList.length);
          // console.log('else groupsvc nugl=', this.newUserGroupList);
          if ( this.groupList &&
               this.groupList.length > 0 &&
               this.newGroupList ) {
             for (const o of this.groupList) {
               // console.log ('getUserGroups o=', o);
               const nobj = this.newGroupList.find(x => x.uid === o.uid);
               // console.log('groupsvc nobj=', nobj);
               if (!nobj) {
                 this.removeAllGroupListItem(o);
               }
             }
             // compare and update obj if necessary
             for (const obj of this.groupList) {
               // console.log ('getUserGroups obj=', obj);
               const nobj = this.newGroupList.find(x => x.uid === obj.uid);
               if (JSON.stringify(obj) !== JSON.stringify(nobj)) {
                  this.updateAllGroupListItem(obj, nobj);
               }
             }
             // Add any items from newlist not in olist
             const newItems = this.newGroupList.filter(obj => this.groupList.every(g => g.uid !== obj.uid));
             // console.log('getUserGroups: newItems=', newItems);
             this.groupList.push(...newItems);
        }
     }
     return this.groupList;
   }
   
   getAllGroupsPromise(userdata: UserData) {
     if (!userdata) {
       console.error('groupsvc invalid userdata, error!');     
     }
     if (userdata && !userdata.iam) {
       console.error('groupsvc get all - invalid permissions, error!');     
     }
     // NOTE: need to check userData.iam
     return this.http.get<GroupData[]>(environment.apiUrl + '/group')
             .toPromise()
             .then(
                (x) => {
                    // console.log('getAllGroupsPromise x=', x);
                    this.newGroupList = x as GroupData[];
                    return this.newGroupList;
                },
                (e) => {
                    console.error('groupsvc failed to get all groups, error: ', e);
                    throw e;
                }
      );
   }

   OLDgetAllGroupsPromise() {
      return this.http.get<GroupData[]>
             (environment.apiUrl + '/group' ).toPromise()
             .then(
                (x) => {
                    return x as GroupData[];
                },
                (e) => {
                    console.log('groupsvc: get ALL groups error: ', e);
                    throw e;
                }
            );
   }

   getAllGroups$(): Observable<GroupData[]> {
      // console.log('groupsvc: getAllGroups$()...');
      return this.http.get<GroupData[]>(environment.apiUrl + '/group/');
   }

   async addGroup(newname) {
        // Add the group on the server
        return this.http.post(environment.apiUrl + '/group', {name: newname})
        .toPromise()
        .then(
            (x : GroupData) => {
                console.log('groupsvc add group x=: ', x);
                if ( this.userGroupList ) {
                  this.userGroupList.push( x );
                  this.sendUserGroupListUpdate(this.userGroupList);
                }
                return x as GroupData;
            },
            (e) => {
                console.log('groupsvc failed to create group: ', newname,
                            ' error:', e);
                throw e;
            }
        );
   }

   async addGroupPromise(data) {
        // Add the group on the server
        const groupData = JSON.stringify(data);
        console.table('groupsvc: add group promise data=', data);
        return this.http.post(environment.apiUrl + '/group', groupData)
        .toPromise()
        .then(
            (x : GroupData) => {
                console.log('groupsvc add group x=: ', x);
                this.sendUserGroupListUpdate(this.userGroupList);
                this.toast.pop('success', 'Add Group', `Created group ${data.name}`);
                return x as GroupData;
            },
            (e) => {
                console.log('groupsvc failed to create group: ', data,
                            ' error:', e);
                this.toast.pop('error', 'Add Group Error!', `Error creating group ${data.name}`);
                throw e;
            }
        );
   }

   async addOrgGroupPromise(oid, data) {
        const groupData = JSON.stringify(data);
        // Add the group on the server
        return this.http.post(environment.apiUrl + '/group', groupData)
        .toPromise()
        .then(
            (x : GroupData) => {
                console.log('groupsvc add group x=: ', x);
                this.userGroupList.push( x );
                this.sendUserGroupListUpdate(this.userGroupList);
                return x as GroupData;
            },
            (e) => {
                console.error('groupsvc failed to create group: ', groupData,
                            ' error:', e);
                throw e;
            }
        );
   }

   NEWdeleteGroupPromise(gid: string) {
       /****
       if ((!gid || gid === '')) {
           this.toast.pop('error', 'Error!', 'deleteGroup no Group ID provided!');
           return this.userGroupList;
        }
        ****/
       const group = this.userGroupList.filter((gr) => gr.uid === gid)[0];
       return this.http.delete(environment.apiUrl + '/group/' + gid )
        .toPromise()
        .then (
            (x) => {
               console.log('groupsvc: deleteGroup x=', x);
               this.userGroupList.forEach((g, index, obj) => {
                  if (g.uid === gid) {
                     obj.splice(index, 1);
                  }
               });
               this.sendUserGroupListUpdate(this.userGroupList);
               this.toast.pop('success', 'Delete Group', `Deleted group successfully`);
               console.log('groupsvc deleted group: ', group.name);
               return x;
            },
            (e) => {
                this.toast.pop('error', 'Delete Group', 'Error deleting group.');
                console.log('groupsvc failed to delete group: ', group.name, ' error:', e);
                throw e;
            }
        );
   }

   async NEWdeleteGroup(gid: string) {
      let x = null;
      try {
         x = await this.NEWdeleteGroupPromise(gid);
      } catch (e) {
          console.log('groupsvc: delete group error=', e);
          throw e;
        }
      return x;
   }

   deleteGroup(gid: string) {
       if ((!gid || gid === '')) {
           this.toast.pop('error', 'Error!', 'deleteGroup no Group ID provided!');
           return this.userGroupList;
        }
       const group = this.userGroupList.filter((gr) => gr.uid === gid)[0];
       return this.http.delete(environment.apiUrl + '/group/' + gid )
        .toPromise()
        .then (
            (x) => {
               console.log('groupsvc: deleteGroup x=', x);
               this.userGroupList.forEach((g, index, obj) => {
                  if (g.uid === gid) {
                     obj.splice(index, 1);
                  }
               });
               this.sendUserGroupListUpdate(this.userGroupList);
               this.toast.pop('success', 'Delete Group', `Deleted group successfully`);
               console.log('groupsvc deleted group: ', group.name);
               return this.userGroupList;
            },
            (e) => {
                this.toast.pop('error', 'Delete Group', `Error deleting group`);
                console.error('groupsvc failed to delete group: ', group.name,
                            ' error:', e);
                throw e;
            }
        );
   }

   renameGroup$(guid, renameValue, groupList){
        const groupData = JSON.stringify({
            name: renameValue
        });
        // Update the server
        this.http.put(environment.apiUrl + '/group/' + guid, groupData).subscribe(
            (x) => {
                // Directly update on success
                this.sendUserGroupListUpdate(guid);
                groupList.forEach((g) => {
                    if (g.uid === guid) {
                        this.toast.pop('success', 'Update Group', 'Renamed group from' + g.name +
                                                                  ' to ' + renameValue);
                        g.name = renameValue;
                    }
                });
            },
            (e) => {
                groupList.forEach((g) => {
                    if (g.uid === guid) {
                        this.toast.pop('error', 'Update Error', 'Failed to Renamed group from' + g.name +
                                                                  ' to ' + renameValue);
                    }
                });
                console.log(e);
            }
        );
   }

   // See above for promise handling after rxjs 8+
   renameGroupPromise(guid, renameValue) {
        const groupData = JSON.stringify({
            name: renameValue
        });
        // Update the server
        return this.http.put(environment.apiUrl + '/group/' + guid, groupData)
        .toPromise()
        .then(
            (x) => {
                // update userGroupList for this service
                const grp = this.userGroupList.find(g => g.uid === guid);
                const oldName = grp.name;
                grp.name = renameValue;
                console.log('groupsvc: renameGroup grp=', grp);
                // send update message to subscribers
                this.sendUserGroupListUpdate(this.userGroupList);
                // toast success - handle in component
                this.toast.pop('success', 'Rename Group', 'Group ' + oldName  + ' has been renamed to '
                                                          + renameValue);
                return grp;
            },
            (e) => {
                console.log(e);
                throw e;
            }
        );
   }

   updateItem(newItem) {
     if ( this.userGroupList ) {
        let idx = -1;
        idx = this.userGroupList.findIndex(item => item.uid === newItem.uid);
        if ( idx >= 0 ) {
          this.userGroupList[idx] = newItem;
          // some angular libraries require breaking the array reference
          // to pick up the update in the array and trigger change detection.
          // In that case, you can do following
          this.userGroupList = Object.assign([], this.userGroupList);
        }
     }
   }

   updateGroupPromise(guid, data) {
        const groupData = JSON.stringify(data);
        // Update the server
        const group = this.userGroupList.filter((gr) => gr.uid === guid)[0];
        let name = group.name;
        if ( data.name ) {
           name = data.name;
        }
        return this.http.put(environment.apiUrl + '/group/' + guid, groupData)
        .toPromise()
        .then(
            (x) => {
                // update userGroupList for this service
                this.updateItem(x);
                console.log('groupsvc: updateGroup grp=', guid);
                // send update message to subscribers
                this.sendUserGroupListUpdate(this.userGroupList);
                // toast success - handle in component
                this.toast.pop('success', 'Update Group', `Updated group ${name}`);
                return x as GroupData;
            },
            (e) => {
                this.toast.pop('error', 'Update Group Error', 'Failed to update group: ' +
name);
                console.log(e);
                throw e;
            }
        );
   }

   private handleError<T>(operation = 'operation', result?: T) {
     return (error: any): T => {

     // TODO: better job of transforming error for user consumption
     console.log(`${operation} failed: ${error.message}`);

     // Let the app keep running by returning an empty result.
     return (result as T);
     };
   }

   // *** Stats methods

   getGroupSystemCount$(){
      this.http.get<any>(environment.apiUrl + '/group/count/system')
      .subscribe(cnt => {
                       console.log('group system cnt =', cnt);
                       this.groupSystemCountSubj$.next(cnt);
                       return cnt as CountData;
             })
   }
   
   getGroupStorage$(uid: string){
      this.http.get<any>(environment.apiUrl + '/group/storage/' + uid);
      /*******
      .subscribe(size => {
                       console.log('group storage =', size);
                       this.groupStorageSubj$.next(size);
                       return size as SumData;
             });
      ********/
   }

   getGroupStorageSubj$(uid: string){
      this.http.get<any>(environment.apiUrl + '/group/storage/' + uid)
      .subscribe(size => {
                       console.log('group storage =', size);
                       this.groupStorageSubj$.next(size);
                       return size as SumData;
             });
   }

   getOrgGroupCount$(oid: string){
      return this.http.get<CountData>(environment.apiUrl + '/group/count/org/' + oid);
      /*******
      .subscribe(cnt => {
                       console.log('org group cnt =', cnt);
                       this.orgGroupCountSubj$.next(cnt);
                       return cnt as CountData;
             });
       *******/
   }

   getOrgGroupCountSubj$(oid: string){
      this.http.get<any>(environment.apiUrl + '/group/count/org/' + oid)
      .subscribe(cnt => {
                       console.log('org group cnt =', cnt);
                       this.orgGroupCountSubj$.next(cnt);
                       return cnt as CountData;
             });
   }

} // group.service
