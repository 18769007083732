import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Subject, BehaviorSubject } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import { Clipboard } from '@capacitor/clipboard';

import { DocumentData } from '../model/documentdata.model';
import { GroupService } from '../service/group.service';
import { ToasterService } from '../service/toaster.service';
import { AuthService } from './auth.service';
import { CountData } from '../model/countdata.model';
import { SumData } from '../model/sumdata.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private documentListUpdates: Subject<DocumentData[]> =
                               new Subject<DocumentData[]>();
  private pdfSrcUpdates: Subject<string> = new Subject<string>();

  baseUrl = environment.apiUrl;

  DocumentSystemCnt: CountData = null;
  DocumentSystemStorage: SumData = null;

  orgDocumentCnt: CountData = null;
  orgDocumentStorage: SumData = null;

  grpDocumentCnt: CountData = null;
  grpDocumentStorage: SumData = null;

  Doc: DocumentData = null;
  DocumentList: DocumentData[] = null;
  newDocumentList: DocumentData[] = null;
  
  orgDocumentList: DocumentData[] = null;
  newOrgDocumentList: DocumentData[] = null;  

  grpDocumentList: DocumentData[] = null;
  newGrpDocumentList: DocumentData[] = null;

  // Behavior Subjects
  public docItem$: BehaviorSubject<DocumentData> = new BehaviorSubject<DocumentData>(this.Doc);
  public docListItems$: BehaviorSubject<DocumentData[]> = new BehaviorSubject<DocumentData[]>(this.DocumentList);
  public orgDocListItems$: BehaviorSubject<DocumentData[]> = new BehaviorSubject<DocumentData[]>(this.orgDocumentList);
  public grpDocListItems$: BehaviorSubject<DocumentData[]> = new BehaviorSubject<DocumentData[]>(this.grpDocumentList);


  // Observables
  docData$: Observable<DocumentData> = this.docItem$.asObservable();
  docList$: Observable<DocumentData[]> = this.docListItems$.asObservable();
  grpDocList$: Observable<DocumentData[]> = this.grpDocListItems$.asObservable();
  orgDocList$: Observable<DocumentData[]> = this.orgDocListItems$.asObservable();


   // System counts and storage
   private documentSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.DocumentSystemCnt);
   documentSystemCount$: Observable<CountData> = this.documentSystemCountSubj$.asObservable();
   private documentSystemStorageSubj$: BehaviorSubject<SumData> = new BehaviorSubject<SumData>(this.DocumentSystemStorage);
   documentSystemStorage$: Observable<SumData> = this.documentSystemStorageSubj$.asObservable();

   // Org counts and storage
   private orgDocumentCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.orgDocumentCnt);
   orgDocumentCount$: Observable<CountData> = this.orgDocumentCountSubj$.asObservable();
   private orgDocumentStorageSubj$: BehaviorSubject<SumData> = new BehaviorSubject<SumData>(this.orgDocumentStorage);
   orgDocumentStorage$: Observable<SumData> = this.orgDocumentStorageSubj$.asObservable();

   // Group counts and storage
   public grpDocumentCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.grpDocumentCnt);
   grpDocumentCount$: Observable<CountData> = this.grpDocumentCountSubj$.asObservable();
   public grpDocumentStorageSubj$: BehaviorSubject<SumData> = new BehaviorSubject<SumData>(this.grpDocumentStorage);
   grpDocumentStorage$: Observable<SumData> = this.grpDocumentStorageSubj$.asObservable();   

  pdfURL: any;
  pdfSrc: any;
  pdfProgress = 0;
  pdfZoom = 1.0;
  pdfZoomPct = 100;
  pdfMaxZoom = 2.0;
  pdfMinZoom = 0.25;
  pdfMaxZoomPct = 200;
  pdfMinZoomPct = 25;
  pdfRotation = 0;
  pdfPageNbr = 1;
  pdfPageCount = 1;
  snapRotation = 0;
  // pageCount = 0;

  displayPageNbr = 1;

  activeDocument: any;

  httpHeaders: any = '';

  constructor(
     private toast: ToasterService,
     private http: HttpClient,
     private groupsvc: GroupService,
     private auth: AuthService
     ) {
         this.newDocumentList = [] as DocumentData[];
         this.DocumentList = [] as DocumentData[];
         this.pdfSrc = null;
         this.pdfPageNbr = 1;
       }

  // CJ - Add and Delete operations will sendGroupMemberUpdate(gid) to
  // send the group id for the updated member list as an observable.
  // Components using the service can subscribe using the
  // getGroupMemberUpdates() method and check the group id and
  // if it matches update the components group member list.

  sendDocumentListUpdate() {
     this.documentListUpdates.next(this.DocumentList);
  }
  clearDocumentListUpdates() {
    this.documentListUpdates.next(null);
  }
  getDocumentListUpdates(): Observable<any> {
    return this.documentListUpdates.asObservable();
  }

  getDocumentList() {
     return this.DocumentList;
  }

  // Get Documents as Observable<DocumentData[]>
  //
  getDocumentsObservable(): Observable<DocumentData[]> {
     const url = environment.apiUrl + + '/document';
     return this.http.get(url)
            .pipe( map( x => {
               this.DocumentList = x as DocumentData[];
               this.sendDocumentListUpdate();
               return this.DocumentList;
             } ));
  }


  removeDocFileListItem(o) {
     const index = this.DocumentList.findIndex(x => x.uid === o.uid);
     this.DocumentList.splice(index, 1);
  }

  updateDocFileListItem(obj, nobj) {
      for(const key in nobj) {
        if (key != 'uid') {
           obj[key] = nobj[key];      
        }
      }
  }

  processDocListChanges(list, newList) {
     // Need to remove any items in list not in newList..
     // console.log('processList length=', list.length);
     // console.log('processList newList=', newList);
     if ( list && list.length > 0 && newList ) {
       for (const o of list) {
         const nobj = newList.find(x => x.uid === o.uid);
         // console.log('processList nobj=', nobj);
         if (!nobj) {
           this.removeDocFileListItem(o);
         }
       }
       // compare and update obj if necessary
       for (const obj of list) {
         // console.log ('ou update obj=', obj);
         const nobj = newList.find(x => x.uid === obj.uid);
         if (JSON.stringify(obj) !== JSON.stringify(nobj)) {
           this.updateDocFileListItem(obj, nobj);
         }
       }
       // Add any items from newlist not in olist
       const newItems = newList.filter(obj => list.every(g => g.uid !== obj.uid));
       // console.log('ou processList: newItems=', newItems);
       list.push(...newItems);
    }
  }

  // Async method to getDocuments and update subscriptions to observers
  //
  async getDocuments() {
     // !!! Need to verify this is flattening the list
     //
     this.DocumentList = await this.getDocumentsPromise();
     /********
     if ( this.DocumentList.length === 0 && this.newDocumentList ) {
        this.DocumentList = Array.from( this.newDocumentList );
     } else {
          const newItems = this.newDocumentList.filter(obj => this.DocumentList.every(d => d.uid !== obj.uid));
          this.DocumentList.push(...newItems);
     }
     ********/
     return this.DocumentList;
  }

   // Use toPromise so we can use async and await
   // try{
   //   grplist = await this.groupsvc.getGroupsPromise(uuid);
   // } catch (e) { }
   // -----
   // Note in rxjs 8 will need to convert to using plain getDocuments Observable
   // and use the following on the component:
   // async loadDocuments() {
   //   const documents$ = this.documentsvc.getDocumentsObservable(duid);
   //   this.documents = await lastValueFrom(documents$);
   // }
   getDocumentsPromise(): Promise<DocumentData[]> {
       return this.http.get<DocumentData[]>(environment.apiUrl + '/document')
            .toPromise()
            .then(
                (x) => {
                   this.newDocumentList = x;
                   if (!this.DocumentList) {
                     this.DocumentList = [];
                   }
                   if (!this.newDocumentList) {
                     this.newDocumentList = [];
                   }
                   if ( this.DocumentList.length === 0 && this.newDocumentList ) {
                     this.DocumentList = Array.from( this.newDocumentList );
                     // console.log('getDocsPromise: created array from query', this.DocumentList);
                   } else {
                           this.processDocListChanges(this.DocumentList, this.newDocumentList);
                        }
                   this.docListItems$.next(this.DocumentList);
                   return this.DocumentList;
                   // this.sendDocumentListUpdate();
                   // return x;
                },
                (e) => {
                   console.log('documentsvc failed to get documents:', e);
                   throw e;
                }
            );
  }

  getOrgFiles$(oid: string){
   this.http.get<DocumentData[]> (this.baseUrl + '/document/org/' + oid)
    .subscribe( res =>
                      {
                       // console.log('documentsvc res=', res);
                       this.orgDocumentList = Object.assign([], res);
                       this.orgDocListItems$.next(res);

                      });
  }

  getGroupFiles$(gid: string){
   const dlist: DocumentData[] = [];
   const newdlist: DocumentData[] = [];   
   this.http.get<DocumentData[]> (this.baseUrl + '/document/group/' + gid)
    .subscribe( res =>
                      {
                       // console.log('documentsvc res=', res);
                       // this.grpDocumentList = Object.assign([], res);
                       this.newGrpDocumentList = res;                  
                       if (!this.grpDocumentList) {
                         this.grpDocumentList = dlist;
                       }
                       if (!this.newGrpDocumentList) {
                         this.newGrpDocumentList = newdlist;
                       }
                       if ( this.grpDocumentList.length === 0 && this.newGrpDocumentList ) {
                         this.grpDocumentList = Array.from( this.newGrpDocumentList );
                        // console.log('getGroupFiles: created array from query', this.grpDocumentList);
                      } else {
                           this.processGroupListChanges(this.grpDocumentList, this.newGrpDocumentList);
                        }
                       this.grpDocListItems$.next(this.grpDocumentList);
                       return this.grpDocumentList;
                      });
  }


  removeGrpFileListItem(o) {
     const index = this.grpDocumentList.findIndex(x => x.uid === o.uid);
     this.grpDocumentList.splice(index, 1);
  }

  updateGrpFileListItem(obj, nobj) {
      // const index = this.grpDocumentList.findIndex(x => x.uid === obj.uid);
      for(const key in nobj) {
        if (key != 'uid') {
           obj[key] = nobj[key];      
        }
      }
  }

  processGroupListChanges(list, newList) {
     // Need to remove any items in list not in newList..
     // console.log('processList length=', list.length);
     // console.log('processList newList=', newList);
     if ( list && list.length > 0 && newList ) {
       for (const o of list) {
         const nobj = newList.find(x => x.uid === o.uid);
         // console.log('processList nobj=', nobj);
         if (!nobj) {
           this.removeGrpFileListItem(o);
         }
       }
       // compare and update obj if necessary
       for (const obj of list) {
         // console.log ('ou update obj=', obj);
         const nobj = newList.find(x => x.uid === obj.uid);
         if (JSON.stringify(obj) !== JSON.stringify(nobj)) {
           this.updateGrpFileListItem(obj, nobj);
         }
       }
       // Add any items from newlist not in olist
       const newItems = newList.filter(obj => list.every(g => g.uid !== obj.uid));
       // console.log('ou processList: newItems=', newItems);
       list.push(...newItems);
    }
  }

  getGroupFilesPromise(gid: string): Promise<DocumentData[]> {
       const dlist: DocumentData[] = [];
       const newdlist: DocumentData[] = [];   
       return this.http.get<DocumentData[]>(environment.apiUrl + '/document/group/' + gid)
            .toPromise()
            .then(
                (x) => {
                   this.newGrpDocumentList = x;
                   if (!this.grpDocumentList) {
                     this.grpDocumentList = dlist;
                   }
                   if (!this.newGrpDocumentList) {
                     this.newGrpDocumentList = newdlist;
                   }
                   if ( this.grpDocumentList.length === 0 && this.newGrpDocumentList ) {
                     this.grpDocumentList = Array.from( this.newGrpDocumentList );
                    // console.log('getGroupFiles: created array from query', this.grpDocumentList);
                   } else {
                     // Need to remove any items in ugl not in new grp list..
                     // console.log('else groupsvc gdl length=', this.grpDocumentList.length);
                     // console.log('else groupsvc ngdl=', this.newGrpDocumentList);
                     if ( this.grpDocumentList &&
                       this.grpDocumentList.length > 0 &&
                       this.newGrpDocumentList ) {
                       for (const o of this.grpDocumentList) {
                          // console.log ('getGroupFiles o=', o);
                          const nobj = this.newGrpDocumentList.find(x => x.uid === o.uid);
                          console.log('groupsvc nobj=', nobj);
                          if (!nobj) {
                             this.removeGrpFileListItem(o);
                          }
                       }
                       // compare and update obj if necessary
                       /******************/
                       for (const obj of this.grpDocumentList) {
                         // console.log ('getGroupFiles obj=', obj);
                         const nobj = this.newGrpDocumentList.find(x => x.uid === obj.uid);
                         if (JSON.stringify(obj) !== JSON.stringify(nobj)) {
                            this.updateGrpFileListItem(obj, nobj);
                         }
                       }
                       /****************/
                       // Add any items from newlist not in olist
                       const newItems = this.newGrpDocumentList.filter(obj => this.grpDocumentList.every(g => g.uid !== obj.uid));
                       // console.log('getGrpDocumentList: newItems=', newItems);
                       this.grpDocumentList.push(...newItems);
                       // this.grpDocumentList = Object.assign([], this.grpDocumentList);
                     }
                   }
                   this.grpDocListItems$.next(x);
                   return this.grpDocumentList;
                   // return x as DocumentData[];
                },
                (e) => {
                   console.log('documentsvc failed to get group documents:', e);
                   throw e;
                }
            );
  }

  // Get Document as Observable<DocumentData>
  //
  getDocument$(uid: string): Observable<DocumentData> {
     const url = environment.apiUrl + + '/document/id' + uid;
     return this.http.get(url)
            .pipe( map( x => {
               this.Doc = x as DocumentData;
               this.docItem$.next(this.Doc);
               return this.Doc;
             } ));
  }
  // Get Document Promise by doc uid
  // let doc = await documentsvc.getDocument( docuid);
  //
  async getDocumentData( docUID ) {
     return this.http.get(environment.apiUrl + '/document/id/' + docUID)
                .toPromise()
                .then(
                   (x) => {
                   // console.log('docsvcgetDocumentData x=', x);
                       return x as DocumentData;
                          },
                   (e) => { console.log(e);
                            throw e;
                          }
                 );
  }

  // Get Document File data
  // let doc = await documentsvc.getFileAsBlob( docuid );
  //
  async getFileAsArrayBuffer( docUID ) {
     const headers = {
               Authorization: 'Bearer ' + this.auth.getToken(),
               'Content-Type': 'application/json;'
     };

     return this.http.get(environment.apiUrl + '/document/' + docUID,
                          { headers, responseType: 'arraybuffer'} )
                .toPromise()
                .then(
                   (x) => { return x;
                          },
                   (e) => { console.log(e);
                            throw e;
                          }
                 );
  }

  // Get Document File data
  // let doc = await documentsvc.getFileAsBlob( docuid );
  //
  async getFileAsBlob( docUID ) {
     let pdfBlob = null;
     const headers = {
               Authorization: 'Bearer ' + this.auth.getToken(),
               'Content-Type': 'application/json; charset=UTF-8'
     };

     return this.http.get(environment.apiUrl + '/document/' + docUID,
                          { headers, responseType: 'blob' } )
                .toPromise()
                .then(
                   (x) => { // console.log('getFile x=', x);
                            pdfBlob = new Blob([x],
                                               {type: 'application/pdf'});
                            return pdfBlob;      // as DocumentData;
                          },
                   (e) => { console.log(e);
                            throw e;
                          }
                 );
  }

  // Get Document File data as encoded string
  // let doc = await documentsvc.getFile( docuid );
  //
  async getFile( docUID ) {
     const headers = {
               Authorization: 'Bearer ' + this.auth.getToken(),
               'Content-Type': 'application/json'
     };

     return this.http.get(environment.apiUrl + '/document/' + docUID,
                          { headers, responseType: 'blob' as 'json' } )
                .toPromise()
                .then(
                   (x) => { return x;      // as DocumentData;
                          },
                   (e) => { console.log(e);
                            throw e;
                          }
                 );
  }

  // Update Document Data
  updateDocumentData(duid, data): any {
    const docData = JSON.stringify(data);

    // update document data
    return this.http.put(environment.apiUrl + '/document/' + duid, data)
        .toPromise()
        .then(
              (x : DocumentData) => {
                // console.log('docsvc updt doc  x=: ', x);
                // Next update the DocumentList item
                this.updateListItem(x);
                // Update List SubjectBehavior Subject
                this.docListItems$.next( this.DocumentList );
                this.sendDocumentListUpdate();
                // Update BehaviorSubject
                this.docItem$.next( x );
                // return data
                return x as DocumentData;
              },
              (e) => {
                console.error('docsvc update error=', e);
                throw e;
             }
        );
  }

  // Remove Document
  removeDocument(uid): any {
    // remove member from selected group
    return this.http.delete(environment.apiUrl + '/document/' + uid)
        .toPromise()
        .then(
              (x) => {
                  // console.log('docsvc updt doc  x=: ', x);
                  // Next remove the DocumentList item
                  this.removeListItem(uid);
                  // Update List Subject/Behavior Subject
                  this.docListItems$.next( this.DocumentList );
                  this.sendDocumentListUpdate();
                  // Update BehaviorSubject
                  // this.documentItem$.next( x );
                  return x;
              },
              (e) => {
                console.error('docsvc remove docdata error=', e);
                throw e;
             }
        );
  }

  updateListItem(new_data: any) {
    const idx = this.DocumentList.findIndex(obj => obj.uid === new_data.uid);
    this.DocumentList[idx] = new_data;
    // some angular libraries require breaking the array reference
    // to pick up the update in the array and trigger change detection.
    // In that case, you can do fo
    this.DocumentList = Object.assign([], this.DocumentList);
  }

  removeListItem(del_uid: any) {
    const idx = this.DocumentList.findIndex(obj => obj.uid === del_uid);
    this.DocumentList.splice(idx, 1);
    this.DocumentList = Object.assign([], this.DocumentList);
  }


  loadPdfSrc(docUid) {
     this.pdfSrc = {
           url: environment.apiUrl + '/document/' + docUid,
           httpHeaders: {
               Authorization: 'Bearer ' + this.auth.getToken(),
              'Content-Type': 'application/json'
           },
     };
     return this.pdfSrc;
  }

  copyLink(duid: string): void {
    // let href = this.router.url.replace('#','');
    // href = href + '?docid=' + docid;
    // let marker = TDDO: optionally accept a marker and add as URL fragment
    // const urlTree = this.router.createUrlTree(['/'], {
    //    queryParams: { docid: duid },
    //    fragment: marker
    // });

    const args = '?docid=' + duid;
    const link = window.location.origin + args;
    console.log('copyLink=', link);
    try {
       const writeToClipboard = async () => {
          await Clipboard.write({
              string: link
          });
       };
       this.toast.notify('info', 'Copy Link',
                                 'Document link copied to clipboard');
       console.log('copyLink = ', link);
    } catch (e) {
       this.toast.notify('error', 'Copy Link',
                         'Error copying Document link to clipboard');
       console.log('copyLink error e=', e);
    }
  }

  pdfZoomChange(evt) {
      console.log('zoom chg evt=', evt);
      let z = evt;
      if (z > this.pdfMaxZoom) { z = this.pdfMaxZoom; }
      if (z < this.pdfMinZoom) { z = this.pdfMinZoom; }
      this.pdfZoom=z;
  }
  pdfZoomPctChange(evt) {
      console.log('zoom pct chg evt=', evt);
      let z = evt;
      if (z > this.pdfMaxZoomPct) { z = this.pdfMaxZoomPct }
      if (z < this.pdfMinZoomPct) { z = this.pdfMinZoomPct }
      this.pdfZoomPct=z;
  }
  pdfRotationChange(evt) {
      console.log('rotation chg evt=', evt);
      this.pdfRotation=evt;
  }
  pdfPageChange(evt) {
      console.log('page chg evt=', evt);
      this.pdfPageNbr=evt;
  }

  // **** Stats methods

   getDocumentSystemCount$(){
      this.http.get<any>(environment.apiUrl + '/document/count/system')
      .subscribe(cnt => {
                       // console.log('document system cnt =', cnt);
                       this.documentSystemCountSubj$.next(cnt);
                       return cnt as CountData;
             })
   }

   getDocumentSystemStorage$(){
      this.http.get<any>(environment.apiUrl + '/document/storage/system')
      .subscribe(size => {
                       // console.log('doc system storage =', size);
                       this.documentSystemStorageSubj$.next(size);
                       return size as SumData;
             })
   }

   getOrgDocumentCount$(oid: string){
      return this.http.get<any>(environment.apiUrl + '/document/count/org/' + oid);
      /****
      .subscribe(cnt => {
                       // console.log('document org cnt=', cnt);
                       this.orgDocumentCountSubj$.next(cnt);
                       return cnt as CountData;
             })
      ****/
   }

   getOrgDocumentCountSubj$(oid: string){
      return this.http.get<any>(environment.apiUrl + '/document/count/org/' + oid)
      .subscribe(cnt => {
                       // console.log('document org cnt=', cnt);
                       this.orgDocumentCountSubj$.next(cnt);
                       return cnt as CountData;
             })
   }

   getOrgDocumentStorage$(oid: string){
      return this.http.get<any>(environment.apiUrl + '/document/storage/org/' + oid);
      /*****
      .subscribe(size => {
                       // console.log('doc org storage =', size);
                       this.orgDocumentStorageSubj$.next(size);
                       return size as SumData;
             })
      *****/
   }

   getOrgDocumentStorageSubj$(oid: string){
      return this.http.get<any>(environment.apiUrl + '/document/storage/org/' + oid)
      .subscribe(size => {
                       // console.log('doc org storage =', size);
                       this.orgDocumentStorageSubj$.next(size);
                       return size as SumData;
             })
   }

   getGroupDocumentCount$(gid: string){
      this.http.get<any>(environment.apiUrl + '/document/count/group/' + gid)
      .subscribe(cnt => {
                       // console.log('document group cnt=', cnt);
                       this.grpDocumentCountSubj$.next(cnt);
                       return cnt as CountData;
             })
   }

   getGroupDocumentStorage$(gid: string){
      this.http.get<any>(environment.apiUrl + '/document/storage/group/' + gid)
      .subscribe(size => {
                       // console.log('doc group storage =', size);
                       this.grpDocumentStorageSubj$.next(size);
                       return size as SumData;
             })
   }

} // DocumentService
