  <mat-menu #objMenu="matMenu">
  <button mat-menu-item enabled (click)="viewObj()">
    <mat-icon>domain</mat-icon>              
    <span style="font-size: 0.9em">View {{objLabel}}</span>
  </button>
  <button *ngIf="userData && objData && objData.owner && (userData.uid === objData.owner)"
	  mat-menu-item enabled (click)="editObj()">
          <mat-icon>edit</mat-icon>  
          <span style="font-size: 0.9em">Edit {{objLabel}}</span>
  </button>
  <button *ngIf="userData"	  
          mat-menu-item enabled (click)="createObj()">
          <mat-icon>add</mat-icon>        
          <span style="font-size: 0.9em">Create New {{objLabel}}</span>
  </button>
  <button *ngIf="userData && objData && objData.owner && (userData.uid === objData.owner)"
	  mat-menu-item enabled (click)="deleteObjDialog()">
          <mat-icon>delete</mat-icon>
          <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
  </mat-menu>

<!---
<ng-template #groupBtns>
        <div class="group-btns">
          <button mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="createObj()"
                  matTooltip="Add Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">group_add</mat-icon>
                  <div class="label-text">Add</div>		  
          </button>
          <button *ngIf="objData && objData.owner === userData.uid"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="editObj()"
                  matTooltip="Rename Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">edit</mat-icon>
                  <div class="label-text">Rename</div>		  
          </button>
          <button mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="sidenavGroupManager()"
                  matTooltip="Edit Groups & Members">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">manage_accounts</mat-icon>
                  <div class="label-text">Manage</div>		  
          </button>
          <button *ngIf="objData"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="sidenavGroupUpload()"
                  matTooltip="Add Files/Content">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">note_add_outlined</mat-icon>
                  <div class="label-text">+Files</div>
          </button>
          <button *ngIf="objData && objData.owner === userData.uid"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openDeleteGroupDialog(selectedGroupObj.uid, selectedGroupObj.name)"
                  matTooltip="Delete Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0; color: red;">delete_forever</mat-icon>
                  <div class="label-text">Delete</div>		  
          </button>
        </div>
</ng-template>
---->

<ng-template #nodata>
   <span>No Data Found</span>
</ng-template>

<ng-template #loading>
  <div>
    <span>Loading Data...</span>
  </div>
</ng-template>

<!-- border: 1px solid #1A567A;  -->
<div>

<div #grp *ngIf="template === DisplayTemplate.FIELD_NAME">
 <div *ngIf="(uid && objData$ | async) as grp">
        <mat-form-field class="form-field" [floatLabel]="'always'" appearance="">	  
           <mat-label class="form-label">{{nameLabel}}</mat-label>
           <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
           <input matInput placeholder=""
                  type="text" name="name" class="form-control"
		  [value]="grp.name">
	   <ng-container *ngIf="addErrorMsg!==''">
             <mat-hint style="color: red">{{addErrorMsg}}</mat-hint>
	   </ng-container>
        </mat-form-field>
  </div>	
</div>

<div #grp *ngIf="template === DisplayTemplate.SIDENAV_NAME">
  <div *ngIf="objData">
    <span class="sidenav-name">{{objData.name}}</span>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em" (click)="viewObjDialog()">domain</mat-icon>
    <mat-icon *ngIf="objData && !objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em" (click)="viewObjDialog()">person</mat-icon>    
    <span *ngIf="objData && selectedObj && selectedObj.uid === objData.uid; else defaultObjName" style="font-size 1.5rem; font-weight: bold; background: #1A567A; color: white; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em; justify-content: space-between;">
	{{objData.name}}
    </span>    	
    <mat-icon *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid"    style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: #1A567A; color: white;">
	check
    </mat-icon>
    
    <ng-template #defaultObjName>
       <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;">
         {{objData.name}}
       </span>
    </ng-template>
    <button *ngIf="showDeleteButton"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.SELECT_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
  <div #grpItemRow style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em" (click)="viewObjDialog()">domain</mat-icon>
    <mat-icon *ngIf="objData && !objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em" (click)="viewObjDialog()">person</mat-icon>    
    <div #grpItemCol style="display: flex; flex-flow: column; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
  <!---
   <div #grpItemColRow style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <div *ngIf="(orgData$ | async) as org">	
       <span style="font-size: 0.7rem;">{{org.org_name}}</span>
    </div>
    <div *ngIf="(ownerData$ | async) as owner">
     <span style="font-size: 0.7rem; margin-left: 1rem;">{{owner.email}}</span>
    </div>
   </div>
  --->
    <span *ngIf="selectedObj && selectedObj.uid === objData.uid; else slNotSelected"
	  style="font-size 1.5rem; font-weight: bold; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      {{objData.name}}
    <span *ngIf="userData.uid===objData.owner" style="font-size: 0.8rem"> &#9733;</span>          
    </span>
    <ng-template #slNotSelected>
    <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      {{objData.name}}
    <span *ngIf="userData.uid===objData.owner" style="font-size: 0.8rem"> &#9733;</span>          
    </span>
    </ng-template>
    <mat-icon *ngIf="selectedObj && selectedObj.uid === objData.uid"
	      style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: white; color: #1A567A;">
	check
    </mat-icon>
  </div>    
    <button *ngIf="showDeleteButton && userData.uid===objData.owner"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
    </div>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.EXPAND_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;" (click)="setSelectedObj()">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="setSelectedObj()">
    <button mat-stroked-button
            (click)="toggleDetail()"
      style="margin-right: 1.5em; color: white; background-color: #2274A5;">
      <mat-icon>expand_more</mat-icon>
    </button>
    <span *ngIf="objData && selectedObj && selectedObj.uid === objData.uid; else defaultObjName"
          style="font-size 1rem; font-weight: bold; color: #2274A5; width: 100%">
          {{objData.name}}
    </span>
    <ng-template #defaultObjName>
       <span style="font-size: bold; font-weight: normal; color: black; width: 100%" (click)="setSelectedObj()"> 
         {{objData.name}}
       </span>
    </ng-template>
  </div>

  <div #objItemButtons (click)="setSelectedObj()"
       style="display: flex; flex-flow: row; justify-content: space-around; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em;">
  <mat-icon style="color: #2274A5; align-self: flex-end;" *ngIf="selectedObj && selectedObj.uid === objData.uid">check</mat-icon>    
 </div>
</div>

<!-- border: 1px solid #1A567A;  -->
<div #objdetail *ngIf="template === DisplayTemplate.DETAIL"
     style="display: flex; flex-flow: column; justify-content: space-between; align-items: space-between; width: 90% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="toggleDetail()">
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
    <button *ngIf="this.mode === DisplayMode.LIST || this.mode === DisplayMode.EXPAND_LIST"
	  mat-stroked-button
          (click)="toggleDetail()"
          style="margin-right: 1.5em;">
    <mat-icon>expand_less</mat-icon>
    </button>
    <span style="width: 100%;">Group {{objData.name}}</span>
    <mat-icon *ngIf="objData && selectedObj && (objData.uid===selectedObj.uid)"
        style="margin-left: 0.5em; margin-right: 0.4em; color: #2274A5">
	check
    </mat-icon>
  </div>
  <span style="margin-left: 1.5em; width: 100%"></span><br>
 <div style="display: flex; flex-flow: column; margin-left: 0.5em; margin-right: 1em; border: 1px solid #1A567A; width: 95%% !important;">  
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
  </div>
  <div style="display:flex; flex-flow: column; justify-content: space-between; margin-left: 0.5em">
    <div *ngIf="(orgData$ | async) as org">
      <span style="">Company/Org. Name : {{org.org_name}}</span>
    </div>
    <div *ngIf="(ownerData$ | async) as owner">
      <span style="">Owner Email Address : {{owner.email}}</span>
    </div>
  </div>
  <span style=""></span><BR>
    <span style=""></span><BR>
  </div>
 </div>
</div>

<div #orgform *ngIf="template===DisplayTemplate.FORM">
 <div 
     style="display: flex; flex-flow: column; justify-content: space-around; align-content; center; min-width: 90vw; margin-left: 1rem; margin-right: 1rem; overflow: hidden !important; margin-bottom: 7rem;">  

  <div *ngIf="showFormTitle">
   <div class="form-title" *ngIf="isAddMode">Create Group</div>
   <div class="form-title" *ngIf="mode === DisplayMode.EDIT">Edit Group</div>
   <div class="form-title" *ngIf="mode === DisplayMode.VIEW">View Group</div>  
  </div>
  
  <form id="objForm" [formGroup]="objForm">
  <!---
  <form id="objForm" [formGroup]="objForm" (ngSubmit)="onSubmit()">
  <mat-tab-group style="overflow: hidden !important;">

    <mat-tab class="form-tab" label="Info">
      --->
      
    <ng-container *ngIf="isAddMode">      
       <app-org-list #orgList class="form-field"
		     style="margin: 0; padding: 0; width: 80vw; max-width: 80vw; height: 100%: overflow-y: auto"
	 [iam]="iam"		 
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showNoneOption]="true"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showMenuButton]="false"
         [showAddButton]="false"		     
         [showListViewButton]="false"		    
         [selectedObj]="selectedOrg"
         [titleFilter]="''"
         (selectObjEvent)="setSelectedOrg($event)"		     
	 >
       </app-org-list>
       </ng-container>
      
    <mat-form-field *ngIf="showHidden"
		    class="form-field" [appearance]="formFieldStyle"
		    [style.display]="'block'" [floatLabel]="'always'">
      <mat-label class="form-label">UID</mat-label>      
      <input matInput placeholder="UID" 
             type="text" name="uid" class="form-control" formControlName="uid">
    </mat-form-field>
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'">
      <mat-label class="form-label">Group Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder=""
             type="text" name="name" class="form-control" formControlName="name">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.name.touched || submitted) && objForm.controls.name.errors?.required">
	Group Name is Required!</span>	
    </mat-error>
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.name.touched || submitted) && objForm.controls.name.errors?.userGroupAdmin">
	Create Group: Your Guest Sub-Account is not Authorized.</span>
    </mat-error>
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.name.touched || submitted) && objForm.controls.name.errors?.orgUserGroupAdmin">
	Create Group: Your Company/Org/Team Guest Account is not Authorized.</span>
    </mat-error>
    
    <mat-form-field *ngIf="showHidden" class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Owner UID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder="Owner UID" 
             type="text" name="owner" class="form-control" formControlName="owner">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.owner.touched || submitted) && 
                objForm.controls.owner.errors?.required">
	Group Owner is required.</span>	
    </mat-error>

    <mat-form-field *ngIf="(ownerData$ | async) as own" class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Owner Email</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder="Owner Email" 
             value="{{own.email}}"
             [ngModel]="own.email"
             type="text" name="owner_email" class="form-control" formControlName="owner_email">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.owner_email.touched || submitted) && objForm.controls.owner_email.errors?.required">
	Owner Email is required.</span>	
    </mat-error>

    <mat-form-field *ngIf="showHidden" class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company/Org. UID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder="" 
             type="text" name="org_uid" class="form-control" formControlName="org_uid">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.org_uid.touched || submitted) && objForm.controls.org_uid.errors?.required">
	Org UID is required.</span>	
    </mat-error>

    <mat-form-field *ngIf="(orgData$ | async) as gorg"
		    class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'" [hidden]="false">
      <mat-label class="form-label">Company/Org. Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder="" 
             value="{{gorg.org_name}}"
             [ngModel]="gorg.org_name"
             type="text" name="org_name" class="form-control" formControlName="org_name">
    </mat-form-field>  
    <mat-error class="form-error"> 
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.org_name.touched || submitted) && 
                objForm.controls.org_name.errors?.required">
	Org Name is required.</span>	
    </mat-error>
    
    <!---
    </mat-tab>
  </mat-tab-group>
  --->
  
  <div class="form-bottom-div">
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more tabs and try again
    </span>

    <div *ngIf="showActions" class="form-btn-div">
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode !== DisplayMode.VIEW" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
<!--       *ngIf="objData && userData && getIsObjAdmin(objData, userData) && (mode === DisplayMode.VIEW)" class="submit-btn" -->
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>
  
  </form>
 </div>
</div>

<!-- DATA PANELS Template -->
<div #grpDataPanels *ngIf="template === DisplayTemplate.DATA_PANELS && objData" style="min-width: 85vw; max-width: 100vw;">
  <mat-nav-list>
      <mat-divider></mat-divider>    
       <!--
          <ng-container *ngTemplateOutlet="groupBtns">
          </ng-container>
        -->
	</mat-nav-list>	  
        <mat-divider></mat-divider>
        <div>
        <mat-accordion style="margin: 0; padding: 0; width: 100%">
	<mat-expansion-panel #grpFilePanel style="margin-left: 0; margin-right: 1em; padding: 0.5em; width: 100%">	  
	  <mat-expansion-panel-header style="margin: 0; padding: 0; width: 90%">
            <mat-panel-title>
	      GROUP FILES
            </mat-panel-title>
             <mat-panel-description>
	       <div style="display: flex; flex-flow: row;">
                <ng-template #nofiles>
	 	(0)
	        </ng-template>
                <div *ngIf="(documentsvc.grpDocumentCountSubj$ | async) as fcnt; else nofiles">
	         ({{fcnt.count}})
	        </div>
	      <mat-icon *ngIf="grpFilePanel.expanded; else grpFilePanelMore">expand_less</mat-icon>
	      <ng-template #grpFilePanelMore>
                 <mat-icon>expand_more</mat-icon>
	      </ng-template>
	      </div>
             </mat-panel-description>
              <button mat-button style="padding: 0; margin: 0; margin-right: 2rem;"
   		  class="group-btn"
              (click)="refreshGroupFilesEvent($event); grpFilePanel.open();"
                  matTooltip="Refresh File List">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">refresh</mat-icon>
              </button>
              <button *ngIf="objData"
                  mat-button style="padding: 0; margin: 0; margin-right: 2.5em;"
   		  class="group-btn"
                  (click)="groupUpload(objData)"
                  matTooltip="Add Files/Content">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">note_add_outlined</mat-icon>
                  <!-- <div class="label-text">Add Files</div> -->
              </button>
	  </mat-expansion-panel-header>
	  <!-- NEW GROUP FILE LIST -->
         <mat-form-field class="mat-header-row"
			    style="font-size: 0.75rem; margin-left: 2em; margin-right: 0.5em">
	  <!-- [(ngModel)]="titleFilter" -->
          <input matInput #titleFilterInput
		 type="text"
		 style="width: 60%; font-size: 1rem;"
		 placeholder="Enter Search filter"/>
         </mat-form-field>
	 <div *ngIf="objData">
         <app-group-file-list #grpFileList 
           class="left-content-scroll"
	   style="margin: 0; padding: 0; width: 75vw; max-width: 99vw; overflow-y: auto"
           [iam]="false"
           [mode]="'SL'"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showDoneButton]="false"
           [showMenuButton]="false"
           [showAddButton]="false"			      
           [showListViewButton]="false"
           [showTitleFilter]="false"			      
           [userInfo]="userInfo"
           [userData]="userData"
           [selectedGroup]="objData"
           [titleFilter]="titleFilterInput.value"
	   (selectObjEvent)="setSelectedObj()"
	   (openObjEvent)="openSelectedFile($event)"
           (refreshListEvent)="refreshData()">
         </app-group-file-list>
         <!--
            (selectObjEvent)="setSelectedDocObj($event)"
           (doneEvent)="sidenavBack()"
           -->
	 </div>
	 <!-- END NEW GROUP FILE LIST -->	  
	</mat-expansion-panel>
        <mat-divider></mat-divider>

	<mat-expansion-panel #grpMemberPanel style="margin-left: 0; margin-right: 1em; padding: 0.5em; width: 100%">
	  <mat-expansion-panel-header style="margin: 0; padding: 0; width: 90%">
            <mat-panel-title>
	      GROUP MEMBERS
            </mat-panel-title>
            <mat-panel-description>
	      <ng-template #nombrs>
		(0)
	      </ng-template>
              <div *ngIf="(groupmembersvc.groupMemberCountSubj$ | async) as mcnt; else nombrs">
                ({{mcnt.count}})
              </div>
	      <mat-icon *ngIf="grpMemberPanel.expanded; else grpMemberPanelMore">expand_less</mat-icon>
	      <ng-template #grpMemberPanelMore>
                 <mat-icon>expand_more</mat-icon>		
	      </ng-template>
            </mat-panel-description>
              <button mat-button style="padding: 0; margin: 0; margin-right: 3em"
   		  class="group-btn"
          (click)="refreshGroupMembersEvent($event); grpMemberPanel.open();"
                  matTooltip="Refresh Member List">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">refresh</mat-icon>
              </button>
              <button *ngIf="objData && isObjAdmin"
	          mat-button style="padding: 0; margin: 0; margin-right: 4.5em;"
   		  class="group-btn"
                  (click)="groupAddMember(objData)"
                  matTooltip="Add Group Member">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">person_add</mat-icon>
                   <!-- <div class="label-text">Add Member</div> -->
                </button>
	  </mat-expansion-panel-header>
	  <mat-nav-list>
	  <div *ngIf="objData">
          <app-group-member-list #grpMemberList
           style="text-align: center; align-items: center; margin-bottom: 50px;"
             id="mbl-member-div2"
             (countMembersEvent)="this.handleGroupMemberCount($event)"
             [template]=5
             [userData]="userData"
             [userInfo]="userInfo"
             [group]="objData"
             [groupUID]="objData.uid"
            ></app-group-member-list>
             <!-- (displayUserInfoEvent)="this.openUserInfoDialog($event)" -->
          <!-- (removeMemberEvent)="this.handleRemoveMember($event)"	-->
	  </div>
          </mat-nav-list>	  
	</mat-expansion-panel>
        </mat-accordion>
     </div>
</div>

 <!-- Delete Template -->
 <div #grpDelete *ngIf="template === DisplayTemplate.DELETE">
        <div *ngIf="selectedObj; else noObjSelected"
	     class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Delete group {{selectedObj.name}}
	  </span>
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
	<ng-template #noObjSelected>
	  <div class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Error: No group selected!
	  </span>
          </div>
	</ng-template>
        <div class="form-btn-div">
             <button mat-stroked-button id="cancel-delete-obj" class="normal-btn" type="button" value="Cancel" (click)="onCancel()">Cancel</button>	  
             <button mat-stroked-button *ngIf="selectedObj" id="delete-obj" class="delete-btn" type="submit" value="Yes" (click)="deleteObjData(selectedObj.uid)">Yes</button>
        </div>
 </div>

<!-- group component -->    
